<div class="row medium-12 columns">
  <div class="medium-3 small-centered columns">
    <h2>Login</h2>
    <form name="form" (ngSubmit)="f.form.valid && login()" #f="ngForm" novalidate>
      <div class="form-group" [ngClass]="{ 'has-error': f.submitted && !fPasscode.valid }">
        <label for="passcode">Passcode</label>
        <input
          id="passcode"
          placeholder="XXXXXX"
          pattern="[0-9]{4,8}"
          type="password"
          inputmode="numeric"
          minlength="4"
          maxlength="8"
          size="8"
          class="masked form-control"
          name="passcode"
          [(ngModel)]="passcode"
          #fPasscode="ngModel"
          required
          autocomplete="off"
          autofocus />
        <div *ngIf="f.submitted && !fPasscode.valid" class="help-block">{{ errorMessageFrom(fPasscode) }}</div>
        <div *ngIf="errorMessage" class="help-block">{{ errorMessage }}</div>
      </div>

      <div>
        <mat-spinner-button (click)="login()" [options]="btnOpts"></mat-spinner-button>
      </div>
    </form>
  </div>
  <p>
    To access this ordering system for institutional subscriptions you need a passcode for your institution. The
    passcode has been sent to your institution's Digimap site representative. If you do not know who your site
    representative is, or you need a reminder of the code, please contact EDINA on
    <a href="mailto:digimap@ed.ac.uk">digimap@ed.ac.uk</a> or 0131 650 3302.
  </p>
  <p>
    If you are trying to access and use the Digimap service, please click here:
    <a href="https://digimap.edina.ac.uk">https://digimap.edina.ac.uk</a>
  </p>
</div>
