import { Injectable } from '@angular/core';
import { StorageService } from './storage.service';

@Injectable()
export class LocalStorageService extends StorageService {
  public getJson(key: string): string | undefined {
    const v = localStorage.getItem(key);
    return v === null ? undefined : v;
  }

  setObject<T>(key: string, entry: T) {
    if (entry) {
      localStorage.setItem(key, JSON.stringify(entry));
    } else {
      this.clear(key);
    }
  }

  clear(key: string) {
    localStorage.removeItem(key);
  }

  clearAll() {
    localStorage.clear();
  }
}
