import { ChangeDetectionStrategy, Component, Inject, Optional } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ErrorDialogData } from 'app/components/error/error-dialog.service';
import { AppPage, AppPages } from 'app/state/app-page';
import { ProductService } from '../../../services/product/products.service';
import { ShopConfigService } from '../../util/shop-config.service';

export interface UnexpectedErrorComponentData extends ErrorDialogData {
  message: string;
}

@Component({
  selector: 'dm-error-message',
  templateUrl: './unexpected-error.component.html',
  styleUrls: ['./unexpected-error.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UnexpectedErrorComponent {
  isDialog: boolean = false;
  title: string;
  message: string;
  productsPage: AppPage = AppPages.products;

  constructor(
    @Optional() @Inject(MAT_DIALOG_DATA) private data: UnexpectedErrorComponentData,
    protected productService: ProductService,
    public shopConfig: ShopConfigService
  ) {
    if (this.data) {
      this.title = this.data.title || 'Unexpected Error';
      this.message = this.data.message;
      this.isDialog = true;
    }
    if (productService.emptyProducts) {
      this.title = 'Organisation Data';
      this.message = "We don't have enough information about your organisation,";
    }
  }

  onTryAgain() {
    window.location.replace('/' + this.productsPage.navPath);
  }
}
