<dm-sub-header [icon]="'assignment_turned_in'" [title]="page.header">
  <button class="warning button large" type="submit" routerLink="/products" [disabled]="cart.orderCompleted === true">
    Return to Products
  </button>
</dm-sub-header>

<dm-order-total-with-vat [cart]="cart"></dm-order-total-with-vat>

<dm-checkout-item
  *ngFor="let cartItem of cartItems"
  [cartItem]="cartItem"
  (cartItemChange)="saveState()"></dm-checkout-item>

<div class="summary">
  <div class="dm-agreements">
    <mat-checkbox
      id="digimap-tos"
      name="digimap-tos"
      required
      color="primary"
      [(ngModel)]="cart.tosAgreementSigned"
      (ngModelChange)="saveState()">
      I acknowledge and agree to the
      <a class="" href="https://digimap.edina.ac.uk/common/tos/terms_of_service_digimap.pdf"
        >Digimap Terms of Service</a
      >
    </mat-checkbox>

    <mat-checkbox
      #authConfirmation
      id="checkbox_terms"
      name="auth-confirmation"
      required
      color="primary"
      [(ngModel)]="cart.authorisationConfirmed"
      (ngModelChange)="saveState()">
      I confirm that I am authorised by my institution to submit this order
    </mat-checkbox>
  </div>
  <div>
    <button
      [disabled]="!appState.isBillingPageEnabled()"
      class="success button large"
      type="submit"
      data-test="Next to Billing"
      [routerLink]="'/' + nextPage.navPath">
      Next
    </button>
  </div>
</div>
