<h1 mat-dialog-title *ngIf="isDialog">{{ title }}</h1>

<mat-dialog-content>
  <div class="dm-error-message-container">
    <div class="dm-error-message">
      <div *ngIf="message">{{ message }}</div>
      <div *ngIf="!message">
        Please <a (click)="onTryAgain()">try again</a> in a few minutes. If this error persists,
      </div>
      <div>
        please contact us on
        <a [href]="'tel:' + shopConfig.contactConfig.phoneNumber">{{ shopConfig.contactConfig.phoneNumber }}</a>
        or email us at
        <a [href]="'mailto:' + (shopConfig.configuration$ | async)?.contactEmail">{{
          (shopConfig.configuration$ | async)?.contactEmail
        }}</a>
      </div>
    </div>
  </div>
</mat-dialog-content>

<mat-dialog-actions *ngIf="isDialog">
  <button mat-raised-button color="primary" [mat-dialog-close]="true">Close</button>
</mat-dialog-actions>
