import { validateText } from '../validate/validate';

export class ContactInfo {
  static readonly validations = {
    title: {
      minLength: 0,
      maxLength: 20,
    },
    givenName: {
      minLength: 0,
      maxLength: 30,
    },
    familyName: {
      minLength: 1,
      maxLength: 150,
    },
    email: {
      // this augments Angular Form's built in email validator to require a domain name.
      regex: '.*@.*\\..{1,30}$',
      maxLength: 254,
      minLength: 4,
    },
  };
  firstName: string = '';
  lastName: string = '';
  emailAddress: string = '';
  title: string = '';

  constructor(cfg: Partial<ContactInfo> = {}) {
    this.firstName = cfg.firstName ? cfg.firstName : this.firstName;
    this.lastName = cfg.lastName ? cfg.lastName : this.lastName;
    this.emailAddress = cfg.emailAddress ? cfg.emailAddress : this.emailAddress;
    this.title = cfg.title ? cfg.title : this.title;
  }

  isValid(): boolean {
    const V = ContactInfo.validations;
    return (
      validateText(this.lastName, true, V.familyName.minLength, V.familyName.maxLength) &&
      validateText(this.emailAddress, true, V.email.minLength, V.email.maxLength)
    );
  }
}
