import { CurrentUser } from 'app/models/current-user.model';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ReplaySubject } from 'rxjs';
import { Subject } from 'rxjs';
import { of } from 'rxjs';

@Injectable()
export class UserService {
  public readonly user$: Observable<CurrentUser>;
  private userSubject: Subject<CurrentUser> = new ReplaySubject<CurrentUser>(1);
  private currentUser: CurrentUser;

  constructor(private http: HttpClient) {
    this.user$ = this.userSubject.asObservable();
    this.init();
  }

  async submitPin(passcode: string): Promise<void> {
    try {
      await this.http.post<any>('/api/authenticate', { passcode }).toPromise();
      const user = new CurrentUser(
        Object.assign({}, this.currentUser, {
          hasEnteredPin: true,
        })
      );
      this.userSubject.next(user);
    } catch (error) {
      const user = new CurrentUser(
        Object.assign({}, this.currentUser, {
          hasEnteredPin: false,
        })
      );
      this.userSubject.next(user);
      throw error;
    }
  }

  verifyUser(): Observable<CurrentUser | null> {
    if (this.currentUser) {
      return of(this.currentUser);
    }

    const result = this.http.get<CurrentUser | null>('/login/user');

    result.subscribe(u => {
      const currentUser = new CurrentUser(u || {});
      this.userSubject.next(currentUser);
    });

    return result;
  }

  private init(): void {
    this.userSubject.subscribe(user => (this.currentUser = new CurrentUser(user)));
  }
}
