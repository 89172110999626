/* eslint-disable max-classes-per-file */

export class AppVersionInfo {
  version: string;
  revision: string;
  dateTime: number;
  branch: string;
}

export class AppEnvironment {
  production: boolean;
  versionInfo: AppVersionInfo;
}
