import { validateText } from '../validate/validate';

export class UserInfo {
  static readonly validations = {
    title: {
      minLength: 1,
      maxLength: 20,
    },
    givenName: {
      minLength: 1,
      maxLength: 30,
    },
    familyName: {
      minLength: 1,
      maxLength: 150,
    },
    jobTitle: {
      maxLength: 60,
      minLength: 1,
    },
    email: {
      // this augments Angular Form's built in email validator to require a domain name.
      regex: '.*@.*\\..{1,30}$',
      maxLength: 254,
      minLength: 4,
    },
    phone: {
      regex: '^[^\\/\\\\]{6,32}$',
      minLength: 6,
      maxLength: 32,
    },
  };
  firstName: string = '';
  lastName: string = '';
  jobTitle: string = '';
  emailAddress: string = '';
  telephoneNumber: string = '';
  title: string = '';

  constructor(cfg: Partial<UserInfo> = {}) {
    this.firstName = cfg.firstName ? cfg.firstName : this.firstName;
    this.lastName = cfg.lastName ? cfg.lastName : this.lastName;
    this.jobTitle = cfg.jobTitle ? cfg.jobTitle : this.jobTitle;
    this.emailAddress = cfg.emailAddress ? cfg.emailAddress : this.emailAddress;
    this.telephoneNumber = cfg.telephoneNumber ? cfg.telephoneNumber : this.telephoneNumber;
    this.title = cfg.title ? cfg.title : this.title;
  }

  isValid() {
    const V = UserInfo.validations;
    return (
      validateText(this.firstName, true, V.givenName.minLength, V.givenName.maxLength) &&
      validateText(this.lastName, true, V.familyName.minLength, V.familyName.maxLength) &&
      validateText(this.jobTitle, true, V.jobTitle.minLength, V.jobTitle.maxLength) &&
      validateText(this.emailAddress, true, V.email.minLength, V.email.maxLength) &&
      validateText(this.telephoneNumber, true, V.phone.minLength, V.phone.maxLength)
    );
  }
}
