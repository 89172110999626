import * as _ from 'lodash';
import { areRangesOverlapping } from 'date-fns';

import { Product } from '../models/product.model';
import { Licence } from '../models/licence.model';
import { ShoppingCart } from '../models/shopping-cart.model';
import { Discount } from '../models/discount.model';

export class ProductUtil {
  /** Get the first non-bundle product which has the given licence. */
  static findProductForLicence(products: Product[], licenceId: string): Product | undefined {
    return products.find(p => p.licences.length === 1 && p.licences[0].id === licenceId);
  }

  /** Get all products which contain the given licence (could be single products or bundles). */
  static findProductsContainingLicence(products: Product[], licenceId: string): Product[] {
    return products.filter(p => p.licences.find(l => l.id === licenceId) !== undefined);
  }

  /** Return true if the list of products has at least one with the given licence */
  static productsContainLicence(products: Product[], licenceId: string): boolean {
    return ProductUtil.findProductsContainingLicence(products, licenceId).length > 0;
  }

  /**
   * Returns all licences covered by the given set of products. The licences in the resulting
   * array will be unique (i.e. be a set).
   */
  static allLicences(products: Product[]): Licence[] {
    return _.uniqBy(
      _.flatMap(products, p => p.licences),
      l => l.id
    );
  }

  /**
   * Returns true if any licence of product is also in any of the products in productList.
   */
  static hasAnyLicenceInProducts(product: Product, productList: Product[]): boolean {
    // Only consider products that are in the same time period
    productList = productList.filter(p =>
      areRangesOverlapping(product.startDate, product.endDate, p.startDate, p.endDate)
    );

    const allLicencesInList = ProductUtil.allLicences(productList);
    const commonLicences = _.intersectionBy(product.licences, allLicencesInList, licence => licence.id);
    return commonLicences.length > 0;
  }

  /**
   * Get discounts which will be applied to the product given the state of the contents of the shopping cart.
   * I.e. if the return list is not empty, the price of the product will be reduced because another product is
   * in the cart which reducing the price of the given product.
   */
  static appliedDiscounts(product: Product, cart: ShoppingCart): Discount[] {
    return product.discounts.filter(d => cart.containsAllProductIds(d.discountRule));
  }

  static isUpgrade(product: Product, cart: ShoppingCart): boolean {
    return !cart.containsProductId(product.productCode) && this.appliedDiscounts(product, cart).length > 0;
  }
}
