import { Component, Input } from '@angular/core';

@Component({
  selector: 'dm-sub-header',
  templateUrl: './sub-header.component.html',
  styleUrls: ['./sub-header.component.scss'],
})
export class SubHeaderComponent {
  @Input('title')
  title: string;

  @Input('icon')
  icon: string;
}
