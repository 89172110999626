import * as currency from 'currency.js';

import { GBP } from './../helpers/currency-defs';
import { Licence } from './licence.model';
import { Discount } from './discount.model';

const DFS_PRODUCT_FAMILY = 'dfs';
const DATANATION_PRODUCT_FAMILY = 'datanation';

export enum ProductSubscriptionState {
  New = 'new', // Not subscribed and not invoiced - this would be a 'new' subscription starting Aug 1.
  Renewable = 'renewable', // Currently subscribed to the previous years version
  Invoiced = 'invoiced', // Purchased or renewed for the upcoming year, but not yet paid. Has a subscription.
  Subscribed = 'subscribed', // Got a current, paid subscription
  Unavailable = 'unavailable', // Can not be bought right now, display message to user
}

export class Product {
  id: string = '';
  icon: string;
  sortOrder: number;
  name: string = '';
  description: string = '';
  subscriptionState: ProductSubscriptionState = ProductSubscriptionState.New;
  startDate: Date;
  endDate: Date;
  priceNoVat: currency;
  vatAmount: currency;
  proRataPrice: currency;
  proRataMonths: number = 12;
  moreInfoUrl: string = '';
  dependency: string = ''; // NB: dependency is the licence ID, NOT a product ID as in Salesforce
  productFamily: string = '';
  productCode: string = '';
  discounts: Discount[] = [];

  licences: Licence[] = [];

  // Prevent TSLint warning about cyclomatic complexity in this case
  /* eslint-disable complexity */
  constructor(cfg: Partial<Product> = {}) {
    this.id = cfg.id ? cfg.id : this.id;
    this.icon = cfg.icon ? cfg.icon : this.icon;
    this.sortOrder = cfg.sortOrder ? cfg.sortOrder : 0;
    this.name = cfg.name ? cfg.name : this.name;
    this.description = cfg.description ? cfg.description : this.description;
    this.subscriptionState = cfg.subscriptionState ? cfg.subscriptionState : ProductSubscriptionState.New;
    this.startDate = new Date(cfg.startDate ? cfg.startDate : this.startDate);
    this.endDate = new Date(cfg.endDate ? cfg.endDate : this.endDate);
    this.priceNoVat = GBP(cfg.priceNoVat ? cfg.priceNoVat : this.priceNoVat);
    this.vatAmount = GBP(cfg.vatAmount ? cfg.vatAmount : this.vatAmount);
    this.proRataPrice = GBP(cfg.proRataPrice ? cfg.proRataPrice : null);
    this.proRataMonths = cfg.proRataMonths ? cfg.proRataMonths : this.proRataMonths;
    this.moreInfoUrl = cfg.moreInfoUrl ? cfg.moreInfoUrl : this.moreInfoUrl;
    this.dependency = cfg.dependency ? cfg.dependency : this.dependency;
    this.productFamily = cfg.productFamily ? cfg.productFamily : this.productFamily;
    this.productCode = cfg.productCode ? cfg.productCode : this.productCode;
    if (cfg.discounts) {
      this.discounts = cfg.discounts.map(discount => new Discount(discount));
    }

    if (cfg.licences) {
      this.licences = cfg.licences.map(licence => new Licence(licence));
    }
  }

  /* eslint-enable complexity */

  totalPrice(): currency {
    // Note we are using the proRataPrice - this will be the same as priceNoVat if no pro-rata discount applicable
    return this.proRataPrice.add(this.vatAmount);
  }

  isBundle(): boolean {
    return this.licences.length > 1;
  }

  isSimple(): boolean {
    return this.licences.length === 1;
  }

  isSimpleWithLicence(licenceId: string): boolean {
    return this.isSimple() && this.licences[0].id === licenceId;
  }

  /**
   * Returns true if this product contains the given licence.
   */
  hasLicence(licence: Licence): boolean {
    return this.hasLicenceId(licence.id);
  }

  /**
   * Returns true if this product contains the given licence ID.
   */
  hasLicenceId(licenceId: string): boolean {
    return this.licences.find(l => l.id === licenceId) !== undefined;
  }

  /**
   * Return true if this product contains all given licences.
   */
  hasAllLicences(licences: Licence[]): boolean {
    return licences.every(licence => this.hasLicence(licence));
  }

  startsInTheFuture(): boolean {
    const today = new Date();
    return this.startDate > today;
  }

  // NOTE: Products are equal if their ID's match or if both product families are DfS
  //       (we wish to treat dfsv1 and v2 as the "same" so that only one or the other can be bought.
  equals(product: Product): boolean {
    return (
      this.id === product.id ||
      (this.productFamily === product.productFamily &&
        (this.productFamily === DFS_PRODUCT_FAMILY || this.productFamily === DATANATION_PRODUCT_FAMILY))
    );
  }
}
