import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { UserInfo } from 'app/models/user-info.model';
import { ShoppingCartService } from 'app/services/shopping-cart.service';
import { ShopConfigService } from '../../../util/shop-config.service';
import { ShopConfigInterface } from '../../../util/shop-config.module';
import { AlertComponent } from '../../../error/alert/alert.component';
import { MatDialog } from '@angular/material/dialog';
import { filteredEmailDomains } from './popular-domains';

@Component({
  selector: 'dm-billing-user-form',
  templateUrl: './billing-user-form.component.html',
})
export class BillingUserFormComponent implements OnInit {
  @Input('user') user: UserInfo;

  @Input('disabled') disabled = false;

  @Input('userType') userType: string;

  @Output('form') form: EventEmitter<NgForm> = new EventEmitter<NgForm>();

  @ViewChild('userForm', { static: true }) userForm;

  readonly validations = UserInfo.validations;
  private _shopType: ShopConfigInterface | null;
  set shopType(newShopType: ShopConfigInterface) {
    this._shopType = newShopType;
  }

  constructor(
    private readonly shoppingCartService: ShoppingCartService,
    private readonly shoppingConfigService: ShopConfigService,
    private readonly dialog: MatDialog
  ) {}

  ngOnInit() {
    this.form.emit(this.userForm);
    this.shoppingConfigService.configuration$.subscribe(shopType => (this._shopType = shopType));
  }

  saveState() {
    this.shoppingCartService.stateChanged();
  }

  blurEmailInput($event) {
    const email = ($event.target as HTMLInputElement).value;
    this.filterPopularEmailDomains(email);
  }

  filterPopularEmailDomains(email: string) {
    const domain = email.split('@')[1];
    if (!domain) {
      return;
    }
    if (!this._shopType) {
      return;
    }
    if (!this._shopType.emailWarning) {
      return;
    }
    if (filteredEmailDomains.indexOf(domain) > -1) {
      this.dialog.open(AlertComponent, {
        data: {
          title: 'Email Warning',
          message:
            'The email address you have provided appears to not be an authoritative school email address. ' +
            'We strongly recommend you provide an authoritative school email address. ' +
            'Failure to do so may result in a delay in the processing of your subscription purchase. ' +
            'If you have a query regarding the suitability of the email address you wish to provide, ' +
            'please contact us.',
          minWidth: '400px',
        },
      });
    }
  }
}
