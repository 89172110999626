<div *ngIf="this.loader.getLoading()" class="spinner-container">
  <div class="spinner-box">
    <div class="spinner">
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>
    <div class="spinner-text">Loading</div>
  </div>
</div>
