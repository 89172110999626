import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChange,
} from '@angular/core';
import { Product, ProductSubscriptionState } from 'app/models/product.model';

import { ShoppingCart } from 'app/models/shopping-cart.model';
import { ShoppingCartService } from 'app/services/shopping-cart.service';
import { ProductUtil } from '../../../../helpers/product-util';
import { BundleService } from '../../../../services/product/bundling/bundle.service';
import { AlternativeBundle } from '../../../../services/product/bundling/alternative-bundle';
import { Discount } from '../../../../models/discount.model';
import { MatDialog } from '@angular/material/dialog';
import { FreeProductAddedDialogComponent } from './free-product-added-dialog/free-product-added-dialog.component';

@Component({
  selector: 'dm-product-list-item',
  templateUrl: './product-list-item.component.html',
  styleUrls: ['./product-list-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProductListItemComponent implements OnInit, OnChanges {
  @Input() product: Product;

  @Input() allProducts: Product[];

  @Input() showPrice = true;

  invoiced: boolean = false;
  inCart: boolean = false;
  dependencyFulfilled: boolean = false;

  cheaperBundle?: AlternativeBundle;

  // List of any discounts which have been applied to the price of the product
  discountsApplied: Discount[] = [];

  private bundleAlternatives?: AlternativeBundle[];
  private invoicedProducts: Product[];
  private cart: ShoppingCart; // safe, because we have resolver and a Replay subject on the cart.

  constructor(
    private shoppingCartService: ShoppingCartService,
    private bundleService: BundleService,
    private changeDetector: ChangeDetectorRef,
    private matDialog: MatDialog
  ) {}

  ngOnInit() {
    this.shoppingCartService.cart$.subscribe(cart => {
      this.cart = cart;
      this.updateProductState();
      this.changeDetector.markForCheck();
    });

    this.bundleService.getCheaperBundles$().subscribe(cheaperBundles => {
      this.bundleAlternatives = cheaperBundles;
    });
  }

  ngOnChanges(change: { product: SimpleChange; allProducts: SimpleChange; cart: SimpleChange }) {
    if (change.product || change.allProducts || change.cart) {
      this.updateProductState();
    }
  }

  addProductToCart(product: Product): void {
    this.shoppingCartService.addItem(product);

    // Get all the free products which have a dependency on `product` and also add them to the cart. This is,
    // initially at least, specifically handle the case of 'Scotus', which should be added when OS or a bundle
    // is added to the cart.
    const freeDependents = this.allProducts.filter(p => this.isFreeDependant(product, p));
    if (freeDependents.length > 0) {
      freeDependents.forEach(p => {
        this.shoppingCartService.addItem(p);
      });
      this.matDialog.open(FreeProductAddedDialogComponent, {
        width: '600px',
        height: '280px',
        data: {
          freeProducts: freeDependents,
        },
      });
    }
  }

  /**
   * List of discounts for this product. Will be the applied list if products already in cart, otherwise all
   * possible discounts.
   */
  discountsList(): Discount[] {
    return this.discountsApplied.length > 0 ? this.discountsApplied : this.product.discounts;
  }

  /** True if the discountsList contains only applied discounts, based on the shopping cart. */
  appliedDiscountsAvailable(): boolean {
    return this.discountsApplied.length > 0;
  }

  isOrdered(subscriptionState: ProductSubscriptionState): boolean {
    return (
      subscriptionState === ProductSubscriptionState.Subscribed ||
      subscriptionState === ProductSubscriptionState.Invoiced
    );
  }

  isAvailable(subscriptionState: ProductSubscriptionState): boolean {
    return subscriptionState !== ProductSubscriptionState.Unavailable;
  }

  isRenewable(subscriptionState: ProductSubscriptionState): boolean {
    return subscriptionState === ProductSubscriptionState.Renewable;
  }

  getNameForLicence(licenceId: string): string | undefined {
    const product = ProductUtil.findProductForLicence(this.allProducts, licenceId);
    return product ? product.name : undefined;
  }

  // Return the first found discount that can be applied to the given product
  getDiscountApplied(): Discount | undefined {
    return this.cart.getDiscountApplied(this.product.discounts);
  }

  private updateProductState() {
    if (this.product && this.allProducts && this.cart) {
      const cartProducts = this.cart.items.map(item => item.product);

      this.invoicedProducts = this.allProducts.filter(prod => this.isOrdered(prod.subscriptionState));
      this.invoiced = ProductUtil.hasAnyLicenceInProducts(this.product, this.invoicedProducts);

      const sameProduct = this.cart.items.find(i => i.product.equals(this.product)) !== undefined;
      const sameLicence = ProductUtil.hasAnyLicenceInProducts(this.product, cartProducts);
      this.inCart = sameProduct || sameLicence;

      if (this.product.dependency) {
        const dependencyInvoiced = ProductUtil.productsContainLicence(this.invoicedProducts, this.product.dependency);
        const dependencyInCart = ProductUtil.productsContainLicence(cartProducts, this.product.dependency);
        this.dependencyFulfilled = dependencyInCart || dependencyInvoiced;
      } else {
        this.dependencyFulfilled = true;
      }

      this.cheaperBundle = undefined;
      if (this.bundleAlternatives) {
        this.bundleAlternatives.forEach(bundle => {
          if (bundle.bundle.id === this.product.id && bundle.isBundleCheaper()) {
            this.cheaperBundle = bundle;
          }
        });
      }

      // List of any discounts which will be applied to the product, based on the contents of the basket
      this.discountsApplied = ProductUtil.appliedDiscounts(this.product, this.cart);
    }
  }

  /**
   * Return true if `product` is free and has any licence of `target` as a dependency.
   * E.g. Scotus is free and has a dependency on OS.
   */
  private isFreeDependant(target: Product, product: Product): boolean {
    if (product.priceNoVat.intValue === 0) {
      const hasDependency = target.hasLicenceId(product.dependency);
      const inBundle = target.hasAllLicences(product.licences);
      const inCart = this.cart.items.find(p => p.product.id === product.id) !== undefined;
      return hasDependency && !inBundle && !inCart;
    }
    return false;
  }
}
