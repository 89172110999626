import { Injectable } from '@angular/core';
import { ShopType } from '../../helpers/ShopType';
import * as jwt_decode from 'jwt-decode';
import { CookieService } from 'ngx-cookie-service';
import { Title } from '@angular/platform-browser';
import { Observable, ReplaySubject, Subject } from 'rxjs';
import { configTypes, ShopConfigInterface } from './shop-config.module';
import { ShoppingCartService } from '../../services/shopping-cart.service';
import { ProductService } from '../../services/product/products.service';

@Injectable()
export class ShopConfigService {
  public static readonly SHOP_TYPE: string = 'shopType';
  public readonly contactConfig = {
    phoneNumber: '0131 650 3302',
  };
  configuration$: Observable<ShopConfigInterface>;
  private shopSubject$: Subject<ShopConfigInterface> = new ReplaySubject<ShopConfigInterface>(1);

  constructor(
    private cookieService: CookieService,
    private titleService: Title,
    private shoppingCartService: ShoppingCartService,
    private productService: ProductService
  ) {
    window.dataLayer = window.dataLayer || [];
    this.configuration$ = this.shopSubject$.asObservable();
    this.updateShopType();
  }

  updateShopType(type = this.getShopType()) {
    const config = configTypes[type];
    if (type !== localStorage.getItem(ShopConfigService.SHOP_TYPE)) {
      this.shoppingCartService.purge();
      this.productService.resultsCached = false;
      localStorage.setItem(ShopConfigService.SHOP_TYPE, type);
    }

    if (config !== undefined) {
      this.titleService.setTitle(config.title);
      this.shopSubject$.next(config);
    }

    if (this.cookieService.get('enable_analytics') === 'true') {
      window.dataLayer.push({
        shopType: type,
      });
    }
  }

  private getShopType(): ShopType {
    const localShop = localStorage.getItem(ShopConfigService.SHOP_TYPE);
    if (localShop) {
      return ShopType[localShop];
    }
    if (this.cookieService.check('subscriptions')) {
      const data = jwt_decode(this.cookieService.get('subscriptions'));
      const x: string = data['shopType'];
      return ShopType[x];
    }
    // return default shop type if it was never set and the cookie wasn't assigned
    return ShopType.DIGIMAP;
  }
}
