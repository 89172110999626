<div class="row checkout_row">
  <fieldset class="medium-11 columns medium-centered">
    <legend>{{ cartItem.product.name }}</legend>
    <div class="dm-item-body">
      <div *ngIf="cartItem.product.icon" class="product-image hide-for-small-only">
        <img src="./assets/{{ cartItem.product.icon }}" class="product-image" aria-hidden="true" />
      </div>
      <br />
      <div class="licenses">
        <ng-container *ngFor="let licence of cartItem.product.licences">
          <h5 class="bundle-product-name" *ngIf="isBundle">{{ licence.displayName }}</h5>
          <div class="build-date">
            Start Date: <span class="text--bold">{{ cartItem.product.startDate | date: 'longDate' }}</span> - End Date:
            <span class="text--bold">{{ cartItem.product.endDate | date: 'longDate' }}</span>
          </div>
          <mat-checkbox
            id="{{ getLicenceId(licence) }}-eula"
            name="{{ getLicenceId(licence) }}-eula"
            required
            color="primary"
            data-test="Product Licence"
            [(ngModel)]="cartItem.licenceAgreementSigned[licence.id]"
            (ngModelChange)="fireChangeEvent()">
            <ng-container *ngIf="licence.finalLicence; else nolicence">
              I acknowledge and agree to the
              <a href="{{ licence.eulaUrl }}">{{ licence.displayName }} Licence</a>
              <ng-container *ngIf="licence.eulaChangesUrl">
                <br />
                Summary of <a href="{{ licence.eulaChangesUrl }}">licence changes</a> since last year
              </ng-container>
            </ng-container>
            <ng-template #nolicence>
              I acknowledge that you will contact me with the licence when it is available for review.
              <br />If upon review I do not wish to agree to the licence I understand that I can cancel my order for
              this Collection and will not be charged.
            </ng-template>
          </mat-checkbox>
          <br />
        </ng-container>
      </div>
      <dm-price-with-vat
        [product]="cartItem.product"
        [showDiscount]="getDiscountApplied(cartItem.product)"></dm-price-with-vat>
    </div>
  </fieldset>
</div>
