import { uniqBy } from 'lodash';

import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { Product } from 'app/models/product.model';

import { PageComponent } from '../PageComponent';
import { ProductService } from 'app/services/product/products.service';
import { UserService } from '../../../services/user/user.service';
import { ShopConfigService } from '../../util/shop-config.service';
import { ShopType } from '../../../helpers/ShopType';
import { Licence } from '../../../models/licence.model';
@Component({
  selector: 'dm-store-front',
  styleUrls: ['./products-page.component.scss'],
  templateUrl: './products-page.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProductsPageComponent extends PageComponent implements OnInit {
  allProducts: Product[] = [];
  currentAvailableProducts: Product[] = [];
  futureAvailableProducts: Product[] = [];
  ownedProducts: Product[] = [];
  shopIsHE: boolean = false;
  licences: Licence[] = [];
  selectedIndex: number | null = null; // Tab index currently selected

  constructor(private productsService: ProductService, private userService: UserService) {
    super();
    this.shopIsHE = localStorage.getItem(ShopConfigService.SHOP_TYPE) === ShopType.DIGIMAP;
  }

  ngOnInit() {
    this.productsService.products$.subscribe(products => {
      this.allProducts = products;
      // Ensure to remove duplicate licences (can occur because of bundles)
      this.licences = uniqBy(
        products.flatMap(p => p.licences),
        licence => licence.id
      );
      this.licences.sort((a, b) => a.displayName.localeCompare(b.displayName));
    });
    this.productsService.availableProducts$.subscribe(products => {
      this.futureAvailableProducts = [];
      this.currentAvailableProducts = [];
      products.forEach(product => {
        if (product.startsInTheFuture()) {
          this.futureAvailableProducts.push(product);
        } else {
          this.currentAvailableProducts.push(product);
        }
      });
    });
    this.productsService.ownedProducts$.subscribe(products => (this.ownedProducts = products));
    this.productsService.load(true);

    this.userService.user$.subscribe(u => {
      if (!u.institution) {
        return;
      }
    });
  }

  showSubheading(): boolean {
    return this.shopIsHE && this.currentAvailableProducts.length > 0;
  }

  gotoCurrentSubscriptionsTab($event: MouseEvent) {
    this.selectedIndex = 1;
    $event.preventDefault();
  }
}
