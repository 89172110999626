<h2 mat-dialog-title>Free product automatically added to your basket</h2>
<mat-dialog-content>
  <p>
    We have added the following free product to your basket automatically. If you do not wish to take this, it can be
    removed from your basket.
  </p>
  <ul>
    <li *ngFor="let product of data.freeProducts">
      {{ product.name }}
    </li>
  </ul>
</mat-dialog-content>
<mat-dialog-actions>
  <button mat-raised-button color="primary" [mat-dialog-close]="true">Ok</button>
</mat-dialog-actions>
