<h1 mat-dialog-title *ngIf="title">{{ title }}</h1>

<mat-dialog-content>
  <div class="dm-error-message-body">
    <div class="dm-error-message">
      <div>{{ message }}.</div>
    </div>
  </div>
</mat-dialog-content>

<mat-dialog-actions *ngIf="title">
  <button mat-raised-button color="primary" [mat-dialog-close]="true">Close</button>
</mat-dialog-actions>
