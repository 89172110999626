import { AppPage, AppPages } from 'app/state/app-page';
import { ChangeDetectionStrategy, Component, OnDestroy, OnInit } from '@angular/core';

import { Address } from 'app/models/address.model';
import { CurrentUser } from 'app/models/current-user.model';
import { NgForm } from '@angular/forms';
import { PageComponent } from 'app/components/page/PageComponent';
import { ShoppingCart } from 'app/models/shopping-cart.model';
import { ShoppingCartService } from 'app/services/shopping-cart.service';
import { Subscription } from 'rxjs';
import { UserInfo } from 'app/models/user-info.model';
import { UserService } from 'app/services/user/user.service';
import { filter } from 'rxjs/operators';
import { COUNTRIES, Country } from '../../../models/countries.module';
import { ShopType } from '../../../helpers/ShopType';
import { ShopConfigService } from '../../util/shop-config.service';

@Component({
  styleUrls: ['./billing-details-page.component.scss'],
  templateUrl: './billing-details-page.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BillingDetailsPageComponent extends PageComponent implements OnDestroy, OnInit {
  page: AppPage = AppPages.billingDetails;
  nextPage: AppPage = AppPages.confirm;
  cart: ShoppingCart;
  user: CurrentUser;
  purchaserContactForm: NgForm;
  invoiceContactForm: NgForm;
  contactUserForm: NgForm;
  now: Date;
  isPurchaserShown: boolean;
  currentShopIsSchool: boolean;

  validations = Address.validations;

  // the data for countries also hold the country's phone dial_code, we can use this to verify phone numbers if needed
  countryData: Country[] = COUNTRIES;
  // default to GB
  countryCodeGB: string = Address.GB_CODE;

  private orderedByUpdates: Subscription | undefined;

  constructor(private userService: UserService, private shoppingCartService: ShoppingCartService) {
    super();
    this.shoppingCartService.cart$.subscribe(cart => (this.cart = cart));
    this.userService.user$.subscribe(user => {
      this.user = user;
    });
    this.now = this.user.lastSeen;
    // the assumption is that the boolean for requesting additional information should only be visible for schools
    const localShop = localStorage.getItem(ShopConfigService.SHOP_TYPE);
    if (localShop) {
      this.currentShopIsSchool = ShopType[localShop] === ShopType.DIGIMAP_FOR_SCHOOLS;
    }
  }

  updatePurchaserRecipient(isPurchaserRecipient: boolean) {
    this.cart.isPurchaserRecipient = isPurchaserRecipient;
    this.cart.invoiceRecipient = isPurchaserRecipient ? this.cart.purchaser : new UserInfo(this.cart.purchaser);
    this.saveState();
  }

  updateProductInvoiceDates(invoiceNow: string) {
    if (invoiceNow === 'now') {
      // All orders should be invoiced together
      this.cart.items.forEach(item => {
        item.invoiceDate = this.now;
      });
    } else {
      // Invoice future produces at invoice date, past products now
      const minDate = this.cart.findEarliestFutureProductDate(this.now);
      if (!minDate) {
        throw Error('No future products found in the cart');
      }
      this.cart.getFutureItems(this.now).forEach(item => {
        item.invoiceDate = minDate;
      });
      this.cart.getPastItems(this.now).forEach(item => {
        item.invoiceDate = this.now;
      });
    }
  }

  showInvoiceAfter(): boolean {
    // Note: this.now is actually this.user.lastSeen (see constructor)
    return this.cart.hasProductInFuture(this.now) && this.isPurchaserShown;
  }

  saveState() {
    this.shoppingCartService.stateChanged();
  }

  setPurchaserContactForm(purchaserContactForm: NgForm) {
    this.purchaserContactForm = purchaserContactForm;
    if (!this.purchaserContactForm.valueChanges) {
      console.error('Somehow the purchaserContactForm has null valueChanges', this.purchaserContactForm);
      return;
    }
    this.orderedByUpdates = this.purchaserContactForm.valueChanges
      .pipe(filter(v => this.cart.isPurchaserRecipient))
      .subscribe(values => this.updatePurchaserRecipient(true));
  }

  setInvoiceContactForm(invoiceContactForm: NgForm) {
    this.invoiceContactForm = invoiceContactForm;
    if (!this.isPurchaserShown) {
      this.cart.invoiceAddress.address = this.user.institution.address.address || this.user.institution.name;
      this.cart.invoiceAddress.postcode = this.user.institution.address.postcode || 'NO0 0PC';
    }
  }

  setContactUserForm(contactUserForm: NgForm) {
    this.contactUserForm = contactUserForm;
  }

  isContactUserFormValid() {
    return this.contactUserForm && this.contactUserForm.value && this.isContactUserFormNotEmpty(this.contactUserForm)
      ? this.contactUserForm.valid
      : true;
  }

  isContactUserFormNotEmpty(form: NgForm) {
    return (
      form.value['purchaser-email'] !== '' ||
      form.value['purchaser-title'] !== '' ||
      form.value['purchaser-firstName'] !== '' ||
      form.value['purchaser-lastName'] !== ''
    );
  }

  nextEnabled(...forms: NgForm[]) {
    const untouched = forms.some(f => f.untouched === true);
    const invalid = forms.some(f => f.invalid === true);
    const enabled = !invalid && (!untouched || this.cartIsValid());
    return enabled && (!this.showInvoiceAfter() || this.cart.invoiceDate) && this.isContactUserFormValid();
  }

  ngOnDestroy() {
    if (this.orderedByUpdates) {
      this.orderedByUpdates.unsubscribe();
    }
  }

  ngOnInit() {
    if (this.cart.grossTotal.value !== 0.0) {
      this.cart.isPurchaserRecipient = false;
      this.isPurchaserShown = true;
    } else {
      this.cart.isPurchaserRecipient = true;
      this.isPurchaserShown = false;
    }

    this.cart.items.forEach(i => (i.invoiceDate = this.now));
  }

  private cartIsValid() {
    // Don't need to validate the address if the invoice is for 0 (eg ofsted)
    const addrValid = this.cart.grossTotal.intValue === 0 || this.cart.invoiceAddress.isValid();
    return addrValid && this.cart.purchaser.isValid() && this.cart.invoiceRecipient.isValid();
  }
}
