<h1>Digimap Subscriptions Privacy Statement</h1>

<h2>About this notice</h2>

<p>
  This privacy notice provides information about how EDINA and the University of Edinburgh collects and uses your
  personal information in relation to your subscription to and use of Digimap.
</p>

<h2>About you</h2>

<p>This privacy notice uses “you” to mean any individual about whom we collect and hold personal data.</p>

<h2>What information may we collect about you?</h2>

<p>We collect, store and use the following categories of personal data:</p>

<ul>
  <li>
    your contact details such as name, title, organisation address, telephone numbers, personal or work e-mail address
  </li>
  <li>
    other relevant personal information. For example, if you are booking a place at a conference or event, the
    conference organisers may ask you to provide other relevant information e.g. details of any dietary requirements or
    allergies
  </li>
  <li>
    customer feedback. We may carry out customer research by providing customers with access to feedback questionnaires.
    Although this feedback is anonymous, the questionnaires provide the opportunity for free text comments, in which a
    customer may supply personally identifiable information about themselves, other customers or members of staff
  </li>
  <li>your IP address</li>
</ul>

<h2>How is your personal data collected?</h2>

<p>
  You give us your personal data through the information collected about you in order to subscribe to Digimap, take a
  payment from you, respond to an email relating to an enquiry etc. We capture this information as it is necessary to
  fulfil the subscription contract.
</p>

<p>
  As a visitor to https://subscriptions.edina.ac.uk/ we record your IP address; we do this so that we can monitor
  service user engagement.
</p>

<p>How we will use the information about you?</p>

<p>The information you provide to Digimap will be used by EDINA and the University of Edinburgh to:</p>

<ul>
  <li>manage your subscription</li>
  <li>manage the payment processes between you and the University</li>
  <li>maintain accurate and up-to-date records</li>
  <li>event administration</li>
  <li>process any complaint you submit</li>
  <li>maintain or develop systems and processes</li>
  <li>meet legislative, statutory, contractual and audit requirements</li>
  <li>improve service levels</li>
  <li>update you on service changes, improvements and downtimes</li>
  <li>notify you of webinars, training events and conferences</li>
</ul>

<p>
  Digimap is provided in association with Ordnance Survey, National Library of Scotland, Getmapping and
  CollinsBartholomew, we may share your information with these partners where the information is required to run the
  service.
</p>

<p>
  We will hold the personal data you provided us for five years after the end of your subscription. We hold IP address
  information for five years from the date of visit.
</p>

<h2>Further information</h2>

<p>You can access our cookies policy <a (click)="navigate('cookie-policy')">here</a></p>

<p>If you have any questions, please contact the EDINA helpdesk on edina@ed.ac.uk or 0131 650 3302.</p>
