import { Observable } from 'rxjs';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { UserService } from './user.service';
import { CurrentUser } from '../../models/current-user.model';
import { Injectable } from '@angular/core';
import { take } from 'rxjs/operators';

@Injectable()
export class UserResolver implements Resolve<CurrentUser> {
  constructor(private userService: UserService) {}

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<CurrentUser> {
    return this.userService.user$.pipe(take(1));
  }
}
