<p *ngIf="schoolType === 'school'" class="prominent">
  Parents and carers: Only authorised school personnel may sign up on behalf of their school.
  <b>Please contact your school</b> to check if they have an existing subscription.
</p>
<div class="form-container">
  <form (ngSubmit)="fetchSchools()">
    <div class="postcode-field">
      <mat-form-field>
        <label for="postcode">Find your {{ schoolType }} by postcode</label>
        <input
          id="postcode"
          name="postcode"
          type="text"
          matInput
          data-test="Enter Postcode"
          [formControl]="postcodeControl"
          [matAutocomplete]="postcodeAuto"
          [errorStateMatcher]="errorMatcher"
          tabindex="0" />
        <mat-autocomplete #postcodeAuto="matAutocomplete" [autoActiveFirstOption]="true">
          <mat-option *ngFor="let postcode of postcodes$ | async" [value]="postcode.BillingPostalCode">
            {{ postcode.BillingPostalCode }}
          </mat-option>
        </mat-autocomplete>
        <mat-error *ngIf="!error && postcodeControl.hasError('no-results')">No postcode found</mat-error>
        <mat-error *ngIf="error">There has been an error - please try again</mat-error>
      </mat-form-field>
      <mat-spinner *ngIf="searching" [diameter]="40"></mat-spinner>
    </div>
    <button type="submit" mat-raised-button color="primary" data-test="Submit Postcode" [disabled]="postcodeDisabled()">
      Submit
    </button>
  </form>

  <p *ngIf="postcodeSubmitted && availableSchools.length === 0">
    No {{ schoolType }}s have been found with the postcode '{{ postcodeValue }}'
  </p>

  <form *ngIf="availableSchools.length" (ngSubmit)="onSubmit()">
    <p *ngIf="availableSchools.length === 1">There is 1 {{ schoolType }} with the postcode '{{ postcodeValue }}'</p>

    <p *ngIf="availableSchools.length > 1">
      There are {{ availableSchools.length }} {{ schoolType }}s with the postcode '{{ postcodeValue }}'
    </p>

    <mat-radio-group [formControl]="selectedSchoolControl">
      <mat-radio-button
        *ngFor="let school of availableSchools"
        color="primary"
        data-test="Select School"
        [value]="school.edinaOrgId"
        >{{ school.name }}
      </mat-radio-button>
    </mat-radio-group>
    <mat-spinner-button (click)="onSubmit()" data-test="Submit School" [options]="btnOpts"></mat-spinner-button>
  </form>
</div>

<div class="postcode-help">
  <p>Enter your {{ schoolType }}'s full postcode.</p>
  <p>
    If your {{ schoolType }} name or postcode does not appear, please contact the
    <a href="mailto:digimap.schools@ed.ac.uk">Helpdesk</a>.
  </p>
</div>
