<fieldset class="text-left">
  <legend class="text--bold">{{ title }}</legend>
  <div class="row">
    <div class="medium-4 columns text--bold">Name:</div>
    <div class="medium-8 columns breakable-data">{{ user.title }} {{ user.firstName }} {{ user.lastName }}</div>
  </div>
  <div class="row">
    <div class="medium-4 columns text--bold">Institution Name:</div>
    <div class="medium-8 columns breakable-data">
      {{ institution?.name }}
    </div>
  </div>
  <div class="row">
    <div class="medium-4 columns text--bold">Job Title:</div>
    <div class="medium-8 columns breakable-data">
      {{ user.jobTitle }}
    </div>
  </div>
  <div class="row">
    <div class="medium-4 columns text--bold">Email Address:</div>
    <div class="medium-8 columns breakable-data">
      {{ user.emailAddress }}
    </div>
  </div>
  <div class="row">
    <div class="medium-4 columns text--bold">Telephone Number:</div>
    <div class="medium-8 columns breakable-data">
      {{ user.telephoneNumber }}
    </div>
  </div>
</fieldset>
