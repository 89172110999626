import { Product } from './product.model';

export class CartItem {
  readonly product: Product;

  licenceAgreementSigned = {};

  // Date when this product should be invoiced, initially undefined
  invoiceDate: Date;

  // Full price is default
  proRataMonths = 12;
  constructor(cfg: Partial<CartItem> = {}) {
    if (!cfg.product) {
      throw new Error('Product is required');
    }
    if (!(cfg.product instanceof Product)) {
      throw new Error('Product must be an instance, not an interface');
    }
    this.product = cfg.product;
    console.log('CartItem#constructor', cfg.licenceAgreementSigned);
    this.licenceAgreementSigned = cfg.licenceAgreementSigned || this.licenceAgreementSigned;
    this.proRataMonths = cfg.product.proRataMonths || this.proRataMonths;
    this.invoiceDate = cfg.invoiceDate || this.invoiceDate;

    this.product.licences.forEach(l => (this.licenceAgreementSigned[l.id] = false));
  }
}
