<div class="home-page-main">
  <p>
    Welcome.<br /><br />

    <a href="https://digimap.edina.ac.uk">Digimap</a>,
    <a href="https://digimapforschools.edina.ac.uk">Digimap for Schools</a> and
    <a href="https://datanation.edina.ac.uk">DataNation</a> provide services to a range of higher educational and school
    communities. In order to use Digimap, Digimap for Schools or DataNation you first need to purchase a subscription.
    <br /><br />
    Please choose the option below that best describes your circumstances.
  </p>
  <div class="home-page-boxes">
    <div class="box">
      <div class="box-body">
        <a class="box-title he-colors" type="submit" routerLink="/institutions">
          <small>Go to</small>
          Universities and Colleges Login
        </a>
        <p>
          Digimap for Higher Education (Universities & Further Education Institutions)<br /><br />
          Note: choose this option if your institution uses the
          <a href="https://www.ukfederation.org.uk/">UK Access Management Federation (Shibboleth)</a>
          for authentication. If you are subscribing on behalf of a College that does not support UKAMF, please use our
          'Digimap Alternative for Colleges without UKAMF' option.
        </p>
      </div>
    </div>
    <div class="box">
      <div class="box-body">
        <a class="box-title school-colors" type="submit" routerLink="/schools">
          <small>Go to</small>
          Schools Login
        </a>
        <p>
          <a href="https://digimapforschools.edina.ac.uk">Digimap for Schools</a> and
          <a href="https://datanation.edina.ac.uk">DataNation</a><br /><br />
          Primary, Secondary and International (overseas) schools only.
        </p>
      </div>
    </div>
    <div class="box">
      <div class="box-body">
        <a class="box-title college-colors" type="submit" routerLink="/colleges">
          <small>Go to</small>
          Alternative (non UKAMF) Colleges Login
        </a>
        <p>
          Digimap Alternative for Colleges without UKAMF<br /><br />
          Note: Digimap requires institutional support for UKAMF. For Colleges without UKAMF we provide an alternative
          to Digimap that does not require UKAMF:
          <a href="https://datanation.edina.ac.uk">DataNation</a>
        </p>
      </div>
    </div>
  </div>
</div>
