import { RemoteBasket, RemoteBasketItem } from 'app/models/remote.model';

import { CartItem } from 'app/models/cart-item.model';
import { ShoppingCart } from 'app/models/shopping-cart.model';

export class RemoteOrderAdaptor {
  static toRemoteBasketProduct(cart: ShoppingCart, item: CartItem): RemoteBasketItem {
    const licenceAgreementSigned = Object.keys(item.licenceAgreementSigned).every(
      productKey => item.licenceAgreementSigned[productKey]
    );

    return {
      licenceAgreementSigned,
      product: item.product.id,
      proRataMonths: item.proRataMonths,
      discount: cart.getDiscountApplied(item.product.discounts) !== undefined,
    };
  }

  static toRemoteOrders(cart: ShoppingCart): RemoteBasket[] {
    const baskets: RemoteBasket[] = [];
    cart.getInvoiceDates().forEach(invoiceDate => {
      const cartItems = cart.getItemsForInvoiceDate(invoiceDate);
      baskets.push(RemoteOrderAdaptor.toRemoteOrder(cart, cartItems, invoiceDate));
    });
    return baskets;
  }

  private static toRemoteOrder(cart: ShoppingCart, cartItems: CartItem[], invoiceDate: Date): RemoteBasket {
    return {
      allAgreementsSigned: cart.allAgreementsSigned(),
      authorisationSigned: cart.authorisationConfirmed,
      tosAgreementSigned: cart.tosAgreementSigned,
      purchaser: cart.purchaser,
      invoiceRecipient: cart.invoiceRecipient,
      invoiceAddress: cart.invoiceAddress,
      additionalNotes: cart.additionalNotes,
      additionalContacts: cart.additionalContacts
        .filter(c => c.emailAddress !== undefined && c.lastName !== undefined)
        .map(contact => ({
          title: contact.title,
          firstName: contact.firstName,
          lastName: contact.lastName,
          email: contact.emailAddress,
          role: 'Teacher',
        })),
      purchaserIsRecipient: cart.isPurchaserRecipient,
      orderIsComplete: true,
      basketItems: cartItems.map(item => RemoteOrderAdaptor.toRemoteBasketProduct(cart, item)),
      purchaseOrder: cart.purchaseOrderNum,
      vatNumber: cart.vatRegistrationNumber,
      companyNumber: cart.companyNumber,
      charityNumber: cart.charityNumber,
      invoiceDate,
    };
  }
}
