<h1>Exit Survey</h1>
<div *ngIf="!showForm && !getDataError && !showSuccessMessage">
  <mat-spinner></mat-spinner>
</div>
<mat-error *ngIf="!showForm && !showSuccessMessage"> You don't have permissions to fill the survey!</mat-error>
<form [formGroup]="formGroup" (ngSubmit)="handleSubmit()" *ngIf="showForm && !showSuccessMessage">
  <p>
    We are very sorry that you have decided not to continue with the Digimap for Schools service. In order to improve
    the service we would be grateful if you could complete the following questionnaire.
  </p>
  <mat-form-field>
    <input matInput placeholder="Title" formControlName="title" required />
  </mat-form-field>
  <mat-form-field>
    <input matInput placeholder="Name" formControlName="firstName" required />
  </mat-form-field>
  <mat-form-field>
    <input matInput placeholder="Surname" formControlName="lastName" required />
  </mat-form-field>
  <mat-form-field>
    <input matInput type="email" placeholder="Enter your email" formControlName="email" required />
  </mat-form-field>

  <label id="cancelling-reason-label">What is the single biggest reason for cancelling?</label>
  <mat-radio-group
    name="cancelReason"
    aria-labelledby="cancelling-reason-label"
    class="radio-group"
    formControlName="cancelReason"
    required>
    <mat-radio-button color="primary" class="radio-btn" *ngFor="let reason of cancelReasons" [value]="reason">
      {{ reason }}
    </mat-radio-button>
  </mat-radio-group>
  <mat-form-field *ngIf="formGroup.value.cancelReason === 'Other'" class="long-input">
    <input
      matInput
      placeholder="Please provide more detail about other reasons for cancelling"
      formControlName="cancelOther" />
  </mat-form-field>

  <mat-form-field class="long-input">
    <textarea
      matInput
      placeholder="Why did you first purchase Digimap for Schools?"
      rows="5"
      formControlName="purchaseReason"></textarea>
  </mat-form-field>

  <label id="usage-reason-label"
    >If lack of usage was the reason for cancelling please could you tell us main reason that usage was low:</label
  >
  <mat-radio-group
    name="usageReason"
    aria-labelledby="usage-reason-label"
    class="radio-group"
    formControlName="usageReason">
    <mat-radio-button color="primary" class="radio-btn" *ngFor="let reason of usageReasons" [value]="reason">
      {{ reason }}
    </mat-radio-button>
  </mat-radio-group>
  <mat-form-field *ngIf="formGroup.value.usageReason === 'Other'" class="long-input">
    <input
      matInput
      placeholder="Please provide more detail about other reasons for low usage"
      formControlName="usageOther" />
  </mat-form-field>

  <label id="dfs-alternative-label">Now that you do not have Digimap for Schools what will you use instead?</label>
  <mat-radio-group
    name="dfsAlternative"
    aria-labelledby="dfs-alternative-label"
    class="radio-group"
    formControlName="dfsAlternative"
    required>
    <mat-radio-button
      color="primary"
      class="radio-btn"
      *ngFor="let alternative of dfsAlternatives"
      [value]="alternative">
      {{ alternative }}
    </mat-radio-button>
  </mat-radio-group>
  <mat-form-field *ngIf="formGroup.value.dfsAlternative === 'Other'" class="long-input">
    <input matInput placeholder="Please provide more detail about other alternatives" formControlName="altOther" />
  </mat-form-field>

  <button class="button" type="submit" [disabled]="!formGroup.valid">Submit</button>
  <mat-error *ngIf="showSubmitError">Your submission has failed. Please Try again!</mat-error>
</form>
<div *ngIf="showSuccessMessage">Thank you for helping us to improve our service.</div>
