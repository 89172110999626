import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  templateUrl: './privacy.component.html',
  styleUrls: ['./privacy.component.scss'],
})
export class PrivacyComponent implements OnInit {
  constructor(private router: Router) {}

  ngOnInit() {}

  navigate(route: string) {
    this.router.navigate([route]);
  }
}
