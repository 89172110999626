import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import {
  AlreadyInvoicedErrorComponent,
  AlreadyInvoicedErrorComponentData,
} from 'app/components/error/already-invoiced/already-invoiced-error.component';
import { ErrorDialogData, ErrorDialogService } from 'app/components/error/error-dialog.service';
import {
  UnexpectedErrorComponent,
  UnexpectedErrorComponentData,
} from 'app/components/error/unexpected/unexpected-error.component';

import { PageComponent } from 'app/components/page/PageComponent';
import { CurrentUser } from 'app/models/current-user.model';
import { ShoppingCart } from 'app/models/shopping-cart.model';
import { BundleService } from 'app/services/product/bundling/bundle.service';
import { AlternativeBundle } from 'app/services/product/bundling/alternative-bundle';
import { ShoppingCartService } from 'app/services/shopping-cart.service';
import { UserService } from 'app/services/user/user.service';
import { AppPage, AppPages } from 'app/state/app-page';
import { MatProgressButtonOptions } from 'mat-progress-buttons';
import { ProductAlreadyInvoicedError } from '../../../services/product-already-invoiced-error';

@Component({
  templateUrl: './order-confirmation.component.html',
  styleUrls: ['./order-confirmation.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OrderConfirmationComponent extends PageComponent implements OnInit {
  page: AppPage = AppPages.confirm;
  productsPage = AppPages.products;

  cart: ShoppingCart;
  user: CurrentUser;
  orderInProgress: boolean = false;
  cheaperBundles: AlternativeBundle[] = [];
  isPurchaserShown: boolean;
  btnOpts: MatProgressButtonOptions = {
    active: false,
    text: 'Confirm and Place Order',
    spinnerSize: 30,
    raised: true,
    stroked: false,
    buttonColor: 'primary',
    spinnerColor: 'accent',
    fullWidth: true,
    disabled: false,
    mode: 'indeterminate',
  };

  constructor(
    private shoppingCartService: ShoppingCartService,
    private router: Router,
    private userService: UserService,
    private errorDialogService: ErrorDialogService,
    private bundleService: BundleService,
    private changeDetector: ChangeDetectorRef
  ) {
    super();
    this.shoppingCartService.cart$.subscribe(cart => (this.cart = cart));
    this.userService.user$.subscribe(u => (this.user = u));
    this.bundleService.cheaperBundles$.subscribe(cheaper => (this.cheaperBundles = cheaper));
  }

  ngOnInit() {
    if (this.cart.grossTotal.value !== 0.0) {
      this.isPurchaserShown = true;
    } else {
      this.isPurchaserShown = false;
    }
  }

  async onConfirmOrderClicked(): Promise<void> {
    try {
      this.orderInProgress = true;
      this.btnOpts.active = true;
      await this.shoppingCartService.placeOrder();
      this.orderInProgress = false;
      this.router.navigate(['/' + AppPages.orderSummary.navPath]);
    } catch (e) {
      let data: ErrorDialogData;
      if (e instanceof ProductAlreadyInvoicedError) {
        data = {
          errorComponent: AlreadyInvoicedErrorComponent,
          title: 'Product Already Ordered',
          products: e.products,
        } as AlreadyInvoicedErrorComponentData;
      } else {
        data = {
          errorComponent: UnexpectedErrorComponent,
          title: 'Unexpected Error',
          message: 'An error occurred while processing your order.',
        } as UnexpectedErrorComponentData;
      }
      await this.errorDialogService.showErrorDialog(data);
      this.orderInProgress = false;
      this.btnOpts.active = false;
      this.changeDetector.detectChanges();
    }
  }
}
