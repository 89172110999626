import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { LoaderService } from './services/loader.service';

@Injectable()
export class LoadingInterceptor implements HttpInterceptor {
  private totalRequests = 0;
  private timerStarted: boolean = false;
  private nIntervId;

  constructor(private loadingService: LoaderService) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    this.totalRequests++;
    if (!this.timerStarted) {
      // Only show loading spinner if waited for more than 2 seconds
      this.startTimer();
    }
    return next.handle(request).pipe(
      finalize(() => {
        this.totalRequests--;
        if (this.totalRequests === 0) {
          this.timerStarted = false;
          clearInterval(this.nIntervId);
          this.loadingService.setLoading(false);
        }
      })
    );
  }

  private startTimer() {
    this.timerStarted = true;
    this.nIntervId = setInterval(() => {
      this.timerStarted = false;
      if (this.totalRequests > 0) {
        this.loadingService.setLoading(true);
      }
    }, 1000);
  }
}
