import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Input,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { BundleAlertDialogComponent } from 'app/components/shopping-cart/bundle-alert-dialog/bundle-alert-dialog.component';

import { Product } from 'app/models/product.model';
import { ShoppingCart } from 'app/models/shopping-cart.model';
import { BundleService } from 'app/services/product/bundling/bundle.service';
import { AlternativeBundle } from 'app/services/product/bundling/alternative-bundle';
import { ShoppingCartService } from 'app/services/shopping-cart.service';
import { AppStateService } from 'app/state/app-state.service';
import { filter, first } from 'rxjs/operators';
import { Subscription } from 'rxjs';
import { ProductService } from '../../services/product/products.service';
import { Router } from '@angular/router';
import { ProductUtil } from '../../helpers/product-util';

@Component({
  selector: 'dm-shopping-cart',
  templateUrl: './shopping-cart.component.html',
  styleUrls: ['./shopping-cart.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ShoppingCartComponent implements OnInit, OnDestroy {
  // When true next page can (optionally) be "upgrades and discounts"
  @Input() enableUpgrades: boolean = true;

  // If defined only products in the list can be removed from the basket
  @Input() allowRemoval?: Product[];

  cart: ShoppingCart;
  cartProducts: Product[];
  cartBundleProducts: Product[];
  cartStandaloneProducts: Product[];
  allProducts: Product[];

  @ViewChild('shoppingCart', { static: true })
  shoppingCart: ElementRef;

  private cartSubscription: Subscription = new Subscription();
  private dialogRef: MatDialogRef<BundleAlertDialogComponent> | undefined;

  constructor(
    private productsService: ProductService,
    private cartService: ShoppingCartService,
    public appState: AppStateService,
    private changeDetector: ChangeDetectorRef,
    private bundleService: BundleService,
    private matDialog: MatDialog,
    private router: Router
  ) {}

  emptyCart(): void {
    this.cartService.empty();

    this.cartService.cart$.pipe(first()).subscribe(() => {
      console.log('ShoppingCartComponent#emptiedCart');
      this.changeDetector.markForCheck();
    });
  }

  ngOnInit(): void {
    this.cartService.cart$.subscribe(cart => this.onCartUpdate(cart));
    this.productsService.products$.subscribe(products => (this.allProducts = products));
    this.bundleService.cheaperBundles$
      .pipe(filter(cheaper => cheaper.length > 0))
      .subscribe(cheaper => this.onCheaperBundle(cheaper));
    this.bundleService.cheaperBundles$
      .pipe(filter(cheaper => cheaper.length === 0))
      .subscribe(() => this.appState.setSuppressBundlePriceAlert(false));
  }

  ngOnDestroy(): void {
    if (this.cartSubscription) {
      this.cartSubscription.unsubscribe();
    }
  }

  canRemoveProduct(product: Product): boolean {
    return (
      !this.hasAllowRemovalList() ||
      (!!this.allowRemoval && this.allowRemoval.find(p => p.id === product.id) !== undefined)
    );
  }

  hasAllowRemovalList(): boolean {
    return !!this.allowRemoval && this.allowRemoval.length > 0;
  }

  removeProductFromCart(product: Product): void {
    console.log('ShoppingCartComponent#removeProductFromCart');
    this.cartService.removeItem(product);

    // Check and remove any other products which have a dependency on the one just removed
    this.cart.items.forEach(item => {
      if (item.product.dependency && product.hasLicenceId(item.product.dependency)) {
        // could be a call to #this.cartsService.removeItem(dependency.product), this way in case of recursive
        // dependency
        this.removeProductFromCart(item.product);
      }
    });
  }

  onNext() {
    const upgrades = this.getUpgrades();
    if (this.enableUpgrades && upgrades.length > 0) {
      // Upgrages available we want to upsell
      this.router.navigate(['upgrades']);
    } else {
      // Otherwise no upgrades, just go directly to the checkout
      this.router.navigate(['checkout']);
    }
  }

  /**
   * Upgrades are products which have an "applied" discount (i.e. the product(s) they depend on are in the shopping
   * cart) but the product itself has not been selected by the user.
   */
  private getUpgrades(): Product[] {
    return this.allProducts.filter(
      product =>
        !this.cart.containsProductId(product.productCode) && ProductUtil.appliedDiscounts(product, this.cart).length > 0
    );
  }

  private onCartUpdate(cart: ShoppingCart) {
    this.cart = cart;
    this.cartProducts = cart.items.map(item => item.product);

    this.cartBundleProducts = cart.items.filter(item => item.product.isBundle()).map(item => item.product);

    this.cartStandaloneProducts = cart.items.filter(item => !item.product.isBundle()).map(item => item.product);

    this.changeDetector.markForCheck();
  }

  private onCheaperBundle(cheaper: AlternativeBundle[]) {
    if (!this.appState.getSuppressBundlePriceAlert() && !this.dialogRef) {
      this.dialogRef = this.matDialog.open(BundleAlertDialogComponent, {
        width: '600px',
        height: '300px',
        data: {
          cheaperBundles: cheaper,
        },
      });

      this.dialogRef.afterClosed().subscribe(() => {
        delete this.dialogRef;
        this.appState.setSuppressBundlePriceAlert(true);
      });
    }
  }
}
