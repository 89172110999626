<ng-container *ngIf="!isErrorPageActive()">
  <nav class="upper-header">
    <ul class="breadcrumbs">
      <li><a routerLink="" class="breadcrumb">Home</a></li>
      <li *ngFor="let breadcrumb of breadcrumbs">
        <a [routerLink]="[breadcrumb.url, breadcrumb.params]">{{ breadcrumb.label }}</a>
      </li>
    </ul>

    <div class="page-info">
      <p *ngFor="let breadcrumb of breadcrumbs">
        {{ breadcrumb.description }}
      </p>
    </div>
  </nav>

  <!-- div commented after meeting with user support concluded that removing the steps would simplify the page better
div class="lower-header hide-for-small-only">
  <div class="order-steps" *ngIf="showCheckoutSteps()">
    <div class="dms-checkout-steps">
      <ng-container *ngFor="let page of checkoutPages; last as isLast">
        <div class="dms-checkout-page-item">
          <a [ngClass]="{ 'dms-disabled': !isStepEnabled(page), 'dms-active': isPageActive(page)}"
             [routerLink]="'/' + page.navPath">{{page.title}}</a>
        </div>
        <div *ngIf="!isLast" class="dms-divider"></div>
      </ng-container>
    </div>
  </div>
</div-->
</ng-container>
