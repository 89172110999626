import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { AllPagesArray, AppPage, AppPages } from 'app/state/app-page';
import { catchError, first, map } from 'rxjs/operators';

import { CurrentUser } from 'app/models/current-user.model';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { UserService } from 'app/services/user/user.service';
import { of } from 'rxjs';

/**
 * All endpoints except 'institutions' require an authenticated user.
 * All endpoints except 'institutions' and 'passcode' also require a valid pin/passcode have been entered.
 */
@Injectable()
export class AuthenticationGuard implements CanActivate {
  constructor(private userService: UserService, private router: Router) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    const originalTargetPage = AllPagesArray.find(page => page.matchesUrl(state.url))!;
    return this.canActivateWithTokenCheck(originalTargetPage);
  }

  private canActivateWithTokenCheck(originalTarget: AppPage): Observable<boolean> {
    return this.userService.verifyUser().pipe(
      first(),
      map(user => {
        let allow = true;
        if (!user) {
          allow = false;
          this.redirectTo(AppPages.institutions);
        } else if (this.shouldDirectToPinPage(user, originalTarget)) {
          allow = false;
          this.redirectTo(AppPages.login);
        }
        return allow;
      }),
      catchError(() => {
        this.redirectTo(AppPages.error);
        return of(false);
      })
    );
  }

  private shouldDirectToPinPage(user: CurrentUser, originalTarget: AppPage): boolean {
    return originalTarget !== AppPages.login && !user.hasEnteredPin;
  }

  private redirectTo(page: AppPage) {
    this.router.navigate([page.navPath]);
  }
}
