import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AlternativeBundle } from 'app/services/product/bundling/alternative-bundle';

@Component({
  selector: 'dm-dialog',
  templateUrl: './bundle-alert-dialog.component.html',
  styleUrls: ['./bundle-alert-dialog.component.scss'],
})
export class BundleAlertDialogComponent {
  constructor(@Inject(MAT_DIALOG_DATA) public data: { cheaperBundles: AlternativeBundle[] }) {}
}
