<ng-container *ngIf="school; else unknownschool">
  <h1>{{ school.name }}</h1>

  <table>
    <tr>
      <th>Address</th>
      <td>{{ formatAddress(school.address.address) }}</td>
    </tr>
    <tr>
      <th>Postcode</th>
      <td>{{ school.address.postcode }}</td>
    </tr>
    <tr>
      <th>Telephone</th>
      <td>{{ school.phone }}</td>
    </tr>
    <tr>
      <th>Phase of Education</th>
      <td>{{ phaseOfEducation(school.institutionType) }}</td>
    </tr>
  </table>

  <div *ngIf="isOfsted" class="ofsted-information">
    Your {{ schoolType }}'s status means that you may be eligible for free access to Digimap for Schools. If your school
    is eligible, you will see a FREE OFSTED product on the next page, which you can select. Once you have added this to
    your basket, check that the price is zero before continuing. Please check that you have selected the correct
    product.
  </div>

  <mat-spinner-button (click)="login()" data-test="Choose School" [options]="btnOpts"></mat-spinner-button>

  <p>Wrong {{ schoolType }}? <a [routerLink]="postcodeNavPath">Go back</a>.</p>
  <p>Wrong info? <a href="mailto:digimap.schools@ed.ac.uk">Contact us!</a></p>
</ng-container>

<ng-template #unknownschool> Unknown {{ schoolType }} - <a [routerLink]="postcodeNavPath">Try again</a> </ng-template>
