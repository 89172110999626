export const validateText = (
  value: string | null | undefined,
  required: boolean,
  minLength: number,
  maxLength: number = 1000
) => {
  let valid: boolean = true;
  if (value === null || value === undefined) {
    valid = !required;
  } else {
    if (minLength > 0) {
      valid = valid && value.length >= minLength;
    }
    if (maxLength) {
      // angular form validators are max-inclusive.
      valid = valid && value.length <= maxLength;
    }
  }
  return valid;
};
