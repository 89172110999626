import { Component, Input } from '@angular/core';
import { ShoppingCart } from '../../models/shopping-cart.model';

@Component({
  selector: 'dm-order-total-with-vat',
  templateUrl: './order-total-with-vat.component.html',
  styleUrls: ['./order-total-with-vat.component.scss'],
})
export class OrderTotalWithVatComponent {
  @Input('cart')
  cart: ShoppingCart;
}
