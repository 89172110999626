import { validateText } from '../validate/validate';
export class Address {
  public static GB_CODE = 'GB';

  static readonly validations = {
    address: {
      minLength: 1,
      maxLength: 512,
    },
    postcode: {
      minLength: 5,
      maxLength: 9,
      regex:
        '^(([Gg][Ii][Rr] 0[Aa]{2})|((([A-Za-z][0-9]{1,2})|(([A-Za-z][A-Ha-hJ-Yj-y][0-9]{1,2})|' +
        '(([A-Za-z][0-9][A-Za-z])|([A-Za-z][A-Ha-hJ-Yj-y][0-9]?[A-Za-z])))) ?[0-9][A-Za-z]{2}))$',
    },
    country: {
      length: 2,
    },
  };

  address: string = '';
  postcode: string = '';
  country: string = Address.GB_CODE;

  constructor(cfg: Partial<Address> = {}) {
    this.address = cfg.address ? cfg.address : this.address;
    this.postcode = cfg.postcode ? cfg.postcode : this.postcode;
    this.country = cfg.country ? cfg.country : this.country;
  }

  isValid(): boolean {
    const V = Address.validations;
    return (
      (validateText(this.address, true, V.address.minLength, V.address.maxLength) &&
        validateText(this.postcode, true, V.postcode.minLength, V.postcode.maxLength)) ||
      this.country !== Address.GB_CODE
    );
  }
}
