import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { BreadcrumbService, IBreadcrumb } from '../../services/breadcrumb.service';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { AppPage, AppPages, CheckoutPageArray } from '../../state/app-page';
import { AppStateService } from '../../state/app-state.service';
import { filter } from 'rxjs/operators';

@Component({
  selector: 'dm-header',
  styleUrls: ['./header.component.scss'],
  templateUrl: './header.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HeaderComponent implements OnInit {
  breadcrumbs: IBreadcrumb[];
  checkoutPages = CheckoutPageArray; // copy local for access in template.

  constructor(
    private appState: AppStateService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private breadcrumbService: BreadcrumbService,
    private changeDetector: ChangeDetectorRef
  ) {
    this.appState.navStateChange$.subscribe(() => this.changeDetector.markForCheck());
  }

  ngOnInit() {
    this.router.events.pipe(filter(event => event instanceof NavigationEnd)).subscribe(event => {
      const root: ActivatedRoute = this.activatedRoute.root;
      /* @todo: ggranum: In breadcrumb service, subscribe to the navStateChange event as above and emit an
       * observable<Breadcrumb[]> or whatever.
       */
      this.breadcrumbs = this.breadcrumbService.getBreadcrumbs(root);
    });
  }

  showCheckoutSteps() {
    return !(
      this.appState.isPageActive(AppPages.login) ||
      this.appState.isPageActive(AppPages.institutions) ||
      this.appState.isPageActive(AppPages.schools) ||
      this.appState.isPageActiveExact(AppPages.root) ||
      this.appState.isPageActive(AppPages.survey) ||
      this.appState.isPageActive(AppPages.colleges)
    );
  }

  isPageActive(page: AppPage): boolean {
    return this.appState.isPageActive(page);
  }

  isStepEnabled(page: AppPage): boolean {
    return this.appState.isPageEnabled(page);
  }

  isErrorPageActive() {
    return this.appState.isPageActive(AppPages.error);
  }
}
