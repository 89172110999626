import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { Product } from 'app/models/product.model';

import { PageComponent } from '../PageComponent';
import { ProductService } from 'app/services/product/products.service';
import { AppPage, AppPages } from '../../../state/app-page';
import { ProductUtil } from '../../../helpers/product-util';
import { ShoppingCartService } from '../../../services/shopping-cart.service';
import { combineLatest } from 'rxjs';
import { take } from 'rxjs/operators';

@Component({
  selector: 'dm-updates',
  styleUrls: ['./upgrades-page.component.scss'],
  templateUrl: './upgrades-page.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UpgradesPageComponent extends PageComponent implements OnInit {
  page: AppPage = AppPages.upgrades;

  upgrades: Product[] = [];

  constructor(private productsService: ProductService, private cartService: ShoppingCartService) {
    super();
  }

  ngOnInit() {
    combineLatest([this.productsService.products$, this.cartService.cart$])
      .pipe(take(1))
      .subscribe(([products, cart]) => {
        this.upgrades = products.filter(product => ProductUtil.isUpgrade(product, cart));
      });
  }
}
