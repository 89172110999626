interface IAppPage {
  navPath: string;
  title: string;
  header: string;
}

export class AppPage implements IAppPage {
  navPath: string;
  title: string;
  header: string;

  constructor(cfg: IAppPage) {
    this.navPath = cfg.navPath;
    this.title = cfg.title;
    this.header = cfg.header;
  }

  matchesUrl(url: string): boolean {
    return this.trimUrl(url) === this.navPath;
  }

  private trimUrl(url: string): string {
    const startIdx = url.length > 1 && url[0] === '/' ? 1 : 0;
    let endIdx = url.indexOf('?');
    endIdx = endIdx > startIdx ? endIdx : url.length;
    return url.substring(startIdx, endIdx);
  }
}

export const StaticAppPages = {
  root: { navPath: '', title: 'Home', header: 'Home' },
  cookiePolicy: { navPath: 'cookie-policy', title: 'Cookie Policy', header: 'Cookie Policy' },
  privacy: { navPath: 'privacy', title: 'Privacy', header: 'Privacy' },
  error: { navPath: 'error', title: 'Error', header: 'Server Error Encountered' },
  unauthorised: { navPath: 'unauthorised', title: 'Unauthorized', header: 'Unauthorized' },
  institutions: { navPath: 'institutions', title: 'Institutions', header: 'Choose Institution' },
  schools: { navPath: 'schools', title: 'Choose School', header: 'Choose School' },
  colleges: { navPath: 'colleges', title: 'Choose College', header: 'Choose College' },
  survey: { navPath: 'schools/exit-survey', title: 'Exit survey', header: 'Exit Survey' },
  login: { navPath: 'pin', title: 'Login', header: 'Login' },
  products: { navPath: 'products', title: 'Products', header: 'Products' },
  upgrades: { navPath: 'upgrades', title: 'Last chance to save', header: 'Last chance to save' },
  checkout: { navPath: 'checkout', title: 'Agree', header: 'Agree to Licences' },
  billingDetails: { navPath: 'billing', title: 'Billing Details', header: 'Billing Details' },
  confirm: { navPath: 'confirmed', title: 'Confirm', header: 'Confirm Order' },
  orderSummary: { navPath: 'summary', title: 'Summary', header: 'Thank you for your order' },
};

export const AppPages = {
  root: new AppPage(StaticAppPages.root),
  error: new AppPage(StaticAppPages.error),
  institutions: new AppPage(StaticAppPages.institutions),
  schools: new AppPage(StaticAppPages.schools),
  colleges: new AppPage(StaticAppPages.colleges),
  survey: new AppPage(StaticAppPages.survey),
  login: new AppPage(StaticAppPages.login),
  products: new AppPage(StaticAppPages.products),
  upgrades: new AppPage(StaticAppPages.upgrades),
  checkout: new AppPage(StaticAppPages.checkout),
  billingDetails: new AppPage(StaticAppPages.billingDetails),
  confirm: new AppPage(StaticAppPages.confirm),
  orderSummary: new AppPage(StaticAppPages.orderSummary),
};

export const CheckoutPageArray: AppPage[] = [
  AppPages.products,
  AppPages.upgrades,
  AppPages.checkout,
  AppPages.billingDetails,
  AppPages.confirm,
  AppPages.orderSummary,
];

export const AllPagesArray: AppPage[] = [AppPages.root, AppPages.error, AppPages.institutions, AppPages.login].concat(
  CheckoutPageArray
);
