import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ErrorDialogData } from 'app/components/error/error-dialog.service';
import { Product } from 'app/models/product.model';
import { AppPage, AppPages } from 'app/state/app-page';

export interface AlreadyInvoicedErrorComponentData extends ErrorDialogData {
  products: Product[];
}

@Component({
  templateUrl: './already-invoiced-error.component.html',
  styleUrls: ['./already-invoiced-error.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AlreadyInvoicedErrorComponent {
  title: string;
  products: Product[];
  productsPage: AppPage = AppPages.products;

  constructor(@Inject(MAT_DIALOG_DATA) public data: AlreadyInvoicedErrorComponentData) {
    this.title = this.data.title || 'Unexpected Error';
    this.products = this.data.products;
  }

  /**
   * Using 'routerLink' on the anchor tag would be great, but we'd have to pass in the dialog and close it,
   * which means we'd have to clean up listeners on the dialog close event so the destroyed component
   * didn't try to use them... bah.
   */
  onGoToProductsPage() {
    window.location.replace('/' + this.productsPage.navPath);
  }
}
