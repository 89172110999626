import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { AppStateService } from '../state/app-state.service';
import { AppPages } from '../state/app-page';
import { first, map, tap } from 'rxjs/operators';

@Injectable()
export class NavSummaryGuard implements CanActivate {
  constructor(private appService: AppStateService, private router: Router) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    return this.appService.ready$.pipe(
      first(ready => ready === true),
      map(() => this.appService.isSummaryPageEnabled()),
      tap(available => {
        if (!available) {
          this.navigateToEnabledPage();
        }
      })
    );
  }

  /**
   * Attempts to navigate to the 'last' available page in the checkout process.
   */
  private navigateToEnabledPage() {
    const nextBestPage = this.appService.previousEnabledCheckoutPage(AppPages.orderSummary);
    if (nextBestPage) {
      this.router.navigate([nextBestPage.navPath]);
    }
  }
}
