<dm-sub-header [icon]="'done_all'" [title]="page.header">
  <button class="warning button large" type="submit" data-test="Start New Order" (click)="startANewOrder()">
    Start a New Order
  </button>
  <button class="secondary-button button" type="submit" (click)="printTheSummary()">Print</button>
</dm-sub-header>

<div id="section-to-print">
  <h4>Digimap Order Summary</h4>

  <p *ngIf="isPurchaserShown" class="lead">
    Thank you for submitting your order. This page is not an invoice. An invoice will be sent to the contact details
    below. This page is for information only.
  </p>

  <p *ngIf="!isPurchaserShown" class="lead">
    Thank you for submitting your order. This page is not an invoice. This page is for information only.
  </p>

  <p *ngIf="isPurchaserShown" class="lead">
    An invoice will be issued by The University of Edinburgh. Please ensure that the University of Edinburgh is set up
    as a supplier, using the billing information on our website here:
    <a href="https://www.ed.ac.uk/finance/business/bills">https://www.ed.ac.uk/finance/business/bills</a>.
  </p>

  <div class="ordered-products">
    <h4>Ordered Products</h4>

    <dm-order-total-with-vat [cart]="cart"></dm-order-total-with-vat>

    <fieldset class="medium-11 columns medium-centered" *ngFor="let item of cart.items">
      <legend class="text--bold js-product-name">{{ item.product.name }}</legend>
      <div *ngIf="item.product.icon" class="medium-2 columns text-center product-image">
        <img src="./assets/{{ item.product.icon }}" class="product-image" />
      </div>
      <div class="medium-6 columns">
        <p class="product_date">
          Start Date: <span class="text--bold">{{ item.product.startDate | date: 'longDate' }}</span>
          <br />
          End Date: <span class="text--bold">{{ item.product.endDate | date: 'longDate' }}</span>
          <br />
          Invoice Date: <span class="text--bold">{{ item.invoiceDate | date: 'longDate' }}</span>
        </p>
      </div>
      <div class="medium-4 columns text-right">
        <dm-price-with-vat
          [product]="item.product"
          [showDiscount]="cart.getDiscountApplied(item.product.discounts)"></dm-price-with-vat>
      </div>
    </fieldset>
  </div>

  <div class="order-details">
    <h4>Contact details</h4>

    <div class="medium-11 columns medium-centered">
      <dm-ordered-by-summary
        [user]="cart.purchaser"
        [institutionName]="user.institution?.name"
        [title]="'Ordered By:'"></dm-ordered-by-summary>
    </div>
    <br />

    <div [ngClass]="{ 'medium-11 columns medium-centered': isPurchaserShown, hide: !isPurchaserShown }">
      <dm-ordered-by-summary
        [user]="cart.invoiceRecipient"
        [institutionName]="user.institution?.name"
        [title]="'Invoice Contact:'"></dm-ordered-by-summary>
    </div>
    <br />

    <div [ngClass]="{ 'medium-11 columns medium-centered': isPurchaserShown, hide: !isPurchaserShown }">
      <h5>Purchaser Address:</h5>
      <div class="row">
        <div class="medium-offset-1 medium-3 columns text--bold">Address:</div>
        <div class="medium-8 columns breakable-data">{{ cart.invoiceAddress.address }}</div>
      </div>
      <div class="row">
        <div class="medium-offset-1 medium-3 columns text--bold">Postcode:</div>
        <div class="medium-8 columns breakable-data">
          {{ cart.invoiceAddress.postcode }}
        </div>
      </div>
      <div class="row">
        <div class="medium-offset-1 medium-3 columns text--bold">Country:</div>
        <div class="medium-8 columns breakable-data">
          {{ cart.invoiceAddress.country }}
        </div>
      </div>
    </div>
    <br />

    <div [ngClass]="{ 'medium-11 columns medium-centered': isPurchaserShown, hide: !isPurchaserShown }">
      <h5>Additional Information:</h5>
      <div class="row">
        <div class="medium-offset-1 medium-3 columns text--bold">PO Number:</div>
        <div class="medium-8 columns breakable-data">
          {{ cart.purchaseOrderNum }}
        </div>
      </div>
      <div class="row">
        <div class="medium-offset-1 medium-3 columns text--bold">VAT Registration Number:</div>
        <div class="medium-8 columns breakable-data">
          {{ cart.vatRegistrationNumber }}
        </div>
      </div>
      <div class="row">
        <div class="medium-offset-1 medium-3 columns text--bold">Company Number:</div>
        <div class="medium-8 columns breakable-data">
          {{ cart.companyNumber }}
        </div>
      </div>
      <div class="row">
        <div class="medium-offset-1 medium-3 columns text--bold">Charity Number:</div>
        <div class="medium-8 columns po-number">
          {{ cart.charityNumber }}
        </div>
      </div>
      <div class="row">
        <div class="medium-offset-1 medium-3 columns text--bold">Dispatch Invoice:</div>
        <div class="medium-8 columns breakable-data">
          <span *ngFor="let date of cart.getInvoiceDates(); let isLast = last">
            {{ date | date: 'longDate' }}{{ isLast ? '' : ', ' }}
          </span>
        </div>
      </div>
      <div class="row">
        <div class="medium-offset-1 medium-3 columns text--bold">Additional Notes:</div>
        <div class="medium-8 columns breakable-data">{{ cart.additionalNotes }}</div>
      </div>
    </div>
    <br />

    <div [ngClass]="'medium-11 columns medium-centered'">
      <h5>Additional Contact:</h5>
      <div *ngFor="let information of cart.additionalContacts; let i = index">
        <div class="row" *ngIf="information.lastName">
          <div class="medium-offset-1 medium-3 columns text--bold">Contact Name #{{ i + 1 }}:</div>
          <div class="medium-8 columns breakable-data">
            {{ information.title }} {{ information.firstName }} {{ information.lastName }}
          </div>
        </div>
        <div class="row" *ngIf="information.emailAddress">
          <div class="medium-offset-1 medium-3 columns text--bold">Contact Email Address #{{ i + 1 }}:</div>
          <div class="medium-8 columns po-number">
            {{ information.emailAddress }}
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<footer></footer>
