<div [style.text-decoration]="showDiscount ? 'line-through' : 'none'">
  <span>{{ getPriceText() }}</span>
  <span class="amount-text">{{ product.proRataPrice.format() }}</span>
</div>
<div *ngIf="!showDiscount && showVat">
  <span>VAT</span>
  <span class="amount-text">{{ product.vatAmount.format() }}</span>
</div>
<div *ngIf="showDiscount">
  <div>
    <span>{{ getPriceText() }}</span>
    <span class="amount-text">{{ showDiscount.proRataPriceWithDiscount.format() }}</span>
  </div>
  <div *ngIf="showVat">
    <span>VAT</span>
    <span class="amount-text">{{ showDiscount.vatAmount.format() }}</span>
  </div>
</div>
