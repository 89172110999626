<dm-sub-header [icon]="'receipt'" [title]="page.header">
  <button class="warning button large" type="submit" routerLink="/checkout">Return to Licence Agreements</button>
</dm-sub-header>

<div class="institution-name">
  <h4 class="text--bold text--blue">{{ user?.institution?.name }}</h4>
</div>

<!-- Purchaser Details -->

<div class="purchaser-details">
  <small
    >Please provide the details of the person who should receive day-to-day correspondence about the service. This
    includes things like access details, training and service notifications.</small
  >
  <fieldset>
    <legend>Ordered By</legend>
    <dm-billing-user-form
      [user]="cart.purchaser"
      [userType]="'order'"
      (form)="setPurchaserContactForm($event)"></dm-billing-user-form>
  </fieldset>
</div>

<hr />

<div>
  <div [ngClass]="{ 'invoice-contact-checkbox': isPurchaserShown, hide: !isPurchaserShown }">
    <mat-checkbox
      id="is-purchaser-recipient"
      name="is-purchaser-recipient"
      color="primary"
      #isPurchaserRecipient
      [checked]="cart.isPurchaserRecipient"
      [ngModel]="cart.isPurchaserRecipient"
      (ngModelChange)="updatePurchaserRecipient($event)">
      Ordered by and invoice contact details are the same
    </mat-checkbox>
  </div>

  <div [ngClass]="{ invoice: isPurchaserShown, hide: !isPurchaserShown }">
    <span
      >If possible, please provide a generic email address (e.g. payments@example.com) rather than personal one.
    </span>
    <fieldset>
      <legend>Invoice Contact</legend>
      <dm-billing-user-form
        [user]="cart.invoiceRecipient"
        [userType]="'invoice'"
        [disabled]="cart.isPurchaserRecipient"
        (form)="setInvoiceContactForm($event)"></dm-billing-user-form>
    </fieldset>

    <fieldset>
      <legend>Invoice Delivery Address</legend>
      <form #deliveryForm="ngForm">
        <div class="row columns">
          <label for="address"
            >Address
            <textarea
              type="textarea"
              id="address"
              [disabled]="!isPurchaserShown"
              #invoiceAddress_address
              placeholder="Address"
              rows="2"
              [(ngModel)]="cart.invoiceAddress.address"
              required
              minlength="{{ validations.address.minLength }}"
              maxlength="{{ validations.address.maxLength }}"
              name="invoiceAddress-address"
              (ngModelChange)="saveState()"></textarea>
          </label>
        </div>
        <div class="row columns">
          <label for="postcode"
            >Postcode
            <div *ngIf="cart.invoiceAddress.country === countryCodeGB; else noPatternCheckPostcodeInput">
              <input
                type="text"
                id="postcode"
                [disabled]="!isPurchaserShown"
                #invoiceAddress_postcode
                placeholder="Postcode"
                required
                pattern="{{ validations.postcode.regex }}"
                minlength="{{ validations.postcode.minLength }}"
                maxlength="{{ validations.postcode.maxLength }}"
                [(ngModel)]="cart.invoiceAddress.postcode"
                name="invoiceAddress-postcode"
                (ngModelChange)="saveState()" />
            </div>
            <ng-template #noPatternCheckPostcodeInput>
              <input
                id="postcode"
                type="text"
                [disabled]="!isPurchaserShown"
                #invoiceAddress_postcode
                placeholder="Postcode"
                [(ngModel)]="cart.invoiceAddress.postcode"
                name="invoiceAddress-postcode"
                (ngModelChange)="saveState()" />
            </ng-template>
          </label>
        </div>
      </form>
      <div class="row columns">
        <label
          >Country
          <select class="form-control" [(ngModel)]="cart.invoiceAddress.country" (ngModelChange)="saveState()" required>
            <option *ngFor="let country of countryData" [ngValue]="country.code">
              {{ country.name }}
            </option>
          </select>
        </label>
      </div>
    </fieldset>
  </div>
</div>

<hr />

<form #additionalDetailsForm="ngForm" class="additional-details-form">
  <div [ngClass]="{ 'pon-and-31-july-fields': isPurchaserShown, hide: !isPurchaserShown }">
    <label for="purchase-order-num">Purchase Order Number <i>(optional)</i></label>
    <input
      id="purchase-order-num"
      type="text"
      name="purchaseOrderNum"
      maxlength="60"
      #purchaseOrderNum
      [(ngModel)]="cart.purchaseOrderNum" />
  </div>

  <div [ngClass]="{ 'additional-notes': isPurchaserShown, hide: !isPurchaserShown }">
    <label for="vat-registration-number">VAT Registration Number <i>(optional)</i></label>
    <input
      id="vat-registration-number"
      type="text"
      name="vatRegistrationNumber"
      maxlength="60"
      #vatRegistrationNumber
      [(ngModel)]="cart.vatRegistrationNumber" />

    <label for="company-number">Company Number <i>(optional)</i></label>
    <input
      id="company-number"
      type="text"
      name="companyNumber"
      maxlength="60"
      #vatRegistrationNumber
      [(ngModel)]="cart.companyNumber" />

    <label for="charity-number">Charity Number <i>(optional)</i></label>
    <input
      id="charity-number"
      type="text"
      name="charityNumber"
      maxlength="60"
      #vatRegistrationNumber
      [(ngModel)]="cart.charityNumber" />

    <label for="additional-notes">Additional Notes <i>(optional)</i></label>
    <textarea
      id="additional-notes"
      placeholder="Additional Notes"
      rows="5"
      name="invoiceAddress-address"
      #additionalNotes
      maxlength="200"
      [(ngModel)]="cart.additionalNotes"
      (ngModelChange)="saveState()"></textarea>
  </div>

  <div class="purchaser-details" *ngIf="currentShopIsSchool">
    <span>
      We would like to ensure that the day-to-day users of the service at your school are kept informed of our free
      training and teaching resources. Please supply details of the end users in your school who you think might benefit
      from hearing about service updates, new resources and the training opportunities that we offer.
      <br />
      These fields are optional.
      <br />
    </span>
    <fieldset>
      <legend>Add contact details <i>(optional)</i></legend>
      <div class="row columns" *ngFor="let contact of cart.additionalContacts">
        <dm-contact-user-form [user]="contact" (form)="setContactUserForm($event)"></dm-contact-user-form>
      </div>
      <button
        [disabled]="!cart.checkLastContactValid()"
        (click)="cart.addAdditionalContacts()"
        class="secondary-button button large add-information"
        type="button">
        Add another...
      </button>
      <button
        [disabled]="cart.additionalContacts.length <= 1"
        (click)="cart.removeLastContact()"
        class="alert button large add-information"
        type="button">
        Remove last
      </button>
    </fieldset>
  </div>

  <div>
    <p>
      <small class="warning"
        >Please note that we will retain this information for <span class="amount-text">2</span> years, and may use it
        to contact you, in accordance with the
        <a href="https://digimap.edina.ac.uk/common/tos/terms_of_service_digimap.pdf">Digimap Terms of Service.</a>
      </small>
    </p>
  </div>

  <div *ngIf="showInvoiceAfter()" class="after-31-july-checkbox">
    <mat-radio-group
      [labelPosition]="'before'"
      name="invoiceDate"
      [(ngModel)]="cart.invoiceDate"
      (ngModelChange)="updateProductInvoiceDates($event)"
      value="">
      <mat-radio-button value="now" color="primary" data-test="Dispatch Now"
        >Please dispatch invoice now for all products</mat-radio-button
      >

      <mat-radio-button value="after" color="primary">
        <ng-container *ngIf="cart.allProductsInFuture(now); else someProductsInThePast">
          Please dispatch invoice after {{ cart.findEarliestFutureProductDate(now) | date: 'longDate' }} for all
          products
        </ng-container>
        <ng-template #someProductsInThePast>
          Please dispatch invoice after {{ cart.findEarliestFutureProductDate(now) | date: 'longDate' }} for future
          products only (products for this year will be invoiced now)
        </ng-template>
      </mat-radio-button>
    </mat-radio-group>
  </div>
  <small *ngIf="showInvoiceAfter() && !cart.invoiceDate" class="warning">
    Please select a <span class="amount-text">dispatch</span> time for your order.
  </small>

  <div class="next-button">
    <button
      [disabled]="!nextEnabled(purchaserContactForm, invoiceContactForm, deliveryForm, additionalDetailsForm)"
      class="success button large"
      type="button"
      data-test="Next to Confirm"
      [routerLink]="'/' + nextPage.navPath">
      Next
    </button>
  </div>
</form>
