<fieldset class="text-left">
  <legend class="text--bold">{{ title }}</legend>
  <div class="row">
    <div class="medium-2 columns text--bold">Address:</div>
    <div class="medium-8 columns address breakable-data">{{ address.address }}</div>
  </div>
  <div class="row">
    <div class="medium-2 columns text--bold">Postcode:</div>
    <div class="medium-8 columns breakable-data">
      {{ address.postcode }}
    </div>
  </div>
  <div class="row">
    <div class="medium-2 columns text--bold">Country:</div>
    <div class="medium-8 columns breakable-data">
      {{ address.country }}
    </div>
  </div>
</fieldset>
