import * as _ from 'lodash';

import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Product, ProductSubscriptionState } from 'app/models/product.model';

import { Injectable } from '@angular/core';
import { Observable, ReplaySubject, Subject } from 'rxjs';
import { ServiceError } from 'app/services/service-error';
import { map } from 'rxjs/operators';
import { ProductUtil } from '../../helpers/product-util';

@Injectable()
export class ProductService {
  public readonly products$: Observable<Product[]>;
  public readonly error$: Observable<ServiceError>;

  public emptyProducts: boolean = false;
  public resultsCached: boolean = false;

  protected productsSubject: Subject<Product[]> = new ReplaySubject<Product[]>(1);
  protected errorSubject: Subject<ServiceError> = new Subject<ServiceError>();

  constructor(private http: HttpClient) {
    this.productsSubject.next([]);
    this.products$ = this.productsSubject.asObservable();
    this.error$ = this.errorSubject.asObservable();
  }

  get availableProducts$(): Observable<Product[]> {
    // a tad paradoxical to add the unavailable products here
    return this.products$.pipe(
      map((all: Product[]) =>
        all.filter(
          p =>
            p.subscriptionState === ProductSubscriptionState.Renewable ||
            p.subscriptionState === ProductSubscriptionState.New ||
            p.subscriptionState === ProductSubscriptionState.Unavailable
        )
      )
    );
  }

  get ownedProducts$(): Observable<Product[]> {
    return this.products$.pipe(
      map((all: Product[]) =>
        all.filter(
          p =>
            p.subscriptionState === ProductSubscriptionState.Invoiced ||
            p.subscriptionState === ProductSubscriptionState.Subscribed
        )
      )
    );
  }

  get orderable$(): Observable<Product[]> {
    return this.products$.pipe(
      map((all: Product[]) => {
        const invoicedProducts = all.filter(p => p.subscriptionState === ProductSubscriptionState.Invoiced);
        const invoicedBundles = all.filter(p => p.isBundle() && !this.bundleIsOrderable(p, invoicedProducts));
        const allInvoiced = invoicedProducts.concat(invoicedBundles);
        return all.filter(p => !allInvoiced.includes(p));
      })
    );
  }

  load(force: boolean = false) {
    if (force || !this.resultsCached) {
      this.resultsCached = true;
      this.requestProducts();
    }
  }

  /**
   * A bundle can be ordered only if none of it's constituent licences has already been invoiced.
   */
  private bundleIsOrderable(bundle: Product, invoicedProducts: Product[]): boolean {
    const invoicedLicences = ProductUtil.allLicences(invoicedProducts);
    return _.difference(bundle.licences, invoicedLicences).length === bundle.licences.length;
  }

  private requestProducts(): void {
    this.http
      .get<Product[]>(`/api/products`)
      // This mapping makes sure real class instances are used
      .pipe(map(products => products.map(p => new Product(p))))
      .subscribe(
        products => {
          if (products.length === 0) {
            this.emptyProducts = true;
            this.errorSubject.next({ message: 'Backend returned no products for this organisation.' });
            return;
          }
          this.emptyProducts = false;

          // Sort products to match the order ID from Salesforce
          products.sort(this.bySortOrderAndStartDate);

          // Sort the product discounts by sort order, based on the first product in the discount rule
          // As a side effect, this will also set the discount's "display name"
          const codeToName = Object.fromEntries(products.map(p => [p.productCode, p.name]));
          const codeToSortOrder = Object.fromEntries(products.map(p => [p.productCode, p.sortOrder]));
          products.forEach(product => {
            if (product.discounts && product.discounts.length > 0) {
              // Set the display name of each discount to be the concatenation of all discount rule names
              product.discounts.forEach(d => {
                d.displayName = d.discountRule.map(code => codeToName[code]).join(', ');
              });
              // Sort the discounts using the sort order of the first product in the discount rule
              product.discounts.sort((a, b) => {
                const sortOrderA = codeToSortOrder[a.discountRule[0]];
                const sortOrderB = codeToSortOrder[b.discountRule[0]];
                return sortOrderA - sortOrderB;
              });
            }
          });

          // Sort licences alphabetically
          products.forEach(product => {
            product.licences.sort((a, b) => a.displayName.localeCompare(b.displayName));
          });

          this.productsSubject.next(products);
        },
        (error: HttpErrorResponse) => this.errorSubject.next({ message: error.statusText })
      );
  }

  /**
   * Sort products first by time (newer products first) and then by the sort order specified in Salesforce, as
   * part of the product object.
   */
  private bySortOrderAndStartDate(a: Product, b: Product): number {
    const aTime = a.startDate.getTime();
    const bTime = b.startDate.getTime();
    return aTime === bTime
      ? a.sortOrder - b.sortOrder // Sort order is 1, 2, 3 etc as expected
      : bTime - aTime; // Put newer products first, products which start sooner are pushed lower in the list
  }
}
