<dm-sub-header [icon]="'gavel'" [title]="page.header">
  <button class="warning button large" type="submit" routerLink="/billing" [disabled]="cart.orderCompleted === true">
    Return to Billing Details
  </button>
</dm-sub-header>

<dm-order-total-with-vat [cart]="cart"></dm-order-total-with-vat>

<div class="row">
  <div class="small-12 columns small-centered text-center">
    <div class="row">
      <dm-ordered-by
        class="medium-6 columns"
        [user]="cart.purchaser"
        [institution]="user.institution"
        [title]="'Ordered By'"></dm-ordered-by>
      <dm-ordered-by
        [ngClass]="{ 'medium-6 columns': isPurchaserShown, hide: !isPurchaserShown }"
        [user]="cart.invoiceRecipient"
        [institution]="user.institution"
        [title]="'Invoice Contact'"></dm-ordered-by>
    </div>

    <br />

    <div class="row">
      <div [ngClass]="{ 'medium-6 columns medium-centered': isPurchaserShown, hide: !isPurchaserShown }">
        <dm-address [address]="cart.invoiceAddress" [title]="'Purchaser Address'"></dm-address>
      </div>
    </div>

    <br />

    <div [ngClass]="{ 'row additional-information': isPurchaserShown, hide: !isPurchaserShown }">
      <fieldset class="medium-11 columns medium-centered text-left">
        <legend class="text--bold">Additional Information</legend>
        <div class="row">
          <div class="medium-6 columns">
            <div class="container">
              <div class="text--bold">PO Number:</div>
              <div>{{ cart.purchaseOrderNum }}</div>
            </div>
            <div class="container">
              <div class="text--bold">VAT Registration Number:</div>
              <div>{{ cart.vatRegistrationNumber }}</div>
            </div>
            <div class="container">
              <div class="text--bold">Company Number:</div>
              <div>{{ cart.companyNumber }}</div>
            </div>
            <div class="container">
              <div class="text--bold">Charity Number:</div>
              <div>{{ cart.charityNumber }}</div>
            </div>
            <div class="container">
              <div class="text--bold">Dispatch Invoice:</div>
              <div>
                <span *ngFor="let date of cart.getInvoiceDates(); let isLast = last">
                  {{ date | date: 'longDate' }}{{ isLast ? '' : ', ' }}
                </span>
              </div>
            </div>
          </div>
          <div class="medium-6 columns">
            <div class="container">
              <div class="text--bold additional-notes-label">Additional Notes:</div>
              <div>{{ cart.additionalNotes }}</div>
            </div>
            <div *ngFor="let information of cart.additionalContacts; let i = index">
              <div class="container" *ngIf="information.lastName">
                <div class="text--bold">Contact Name #{{ i + 1 }}:</div>
                <div>{{ information.title }} {{ information.firstName }} {{ information.lastName }}</div>
              </div>
              <div class="container" *ngIf="information.emailAddress">
                <div class="text--bold">Contact Email Address #{{ i + 1 }}:</div>
                <div>{{ information.emailAddress }}</div>
              </div>
            </div>
          </div>
        </div>
      </fieldset>
    </div>
  </div>

  <div class="ordered-products">
    <h4>Ordered Products</h4>
    <fieldset class="medium-11 columns medium-centered" *ngFor="let item of cart.items">
      <legend class="text--bold js-product-name">{{ item.product.name }}</legend>
      <div *ngIf="item.product.icon" class="medium-2 columns text-center product-image">
        <img src="./assets/{{ item.product.icon }}" class="product-image" />
      </div>
      <div class="medium-5 columns">
        <p class="product_date">
          Start Date: <span class="text--bold">{{ item.product.startDate | date: 'longDate' }}</span>
          <br />
          End Date: <span class="text--bold">{{ item.product.endDate | date: 'longDate' }}</span>
          <br />
          Invoice Date: <span class="text--bold">{{ item.invoiceDate | date: 'longDate' }}</span>
        </p>
      </div>
      <div class="medium-5 columns text-right">
        <dm-price-with-vat
          [product]="item.product"
          [showDiscount]="cart.getDiscountApplied(item.product.discounts)"></dm-price-with-vat>
      </div>
    </fieldset>
  </div>
</div>

<div class="confirm-order">
  <dm-order-total-with-vat [cart]="cart"></dm-order-total-with-vat>
  <div class="dm-bundle-price-notice" *ngIf="cheaperBundles.length">
    <div>
      You could save <span class="dm-bundle-savings">£{{ cheaperBundles[0].bundleSavings().format() }}</span> if you
      purchase the <span class="dm-bundle-name">{{ cheaperBundles[0].bundle.name }}</span> bundle. If you would like to
      purchase the cheaper bundle instead of your current selection, please return to the
      <a [routerLink]="['/' + productsPage.navPath]">Products page</a>, clear your current order and add the bundle.
    </div>
  </div>
  <div class="order-button-container">
    <button
      [disabled]="orderInProgress"
      class="success button large"
      type="submit"
      data-test="Confirm and Place Order"
      (click)="onConfirmOrderClicked()">
      <span>Confirm and Place Order</span>

      <mat-spinner *ngIf="orderInProgress" diameter="25"></mat-spinner>
    </button>
  </div>
</div>
