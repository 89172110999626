<h1 mat-dialog-title>{{ title }}</h1>

<mat-dialog-content>
  <div class="dm-error-message-container">
    <div class="dm-error-message">
      <div class="dm-text-block">
        One or more products in your cart has already been ordered, possibly by someone else at your institution.
      </div>
      <ng-container *ngIf="products.length === 1; else elseListProducts">
        <div class="dm-text-block">
          Please <a (click)="onGoToProductsPage()">return to the products page</a> and remove
          <span class="dm-already-ordered-product">{{ products[0].name }} </span> from your cart.
        </div>
      </ng-container>
      <ng-template #elseListProducts>
        <div class="dm-text-block">
          Please
          <a [routerLink]="['/' + productsPage.navPath]" (click)="onGoToProductsPage()">return to the products page</a>
          and remove the following products from your cart:
        </div>
        <ul>
          <li class="dm-already-ordered-product" *ngFor="let product of products">{{ product.name }}</li>
        </ul>
      </ng-template>
    </div>
  </div>
</mat-dialog-content>

<mat-dialog-actions>
  <button mat-raised-button color="primary" [mat-dialog-close]="true">Close</button>
</mat-dialog-actions>
