import { Address } from './address.model';

export enum InstitutionType {
  HE = 'HE',
  COLLEGE = 'COLLEGE',
  PRIMARY_SCHOOL = 'PRIMARY_SCHOOL',
  SECONDARY_SCHOOL = 'SECONDARY_SCHOOL',
}

export const INSTITUTION_DISPLAY_NAME = {
  HE: 'Higher Education',
  COLLEGE: 'Further Education',
  PRIMARY_SCHOOL: 'Primary School',
  SECONDARY_SCHOOL: 'Secondary School',
};

export class Institution {
  readonly edinaOrgId: string;
  readonly name: string;
  readonly address: Address;
  readonly phone: string;
  readonly institutionType: InstitutionType;
  readonly isOfsted: boolean;

  constructor(cfg: Partial<Institution> = {}) {
    this.edinaOrgId = cfg.edinaOrgId ? cfg.edinaOrgId : '';
    this.name = cfg.name ? cfg.name : '';
    this.address = cfg.address ? new Address(cfg.address) : new Address();
    this.institutionType = cfg.institutionType ? cfg.institutionType : InstitutionType.HE;
    this.phone = cfg.phone ? cfg.phone : '';
    this.isOfsted = cfg.isOfsted ? cfg.isOfsted : false;
  }
}
