import { AppEnvironment, AppVersionInfo } from 'app/helpers/app-environment';
import { BrowserModule, Meta } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ErrorHandler, LOCALE_ID, NgModule } from '@angular/core';
import { StorageService } from 'app/services/storage.service';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatBadgeModule } from '@angular/material/badge';
import { MatTabsModule } from '@angular/material/tabs';

import { AddressComponent } from 'app/components/shared/address.component';
import { AlertComponent } from 'app/components/error/alert/alert.component';
import { AlreadyInvoicedErrorComponent } from 'app/components/error/already-invoiced/already-invoiced-error.component';
import { AppComponent } from 'app/app.component';
import { AppRoutingModule } from 'app/app.routing';
import { AppStateService } from 'app/state/app-state.service';
import { AuthenticationGuard } from 'app/route-guards/authentication.guard';
import { BillingDetailsPageComponent } from 'app/components/page/billing/billing-details-page.component';
import { BillingUserFormComponent } from 'app/components/page/billing/billing-user-form/billing-user-form.component';
import { BreadcrumbComponent } from 'app/components/breadcrumb/breadcrumb.component';
import { BreadcrumbService } from 'app/services/breadcrumb.service';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BundleAlertDialogComponent } from 'app/components/shopping-cart/bundle-alert-dialog/bundle-alert-dialog.component';
import { BundleService } from 'app/services/product/bundling/bundle.service';
import { CheckoutItemComponent } from 'app/components/page/checkout/checkout-product-item/checkout-product-item.component';
import { CheckoutPageComponent } from 'app/components/page/checkout/checkout-page.component';
import { ErrorDialogService } from 'app/components/error/error-dialog.service';
import { HeaderComponent } from 'app/components/header/header.component';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { InstitutionPageComponent } from 'app/components/page/institution-page/institution-page.component';
import { KeysPipe } from 'app/components/util/keys.pipe';
import { LoginComponent } from 'app/components/page/login/login.component';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { NavBillingGuard } from 'app/route-guards/nav-billing.guard';
import { NavCheckoutGuard } from 'app/route-guards/nav-checkout.guard';
import { NavConfirmGuard } from 'app/route-guards/nav-confirm.guard';
import { NavProductsGuard } from 'app/route-guards/nav-products.guard';
import { NavSummaryGuard } from 'app/route-guards/nav-summary.guard';
import { OrderConfirmationComponent } from 'app/components/page/order-confirmation/order-confirmation.component';
import { OrderService } from 'app/services/order/order.service';
import { OrderSummaryComponent } from 'app/components/page/order-summary/order-summary.component';
import { OrderTotalWithVatComponent } from './components/order-total-with-vat/order-total-with-vat.component';
import { OrderedByComponent } from 'app/components/shared/ordered-by.component';
import { OrderedBySummaryComponent } from 'app/components/shared/ordered-by-summary.component';
import { PinGuard } from './route-guards/pin.guard';
import { PriceWithVatComponent } from 'app/components/price-with-vat/price-with-vat.component';
import { ProductListItemComponent } from 'app/components/page/products/product-list-item/product-list-item.component';
import { ProductResolver } from 'app/services/product/product.resolver';
import { ProductService } from 'app/services/product/products.service';
import { ProductsPageComponent } from 'app/components/page/products/products-page.component';
import { ShoppingCartComponent } from 'app/components/shopping-cart/shopping-cart.component';
import { ShoppingCartService } from 'app/services/shopping-cart.service';
import { StaticErrorPageComponent } from 'app/components/page/error/static-error-page.component';
import { SubHeaderComponent } from 'app/components/sub-header/sub-header.component';
import { UnexpectedErrorComponent } from 'app/components/error/unexpected/unexpected-error.component';
import { HomePageComponent } from 'app/components/page/home/home-page.component';
import { UserResolver } from 'app/services/user/user.resolver';
import { UserService } from 'app/services/user/user.service';
import { environment } from 'environments/environment';
import localeEnGb from '@angular/common/locales/en-GB';
import { registerLocaleData } from '@angular/common';
import * as Sentry from '@sentry/browser';
import { SchoolsPageComponent } from 'app/components/page/schools-page/schools-page.component';
import { SchoolService } from './components/page/schools-page/school.service';
import { SchoolsConfirmComponent } from './components/page/schools-page/schools-confirm/schools-confirm.component';
import { SchoolResolver } from './components/page/schools-page/school-resolver';
import { ShopConfigService } from './components/util/shop-config.service';
import { CookieService } from 'ngx-cookie-service';
import { ExitSurveyComponent } from './components/page/schools-page/exit-survey/exit-survey.component';
import { MatProgressButtonsModule } from 'mat-progress-buttons';
import { UnauthorisedPageComponent } from './components/page/unauthorised/unauthorised-page.component';
import { ContactUserFormComponent } from './components/page/billing/contact-user-form/contact-user-form.component';
import { CookiePolicyComponent } from './components/page/cookie-policy/cookie-policy.component';
import { PrivacyComponent } from './components/page/privacy/privacy.component';
import { SentryErrorHandler } from './helpers/sentry-error-handler';
import { LocalStorageService } from './services/local-storage-service';
import { SpinnerComponent } from './components/spinner/spinner.component';
import { LoadingInterceptor } from './loading.interceptor';
import { UpgradesPageComponent } from './components/page/upgrades/upgrades-page.component';
import { FreeProductAddedDialogComponent } from './components/page/products/product-list-item/free-product-added-dialog/free-product-added-dialog.component';

registerLocaleData(localeEnGb, 'en_GB');

if (environment.production) {
  Sentry.init({
    dsn: 'https://883e7d949fa1424b97b2176d88959178@sentry.edina.ac.uk/5',
    environment: window.location.host,
    release: environment.versionInfo.revision,
  });
}

@NgModule({
  bootstrap: [AppComponent],
  imports: [
    BrowserModule,
    FormsModule,
    HttpClientModule,
    AppRoutingModule,
    MatExpansionModule,
    BrowserAnimationsModule,
    MatCheckboxModule,
    MatDialogModule,
    MatInputModule,
    MatIconModule,
    MatMenuModule,
    MatToolbarModule,
    MatButtonModule,
    MatFormFieldModule,
    ReactiveFormsModule,
    MatAutocompleteModule,
    MatRadioModule,
    MatProgressButtonsModule.forRoot(),
    MatProgressSpinnerModule,
    MatSnackBarModule,
    MatTabsModule,
    MatBadgeModule,
  ],
  declarations: [
    AppComponent,
    InstitutionPageComponent,
    ShoppingCartComponent,
    ProductsPageComponent,
    UpgradesPageComponent,
    CheckoutPageComponent,
    CheckoutItemComponent,
    OrderConfirmationComponent,
    OrderSummaryComponent,
    BillingDetailsPageComponent,
    AlertComponent,
    LoginComponent,
    ProductListItemComponent,
    BreadcrumbComponent,
    KeysPipe,
    HeaderComponent,
    AddressComponent,
    OrderedByComponent,
    OrderedBySummaryComponent,
    PriceWithVatComponent,
    BillingUserFormComponent,
    ContactUserFormComponent,
    SubHeaderComponent,
    AlreadyInvoicedErrorComponent,
    UnexpectedErrorComponent,
    StaticErrorPageComponent,
    HomePageComponent,
    BundleAlertDialogComponent,
    FreeProductAddedDialogComponent,
    OrderTotalWithVatComponent,
    SchoolsPageComponent,
    SchoolsConfirmComponent,
    ExitSurveyComponent,
    UnauthorisedPageComponent,
    CookiePolicyComponent,
    PrivacyComponent,
    SpinnerComponent,
  ],
  providers: [
    AppStateService,
    ProductService,
    ErrorDialogService,
    AuthenticationGuard,
    PinGuard,
    BundleService,
    NavProductsGuard,
    NavCheckoutGuard,
    NavBillingGuard,
    NavConfirmGuard,
    NavSummaryGuard,
    {
      provide: StorageService,
      useClass: LocalStorageService,
    },
    ShoppingCartService,
    UserService,
    BreadcrumbService,
    UserResolver,
    ProductResolver,
    SchoolResolver,
    OrderService,
    SchoolService,
    Meta,
    CookieService,
    ShopConfigService,
    {
      provide: AppEnvironment,
      useValue: environment,
    },
    {
      provide: AppVersionInfo,
      useValue: environment.versionInfo,
    },
    {
      provide: LOCALE_ID,
      useValue: 'en_GB',
    },
    {
      provide: ErrorHandler,
      useClass: SentryErrorHandler,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: LoadingInterceptor,
      multi: true,
    },
  ],
})
export class AppModule {
  constructor(env: AppEnvironment, meta: Meta) {
    if (env && env.versionInfo) {
      meta.addTag({
        name: 'version',
        content: env.versionInfo.version,
      });
      meta.addTag({
        name: 'revision',
        content: env.versionInfo.revision,
      });
      meta.addTag({
        name: 'build-date',
        content: '' + env.versionInfo.dateTime,
      });
    }
  }
}
