import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Product } from '../../../../../models/product.model';

@Component({
  selector: 'dm-dialog',
  templateUrl: './free-product-added-dialog.component.html',
  styleUrls: ['./free-product-added-dialog.component.scss'],
})
export class FreeProductAddedDialogComponent {
  constructor(@Inject(MAT_DIALOG_DATA) public data: { freeProducts: Product[] }) {}
}
