import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { Address } from '../../models/address.model';

@Component({
  selector: 'dm-address',
  templateUrl: './address.component.html',
  styleUrls: ['./address.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AddressComponent {
  @Input() address: Address;
  @Input() title: string;

  constructor() {}
}
