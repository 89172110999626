import { Component, Inject, Optional } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UnexpectedErrorComponentData } from '../unexpected/unexpected-error.component';

@Component({
  templateUrl: 'alert.component.html',
  styleUrls: ['./alert.component.scss'],
})
export class AlertComponent {
  title?: string;
  message: string;

  constructor(@Optional() @Inject(MAT_DIALOG_DATA) public data: UnexpectedErrorComponentData) {
    if (this.data) {
      this.title = this.data.title;
      this.message = this.data.message;
    }
  }
}
