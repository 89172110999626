<div *ngIf="!inCart" class="dm-product-container">
  <div class="dm-list-item-container">
    <div class="dm-item-header">
      <div class="dm-product-name">{{ product.name }}</div>
      <ng-container *ngIf="isAvailable(product.subscriptionState)">
        <div class="dm-product-date">
          ({{ product.startDate | date: 'dd MMM yy' }} - {{ product.endDate | date: 'dd MMM yy' }})
        </div>
        <div *ngIf="showPrice" class="dm-price">
          <dm-price-with-vat
            [product]="product"
            [showVat]="false"
            [showDiscount]="getDiscountApplied()"></dm-price-with-vat>
        </div>
      </ng-container>
    </div>

    <div class="dm-item-body">
      <div class="dm-description">
        <div *ngIf="product.icon" class="dm-image-container show-for-large">
          <img src="./assets/{{ product.icon }}" class="product-image dm-list-item-image" aria-hidden="true" />
        </div>
        <span *ngIf="isRenewable(product.subscriptionState)" class="badge show-for-large">Previously subscribed</span>
        <div class="product-description">
          <span class="hide-for-large dm-small-btn-container">
            <ng-container class="hide-for-large" *ngTemplateOutlet="order_btn"></ng-container>
          </span>
          <div *ngIf="!isAvailable(product.subscriptionState); else productDescription" class="block-product">
            <!-- TODO: hardcodes the ofsted code, unfortunately. This should be generalised -->
            <ng-container *ngIf="product.productCode === 'dfs2_ofsted_scheme3'; else freeTrialMessage">
              You are unable to order this product online because your school has had a subscription under the Ofsted
              scheme within the past 12 months. If you believe your school is still eligible, please contact us on
              <a href="mailto:digimap.schools@ed.ac.uk?subject=Digimap for Schools Ofsted eligibility query">
                digimap.schools@ed.ac.uk </a
              >.
            </ng-container>
            <ng-template #freeTrialMessage>
              This product isn't available because your school has either previously used the free trial option or has
              recently had a paid subscription. Please email
              <a href="mailto:digimap.schools@ed.ac.uk?subject=Digimap for Schools free trial eligibility query">
                digimap.schools@ed.ac.uk
              </a>
              for further information.
            </ng-template>
            <br />
          </div>
          <ng-template #productDescription>
            <span>{{ product.description }}</span>
            <span *ngIf="product.moreInfoUrl.length > 0">
              <br /><a href="{{ product.moreInfoUrl }}">More Info…</a>
            </span>
          </ng-template>

          <ng-container *ngIf="product.isSimple()">
            <span class="dependency-text" *ngIf="!dependencyFulfilled && getNameForLicence(product.dependency)">
              <br />This product is only available to subscribers of '{{ getNameForLicence(product.dependency) }}'.
            </span>
          </ng-container>

          <ng-container *ngIf="product.isBundle() && !isOrdered(product.subscriptionState)">
            <span *ngIf="!invoiced && cheaperBundle">
              <br />Save up to {{ cheaperBundle.bundleSavings().format() }} with this option!
            </span>
            <span *ngIf="invoiced"> <br />Unavailable - one or more collections already ordered </span>
          </ng-container>

          <div *ngIf="discountsList().length > 0" class="discount-list">
            <ul>
              <li *ngFor="let discount of discountsList()">
                <span
                  >Buy NOW with {{ discount.displayName }} and <b>SAVE {{ discount.discount.format() }}</b></span
                >
              </li>
            </ul>
          </div>
        </div>
      </div>

      <div class="dm-button-container show-for-large">
        <ng-container *ngTemplateOutlet="order_btn"></ng-container>
      </div>
    </div>
  </div>

  <ng-template #order_btn>
    <button
      *ngIf="!isOrdered(product.subscriptionState) && isAvailable(product.subscriptionState)"
      class="add-to-order-btn"
      mat-raised-button
      [matBadge]="isRenewable(product.subscriptionState) ? '↺' : ''"
      matBadgeColor="accent"
      matBadgeSize="large"
      color="primary"
      [attr.title]="
        dependencyFulfilled === false ? 'The product has dependencies that need to be ordered first.' : null
      "
      [disabled]="inCart || invoiced || !dependencyFulfilled"
      [attr.data-test]="'Add ' + product.productCode + ' to order'"
      (click)="addProductToCart(product)">
      Add to Order
    </button>
  </ng-template>
</div>
