import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Institution, INSTITUTION_DISPLAY_NAME, InstitutionType } from '../../../../models/institution.model';
import { SchoolService, SchoolType } from '../school.service';
import { AppPages } from '../../../../state/app-page';
import { mergeMap } from 'rxjs/operators';
import { ShopConfigService } from '../../../util/shop-config.service';
import { School } from '../school';
import { MatProgressButtonOptions } from 'mat-progress-buttons';

@Component({
  templateUrl: './schools-confirm.component.html',
  styleUrls: ['./schools-confirm.component.scss'],
})
export class SchoolsConfirmComponent implements OnInit {
  school: Institution | null;
  schoolId: string;
  isOfsted = false;
  ofstedActive = false;
  confirmationSubmitted = false;
  resendEmail: string;
  ofstedEmailDomain = '';

  btnOpts: MatProgressButtonOptions = {
    active: false,
    text: 'Next',
    spinnerSize: 30,
    raised: true,
    stroked: false,
    buttonColor: 'accent',
    spinnerColor: 'primary',
    fullWidth: true,
    disabled: false,
    mode: 'indeterminate',
  };

  postcodeNavPath: string[];
  schoolType: SchoolType;

  constructor(
    private readonly route: ActivatedRoute,
    private readonly router: Router,
    private readonly schoolService: SchoolService,
    private readonly shopConfig: ShopConfigService
  ) {}

  login() {
    this.btnOpts.active = true;
    this.confirmationSubmitted = true;
    this.schoolService.login(this.schoolId).subscribe({
      next: valid => {
        if (!valid) {
          return this.router.navigate(['/' + AppPages.error.navPath]);
        }
        this.shopConfig.updateShopType();
        return this.router.navigate(['/' + AppPages.products.navPath]);
      },
      error: () => {
        this.btnOpts.active = false;
        this.confirmationSubmitted = false;
      },
    });
  }

  formatAddress(address: string | null): string {
    if (!address) {
      return '';
    }

    return address.replace(/\n/g, ',');
  }

  phaseOfEducation(institutionType: InstitutionType) {
    return INSTITUTION_DISPLAY_NAME[institutionType];
  }

  ngOnInit() {
    // Assume a school, unless the schoolType has been explicitly set in the route data.
    this.route.data.subscribe(data => {
      this.schoolType = data.schoolType ? data.schoolType : 'school';
      this.postcodeNavPath = [
        '/' + (this.schoolType === 'school' ? AppPages.schools.navPath : AppPages.colleges.navPath),
      ];
    });

    // The getOfstedActive endpoint should only be called if the school
    // is part of the ofsted scheme.
    this.route.params
      .pipe(
        mergeMap(params => {
          this.schoolId = params['id'];
          return this.route.data;
        })
      )
      .subscribe(data => {
        const school = data['school'] as School;
        this.school = school.institution;
        this.isOfsted = school.ofsted;
      });
  }
}
