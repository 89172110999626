import { Directive } from '@angular/core';

@Directive()
export class PageComponent {
  constructor() {
    this.scrollToTheTop();
  }

  private scrollToTheTop() {
    window.scrollTo(0, 0);
  }
}
