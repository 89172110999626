<div class="row small-12 columns">
  <section class="boxed small-6 columns">
    <h2>Renew your subscriptions</h2>
    <p>
      If you are an existing Digimap subscriber please select your institution and login using your usual institutional
      credentials. You will then be asked to enter a PIN code which has been sent to you as a person authorised to subscribe
      to resources. If you do not have this code please <a href="mailto:digimap@ed.ac.uk">contact us</a>.
    </p>

    <p>
      <a mat-raised-button color="primary" href="{{ baseLoginUrl }}" tabindex="0">Login</a>
    </p>

    <p>Having trouble logging in? Please call us: 0131 650 3302</p>
  </section>

  <section class="boxed small-5 columns">
    <h2>New subscribers - ask for PIN code</h2>
    <p>You need a PIN code to access our subscription portal. If you have never had any subscriptions to Digimap,
      please <a href="mailto:digimap@ed.ac.uk">contact us</a> for your PIN code.</p>
  </section>
</div>
