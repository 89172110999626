import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, Router, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { SchoolService } from './school.service';
import { catchError } from 'rxjs/operators';
import { AppPages } from '../../../state/app-page';
import { School } from './school';

@Injectable()
export class SchoolResolver implements Resolve<School | null> {
  constructor(private schoolService: SchoolService, private router: Router) {}

  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<School | null> | Promise<School | null> | School | null {
    return this.schoolService.getSchoolById(route.params['id']).pipe(
      catchError(() => {
        this.router.navigate(['/' + AppPages.error.navPath]);
        return [];
      })
    );
  }
}
