<h2>What is a cookie?</h2>

<p>
  A cookie is a small file downloaded on to your phone, tablet or computer when you visit a website. Cookies allow
  websites to recognise your device when you make a return visit.
</p>

<p>
  We use several types of cookies on the EDINA website. Some are essential, like cookies that make sure our website
  works properly with your browser. They always need to be on.
</p>

<p>
  Some cookies you can opt out of or block completely. Opting out or blocking these cookies might affect how the website
  looks or works for you.
</p>

<p>
  When you leave our website or click on links that take you to an external websites, we can’t control what cookies they
  set. They will have their own cookie policy and you will need to set your cookie preferences for those websites too.
</p>

<h2>Why and how we use cookies</h2>

<p>There are four reasons why a cookie might be stored on your device when visiting www.subscriptions.edina.ac.uk:</p>

<ul>
  <li>
    Cookies that make the website work properly for you and enable you to make use of the secure online services that we
    provide;
  </li>
  <li>
    Cookies that collect data about your use of the website which is then anonymised and used to help us improve our
    online services
  </li>
  <li>Cookies that remember your preferences and make the site easier for you to use</li>
  <li>
    Cookies that are placed by third party services we make use of to enhance the information we present online. We have
    no control over these third party cookies.
  </li>
</ul>

<p>
  These cookies are placed on your device either by EDINA or by the third parties whose services we use as part of the
  website.
</p>

<p>
  Some cookies are retained in your browser for only as long as you visit our website, while others persist for a longer
  specified or unspecified period.
</p>

<h2>Strictly necessary cookies</h2>

<p>
  Some cookies we place on your browser ensure that EDINA services and sites delivers you information and services
  securely and optimally. You must accept these cookies to be able to make use of our online systems.
</p>

<h2>Functionality cookies</h2>

<p>
  Some cookies enable websites to remember choices their users make, for example, user name, language or text size.
  These cookies are known as “functionality cookies” and help to improve a user’s experience of a website by providing a
  more personalised service.
</p>

<h2>Performance cookies</h2>

<p>
  We use a number of tools that monitor visitor behaviour on our website to help us improve our information and
  services.
</p>

<p>
  The tool we use most consistently is Google Analytics. Anonymous data about your visit is collected and amalgamated
  with that of other visitors so we can better understand how people use our website.
</p>

<p>
  You can opt out of providing us with this information if you wish, with no impact on your experience of our website.
</p>

<h2>Third party cookies</h2>

<p>
  Some EDINA services and sites use third party services or software, such as maps, online videos or social networking
  features. Many of these services may set cookies on your device.
</p>

<p>
  However, you can block or remove cookies yourself by altering the settings of your browser. Blocking these cookies is
  unlikely to impact on your experience of our website.
</p>

<h2>How to block cookies</h2>

<p>
  Most browsers allow some control of cookies through browser settings. To find out more about cookies, including how to
  see what cookies have been set and how to manage and delete them, visit www.aboutcookies.org or
  www.allaboutcookies.org.
</p>

<p>
  To opt out of being tracked by Google Analytics across all websites, visit http://tools.google.com/dlpage/gaoptout.
</p>

<h2>Cookies set across subscriptions.edina.ac.uk</h2>

<h3>Strictly necessary cookies</h3>

<p>
  Some cookies we place on your browser ensure that EDINA Services and Sites deliver you information and services
  securely and optimally. You must accept these cookies to be able to make use of our online systems.
</p>

<table class="compact bordered">
  <thead>
    <tr>
      <th>Cookie</th>
      <th>Cookie name(s)</th>
      <th>Data stored</th>
      <th>When does it expire?</th>
      <th>Description</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td>Cookie Preferences</td>
      <td>enable_analytics</td>
      <td>True or false cookie retention preferences plus time stamp</td>
      <td>The cookie is not deleted when you close your web browser.</td>
      <td>
        The cookie preferences are stored to retain visitor choices in the main cookie banner. This means that the main
        cookie banner can be minimised on future visits, and so that analytics and advertising cookies are not set for
        visitors who opt out of this tracking.<br /><br />
        <small>
          This cookie does not identify you personally and is not linked to any other information we store about
          you.</small
        >
      </td>
    </tr>

    <tr>
      <td>Session ID</td>
      <td>subscriptions</td>
      <td>A JSON Web Token (JWT). This functions in a similar way to a standard session cookie.</td>
      <td></td>
      <td>
        This cookie is required to follow your progress through the Services and Sites. It is essential to ensure that
        any information you enter or routes you take are remembered by the Services and Sites. Without this cookie,
        every page you visited would treat you as a completely new visitor. <br /><br />
        <small
          >This cookie does not identify you personally and is not linked to any other information we store about
          you.</small
        >
        <br /><br />
        <a href="http://en.wikipedia.org/wiki/Session_ID">http://en.wikipedia.org/wiki/Session_ID</a>
      </td>
    </tr>

    <tr>
      <td>Load balancer</td>
      <td>RCKBMHKB</td>
      <td>A random unique number or string of letters and numbers.</td>
      <td>The cookie is deleted when you close your web browser.</td>
      <td>
        EDINA uses more than one server to provide web pages. When you visit the Services and Sites, you are assigned to
        one of several servers. This cookie is required to track which server you are communicating with in order to
        present a consistent user experience and remember information about the data you have entered. <br /><br />
        <small
          >This cookie does not identify you personally and is not linked to any other information we store about
          you.</small
        >
      </td>
    </tr>
    <tr>
      <td>Application State</td>
      <td>AppState</td>
      <td>A random unique number or string of letters and numbers.</td>
      <td>The cookie is not deleted when you close your web browser.</td>
      <td>
        This stores information about your activity and progress through the subscription portal. It allows you to
        return to the page to complete your transaction. <br /><br />
        <small
          >This does not identify you personally and is not linked to any other information we store about you.</small
        >
      </td>
    </tr>

    <tr>
      <td>Shopping Cart</td>
      <td>cart</td>
      <td>This records the products you have added to your subscription order.</td>
      <td>The cookie is not deleted when you close your web browser.</td>
      <td>
        This stores information about your activity and progress through the subscription portal. It allows you to
        return to the page to complete your transaction. <br /><br />
        <small
          >This does not identify you personally and is not linked to any other information we store about you.</small
        >
      </td>
    </tr>
    <tr>
      <td>Shop Type</td>
      <td>shopType</td>
      <td>DIGIMAP or SCHOOLS</td>
      <td>The cookie is not deleted when you close your web browser.</td>
      <td>
        Records whether you are a schoool or higher education customer.<br /><br />
        <small>
          This cookie does not identify you personally and is not linked to any other information we store about
          you.</small
        >
      </td>
    </tr>
    <tr>
      <td>YouTube</td>
      <td>
        SID, LOGIN_INFO, use_hotbox, PREF, SSID, HSID, watched_video_id_list, __utma, __utmz, demographics,
        VISITOR_INFO1_LIVE
      </td>
      <td>Various unique identifiers, as well as login information that may relate to a Google account.</td>
      <td>
        Most of the cookies expire 10 years after your last visit to a page containing a YouTube video, although some
        expire sooner.
      </td>
      <td>
        Google set a number of cookies on any page that includes a YouTube video. While we have no control over the
        cookies set by Google, they appear to include a mixture of pieces of information to measure the number and
        behaviour of YouTube users, including information that links your visits to our website with your Google account
        if you are signed in to one. <br /><br />
        <small>
          In doing so, information about your use of our website, including your IP address, may be transmitted to
          Google and stored on servers in the United States.
          <br /><br />
          This cookie does not identify you personally unless you are logged into Google, in which case it is linked to
          your Google account.
          <br /><br />
          <ul>
            <li><a href="https://policies.google.com/privacy">Google’s Privacy Policy</a></li>
            <li><a href="https://tools.google.com/dlpage/gaoptout">Opt out of Google Analytics</a></li>
          </ul>
        </small>
      </td>
    </tr>
  </tbody>
</table>

<h3>Performance cookies</h3>

<p>
  We use a number of tools that monitor visitor behaviour on EDINA’s Services and Sites to help us improve our
  performance in provision of information and services. These cookies collect anonymous information on the pages
  visited.
</p>

<table class="compact bordered">
  <thead>
    <tr>
      <th>Cookie</th>
      <th>Cookie name(s)</th>
      <th>Data stored</th>
      <th>When does it expire?</th>
      <th>Description</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td>Google Analytics</td>
      <td>_ga, _gid, __opix_uid, _fbp</td>
      <td>A random unique number or string of letters and numbers.</td>
      <td>This cookie expires 2 years after your last visit to the website.</td>
      <td>
        Google Analytics is a web analytics service provided by Google, Inc. (“Google”), to help us see how our website
        is used. In doing so information about your use of our website, including your IP address, may be transmitted to
        Google and stored on servers in the United States <br /><br />
        <ul>
          <li><a href="https://policies.google.com/privacy">Google’s Privacy Policy</a></li>
          <li><a href="https://tools.google.com/dlpage/gaoptout">Opt out of Google Analytics</a></li>
        </ul>
        <small>
          The information stored in this cookie is used to identify how frequently the same people revisit the EDINA
          website, and to detect how long people take between each visit. This information is combined with data from
          thousands of other users to create an overall picture of website use, and is never identified individually.
          <br /><br />
          This cookie does not identify you personally and is not linked to any other information we store about you.
        </small>
      </td>
    </tr>
  </tbody>
</table>

<h3>Third party cookies</h3>

<p>
  Some of EDINA’s Services and Sites use third party services or software, such as maps, online videos or social
  networking features. Many of these services may set cookies in your browser in accordance with their own privacy
  policies, although many can be blocked by you without impact on your experience. EDINA has no control over these
  cookies.
</p>

<table class="compact bordered">
  <thead>
    <tr>
      <th>Cookie</th>
      <th>Cookie name(s)</th>
      <th>Data stored</th>
      <th>When does it expire?</th>
      <th>Description</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td>YouTube</td>
      <td>
        SID, LOGIN_INFO, use_hotbox, PREF, SSID, HSID, watched_video_id_list, __utma, __utmz, demographics,
        VISITOR_INFO1_LIVE
      </td>
      <td>Various unique identifiers, as well as login information that may relate to a Google account.</td>
      <td>
        Most of the cookies expire 10 years after your last visit to a page containing a YouTube video, although some
        expire sooner.
      </td>
      <td>
        Google set a number of cookies on any page that includes a YouTube video. While we have no control over the
        cookies set by Google, they appear to include a mixture of pieces of information to measure the number and
        behaviour of YouTube users, including information that links your visits to our website with your Google account
        if you are signed in to one. <br /><br />
        <small>
          In doing so, information about your use of our website, including your IP address, may be transmitted to
          Google and stored on servers in the United States.
          <br /><br />
          This cookie does not identify you personally unless you are logged into Google, in which case it is linked to
          your Google account.
          <br /><br />
          <ul>
            <li><a href="https://policies.google.com/privacy">Google’s Privacy Policy</a></li>
            <li><a href="https://tools.google.com/dlpage/gaoptout">Opt out of Google Analytics</a></li>
          </ul>
        </small>
      </td>
    </tr>
  </tbody>
</table>
