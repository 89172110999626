import { Type } from '@angular/core';

export abstract class StorageService {
  public abstract getJson(key: string): string | undefined;

  getObject<T>(key: string, type: Type<T>): T | undefined {
    const v: string | undefined = this.getJson(key);
    let result: T | undefined;
    if (v) {
      result = JSON.parse(v) as T;
    }
    return result;
  }

  getWithDefault<T>(key: string, defaultValue: T): T {
    const v: string | undefined = this.getJson(key);
    return v ? (JSON.parse(v) as T) : defaultValue;
  }

  abstract setObject<T>(key: string, entry: T);

  abstract clear(key: string);

  abstract clearAll();
}
