import { AppEnvironment, AppVersionInfo } from './helpers/app-environment';

import { Component } from '@angular/core';
import { ShopConfigService } from './components/util/shop-config.service';

@Component({
  styleUrls: ['./app.component.scss'],
  selector: 'dm-root',
  templateUrl: 'app.component.html',
})
export class AppComponent {
  versionInfo: AppVersionInfo;

  constructor(environment: AppEnvironment, public shopConfig: ShopConfigService) {
    this.versionInfo = environment.versionInfo;
  }
}
