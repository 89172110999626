import { Injectable } from '@angular/core';
import { Postcode } from './postcode';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { retry, timeout } from 'rxjs/operators';
import { Institution } from '../../../models/institution.model';
import { School } from './school';
import { SchoolInfo } from './school-info';
import { SurveyResponse } from './survey-response';

export type SchoolType = 'school' | 'college';

@Injectable()
export class SchoolService {
  constructor(private http: HttpClient) {}

  getPostcode(postcode: string): Observable<Postcode[]> {
    return this.http.get<Postcode[]>(`/api/open/postcode/${this.cleanString(postcode)}`).pipe(timeout(10000), retry(3));
  }

  getSchoolsByPostcode(postcode: string, schoolType: SchoolType): Observable<Institution[]> {
    return this.http
      .get<Institution[]>(`/api/open/${schoolType}/postcode/${this.cleanString(postcode)}`)
      .pipe(timeout(10000), retry(3));
  }

  getSchoolById(id: string): Observable<School | null> {
    return this.http.get<School>(`/api/open/school/${id}`).pipe(timeout(10000), retry(3));
  }

  login(edinaOrgId: string): Observable<boolean> {
    return this.http.post<boolean>('/login/school', { edinaOrgId }).pipe(timeout(10000), retry(3));
  }

  getEmailByEdinaOrgId(edinaOrgId: string): Observable<string> {
    return this.http
      .get(`/api/open/school/${edinaOrgId}/getemail`, { responseType: 'text' })
      .pipe(timeout(10000), retry(3));
  }

  getSurveyInfo(edinaOrgId: string, subscriptionId: string): Observable<SchoolInfo> {
    return this.http
      .get<SchoolInfo>(`/api/open/schools/exit-survey/${edinaOrgId}/${subscriptionId}`)
      .pipe(timeout(10000), retry(3));
  }

  sendSurvey(response: SurveyResponse, edinaOrgId: string, subscriptionId: string) {
    return this.http
      .post<void>(`/api/open/schools/exit-survey/${edinaOrgId}/${subscriptionId}`, response)
      .pipe(timeout(10000), retry(3));
  }

  private cleanString(postcode: string) {
    const specialRegex = /[\\,;/%.]+/g;
    return postcode.replace(specialRegex, '');
  }
}
