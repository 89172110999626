<mat-toolbar>
  <mat-toolbar-row>
    <header class="header">
      <div class="subscription-logo">
        <a [href]="(shopConfig.configuration$ | async)?.headerUrl">
          <img
            *ngIf="(shopConfig.configuration$ | async)?.icon as icon"
            [alt]="icon.alternativeText"
            [src]="icon.iconUrl" />
          <img alt="Digimap subscription" src="assets/digimap-subscription.svg" />
        </a>
      </div>
      <div class="middle-logos">
        <div class="edina-logo">
          <img alt="The University of Edinburgh" src="assets/edina.svg" />
        </div>
        <div>
          <img alt="The University of Edinburgh" src="assets/u-of-e.svg" />
        </div>
      </div>
      <div class="contact">
        <div class="flat-menu">
          <a mat-button [href]="'tel:' + shopConfig.contactConfig.phoneNumber">
            tel: {{ shopConfig.contactConfig.phoneNumber }}
          </a>
          <a mat-button [href]="'mailto:' + (shopConfig.configuration$ | async)?.contactEmail">Contact Us</a>
        </div>

        <button class="menu" mat-button [matMenuTriggerFor]="menu">
          <span>Contact Us</span>
        </button>

        <mat-menu class="menu" #menu="matMenu">
          <a mat-menu-item [href]="'tel:' + shopConfig.contactConfig.phoneNumber">
            <mat-icon>phone</mat-icon>
            <span>tel: {{ shopConfig.contactConfig.phoneNumber }}</span>
          </a>

          <a mat-menu-item [href]="'mailto:' + (shopConfig.configuration$ | async)?.contactEmail">
            <mat-icon>email</mat-icon>
            <span>Contact Us</span>
          </a>
        </mat-menu>
      </div>
    </header>
  </mat-toolbar-row>
</mat-toolbar>

<div class="row content">
  <div class="small-12 columns">
    <dm-header></dm-header>
    <main>
      <router-outlet></router-outlet>
    </main>
  </div>
</div>

<footer>
  <div class="privacy-margin">
    <a class="privacy-policy" [routerLink]="'privacy'">Privacy Statement</a>
    <a class="privacy-policy" [routerLink]="'cookie-policy'">Cookies Policy</a>
  </div>
</footer>

<dm-app-spinner></dm-app-spinner>
