import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot } from '@angular/router';

import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { UserService } from './../services/user/user.service';
import { map } from 'rxjs/operators';

@Injectable()
export class PinGuard implements CanActivate {
  constructor(private userService: UserService) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return this.userService.verifyUser().pipe(map(u => !!u));
  }
}
