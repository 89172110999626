import * as currency from 'currency.js';

import { Address } from './address.model';
import { CartItem } from './cart-item.model';
import { GBP } from './../helpers/currency-defs';
import { Product } from './product.model';
import { UserInfo } from './user-info.model';
import { isBefore, isAfter, subDays, isSameDay, min } from 'date-fns';
import { ContactInfo } from './contact-info.model';
import { Discount } from './discount.model';

export class ShoppingCart {
  static StoreKey: string = 'cart';

  items: CartItem[] = [];

  additionalNotes = '';
  vatRegistrationNumber: string = '';
  companyNumber: string = '';
  charityNumber: string = '';
  isPurchaserRecipient = false;
  isInstInvoiceAddress = false;
  purchaser: UserInfo = new UserInfo();
  invoiceRecipient: UserInfo = new UserInfo();
  additionalContacts: ContactInfo[] = [new ContactInfo()];
  invoiceAddress: Address = new Address();
  purchaseOrderNum: string = '';
  invoiceDate: string = '';
  // Controls state
  tosAgreementSigned = false;
  authorisationConfirmed = false;
  orderCompleted = false;

  /* eslint-disable complexity */
  constructor(cfg: Partial<ShoppingCart> = {}) {
    this.items = cfg.items ? cfg.items.slice() : this.items;
    this.additionalNotes = cfg.additionalNotes ? cfg.additionalNotes : this.additionalNotes;
    this.isPurchaserRecipient = cfg.isPurchaserRecipient ? cfg.isPurchaserRecipient : this.isPurchaserRecipient;
    this.isInstInvoiceAddress = cfg.isInstInvoiceAddress ? cfg.isInstInvoiceAddress : this.isInstInvoiceAddress;
    this.purchaser = cfg.purchaser ? new UserInfo(cfg.purchaser) : this.purchaser;
    this.invoiceRecipient = cfg.invoiceRecipient ? new UserInfo(cfg.invoiceRecipient) : this.invoiceRecipient;
    this.additionalContacts = cfg.additionalContacts ? cfg.additionalContacts.slice() : this.additionalContacts;
    this.invoiceAddress = cfg.invoiceAddress ? new Address(cfg.invoiceAddress) : this.invoiceAddress;
    this.purchaseOrderNum = cfg.purchaseOrderNum ? cfg.purchaseOrderNum : this.purchaseOrderNum;
    this.vatRegistrationNumber = cfg.vatRegistrationNumber ? cfg.vatRegistrationNumber : this.vatRegistrationNumber;
    this.companyNumber = cfg.companyNumber ? cfg.companyNumber : this.companyNumber;
    this.charityNumber = cfg.charityNumber ? cfg.charityNumber : this.charityNumber;
    this.invoiceDate = cfg.invoiceDate ? cfg.invoiceDate : this.invoiceDate;

    this.tosAgreementSigned = cfg.tosAgreementSigned ? cfg.tosAgreementSigned : this.tosAgreementSigned;
    this.authorisationConfirmed = cfg.authorisationConfirmed ? cfg.authorisationConfirmed : this.authorisationConfirmed;
    this.orderCompleted = cfg.orderCompleted ? cfg.orderCompleted : this.orderCompleted;
  }

  /* eslint-enable complexity */

  get grossTotal(): currency {
    return this.items.reduce((prev, item) => {
      if (item.product.discounts.length > 0) {
        const discount = this.getDiscountApplied(item.product.discounts);
        if (discount) {
          return prev.add(discount.totalPrice());
        }
      }
      return prev.add(item.product.totalPrice());
    }, GBP(0));
  }

  get netTotal(): currency {
    return this.items.reduce((prev, item) => {
      if (item.product.discounts.length > 0) {
        const discount = this.getDiscountApplied(item.product.discounts);
        if (discount) {
          return prev.add(discount.proRataPriceWithDiscount);
        }
      }
      return prev.add(item.product.proRataPrice);
    }, GBP(0));
  }

  allAgreementsSigned(): boolean {
    return (
      this.authorisationConfirmed &&
      this.tosAgreementSigned &&
      this.items.every(item =>
        Object.keys(item.licenceAgreementSigned).every(productKey => item.licenceAgreementSigned[productKey])
      )
    );
  }

  addItem(item: CartItem) {
    this.items.push(item);
  }

  addAdditionalContacts(contact?: ContactInfo) {
    this.additionalContacts.push(contact ? contact : new ContactInfo());
  }

  checkLastContactValid(): boolean {
    const lastContact = Object.assign(new ContactInfo(), this.additionalContacts[this.additionalContacts.length - 1]);
    return lastContact.isValid();
  }

  removeLastContact() {
    this.additionalContacts.pop();
  }

  removeProductById(productId: string) {
    this.items = this.items.filter(item => item.product.id !== productId);
  }

  removeSimpleProductByLicenceId(licenceId: string) {
    this.items = this.items.filter(item => !item.product.isSimpleWithLicence(licenceId));
  }

  removeProductItem(itemToRemove: Product) {
    this.items = this.items.filter(item => item.product.id !== itemToRemove.id);
  }

  findMinStartDate(): Date {
    let startDate = this.items[0].product.startDate;
    for (let _i = 1; _i < this.items.length; _i++) {
      if (isBefore(this.items[_i].product.startDate, startDate)) {
        startDate = this.items[_i].product.startDate;
      }
    }
    startDate = subDays(startDate, 1);
    return startDate;
  }

  /** Get all invoice dates for all products in the cart */
  getInvoiceDates(): Date[] {
    const dates: Date[] = [];
    this.items
      .map(item => item.invoiceDate)
      .forEach(date => {
        if (date && dates.indexOf(date) === -1) {
          dates.push(date);
        }
      });
    return dates;
  }

  getItemsForInvoiceDate(invoiceDate: Date): CartItem[] {
    return this.items.filter(item => item.invoiceDate === invoiceDate);
  }

  getFutureItems(now: Date): CartItem[] {
    return this.items.filter(item => this.isFutureProduct(item, now));
  }

  getPastItems(now: Date): CartItem[] {
    return this.items.filter(item => !this.isFutureProduct(item, now));
  }

  /**
   * Get earliest future date from the cart, or return undefined if there are no future products.
   */
  findEarliestFutureProductDate(now: Date): Date | undefined {
    const futureItems = this.getFutureItems(now);
    if (futureItems.length === 0) {
      return undefined;
    }
    return min(...futureItems.map(i => i.product.startDate));
  }

  hasProductInFuture(now: Date): boolean {
    return this.items.some(item => this.isFutureProduct(item, now));
  }

  allProductsInFuture(now: Date): boolean {
    return this.items.every(item => this.isFutureProduct(item, now));
  }

  getDiscountApplied(discounts: Discount[]): Discount | undefined {
    return discounts.find(discount =>
      discount.discountRule.every(
        productCode => this.items.find(i => i.product.productCode === productCode) !== undefined
      )
    );
  }

  /** Return true if the product code (e.g. os_22_23) is in the shopping cart */
  containsProductId(productCode: string): boolean {
    return this.items.find(item => item.product.productCode === productCode) !== undefined;
  }

  /** Return true if all the given product codes are in the shopping cart */
  containsAllProductIds(productCodes: string[]): boolean {
    return productCodes.every(code => this.containsProductId(code));
  }

  private isFutureProduct(item: CartItem, now: Date): boolean {
    return isAfter(item.product.startDate, now) && !isSameDay(item.product.startDate, now);
  }
}
