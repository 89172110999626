import { ComponentType } from '@angular/cdk/portal';
import { Injectable, TemplateRef } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

export interface ErrorDialogData {
  errorComponent: ComponentType<any> | TemplateRef<any>;
  title?: string;
}

@Injectable()
export class ErrorDialogService {
  constructor(public dialog: MatDialog) {}

  showErrorDialog(data: ErrorDialogData): Promise<void> {
    return new Promise(resolve => {
      const dialogRef = this.dialog.open(data.errorComponent, {
        minWidth: '400px',
        data,
      });

      dialogRef.afterClosed().subscribe(result => {
        resolve();
      });
    });
  }
}
