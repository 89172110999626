import { Product } from '../models/product.model';

export class ProductAlreadyInvoicedError implements Error {
  message: string;
  name: string;

  constructor(public products: Product[]) {
    this.name = 'ProductAlreadyInvoicedError';
    this.message = 'Products already invoiced: ' + JSON.stringify(this.products.map(p => p.name));
  }
}
