<form #contactForm="ngForm">
  <div class="row">
    <div class="medium-2 columns">
      <label for="purchaser-title">Title</label>
      <input
        id="purchaser-title"
        type="text"
        placeholder="Title"
        name="purchaser-title"
        [(ngModel)]="user.title"
        required
        maxlength="{{ validations.title.maxLength }}"
        minlength="{{ validations.title.minLength }}"
        (ngModelChange)="saveState()" />
    </div>
    <div class="medium-5 columns">
      <label for="first-name">First Name</label>
      <input
        id="first-name"
        type="text"
        placeholder="First Name"
        name="purchaser-firstName"
        autocomplete="given-name"
        required
        maxlength="{{ validations.givenName.maxLength }}"
        minlength="{{ validations.givenName.minLength }}"
        [(ngModel)]="user.firstName"
        (ngModelChange)="saveState()" />
    </div>
    <div class="medium-5 columns">
      <label for="last-name">Last Name</label>
      <input
        id="last-name"
        type="text"
        placeholder="Last Name"
        name="purchaser-lastName"
        autocomplete="family-name"
        required
        maxlength="{{ validations.familyName.maxLength }}"
        minlength="{{ validations.familyName.minLength }}"
        [(ngModel)]="user.lastName"
        (ngModelChange)="saveState()" />
    </div>
  </div>
  <div class="row">
    <div class="medium-6 columns">
      <label for="email-address">Email Address</label>
      <input
        id="email-address"
        type="email"
        name="purchaser-email"
        ngModel
        email="true"
        placeholder="Email Address"
        autocomplete="email"
        required
        email
        pattern="{{ validations.email.regex }}"
        maxlength="{{ validations.email.maxLength }}"
        minlength="{{ validations.email.minLength }}"
        [(ngModel)]="user.emailAddress"
        (ngModelChange)="saveState()" />
    </div>
  </div>
</form>
