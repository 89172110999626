<div class="dm-dialog-container">
  <mat-dialog-content>
    <div class="dm-bundle-price-notice">
      <div class="dm-text-block">
        You could save <span class="dm-bundle-savings">{{ data.cheaperBundles[0].bundleSavings().format() }}</span> if
        you purchase the <span class="dm-bundle-name">{{ data.cheaperBundles[0].bundle.name }}</span> bundle.
      </div>
      <div class="dm-text-block">
        If you would like to take the cheaper bundle instead of your current selection, please click the Clear Order
        button and select the bundle from the list of available options.
      </div>
    </div>
  </mat-dialog-content>

  <mat-dialog-actions>
    <button mat-raised-button color="primary" [mat-dialog-close]="true">Ok</button>
  </mat-dialog-actions>
</div>
