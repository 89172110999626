<div class="information-panel">
  If you do not see the product you are looking for, please check the
  <a href="" (click)="gotoCurrentSubscriptionsTab($event)">current subscriptions tab</a>. If you still don't see the
  product you want, please contact us at <a href="mailto:edina@ed.ac.uk">edina@ed.ac.uk</a>.
</div>
<mat-tab-group mat-align-tabs="center" [(selectedIndex)]="selectedIndex">
  <mat-tab>
    <ng-template mat-tab-label>
      <span class="tab-big-font">Buy or Renew Subscriptions</span>
    </ng-template>
    <div class="small-12 medium-8 columns nopadding">
      <h5 *ngIf="futureAvailableProducts.length + currentAvailableProducts.length === 0">
        There are no products to buy at the moment.
      </h5>
      <ul class="dm-product-list">
        <li *ngFor="let product of futureAvailableProducts">
          <dm-product-list-item [product]="product" [allProducts]="allProducts"></dm-product-list-item>
        </li>
      </ul>
      <h3 *ngIf="showSubheading()" class="product-subheading">Subscriptions starting immediately</h3>
      <ul class="dm-product-list">
        <li *ngFor="let product of currentAvailableProducts">
          <dm-product-list-item [product]="product" [allProducts]="allProducts"></dm-product-list-item>
        </li>
      </ul>
    </div>
    <div class="small-12 medium-4 columns">
      <dm-shopping-cart></dm-shopping-cart>
    </div>
  </mat-tab>
  <mat-tab>
    <ng-template mat-tab-label>
      <span class="tab-big-font">Your Current Subscriptions</span>
    </ng-template>
    <div class="small-12 medium-8 columns nopadding">
      <h5 *ngIf="ownedProducts.length === 0">You have no currently subscribed products.</h5>
      <ul class="dm-product-list">
        <li *ngFor="let product of ownedProducts">
          <dm-product-list-item
            [product]="product"
            [allProducts]="allProducts"
            [showPrice]="false"></dm-product-list-item>
        </li>
      </ul>
    </div>
    <div class="small-12 medium-4 columns">
      <dm-shopping-cart></dm-shopping-cart>
    </div>
  </mat-tab>
  <mat-tab>
    <ng-template mat-tab-label>
      <span class="tab-big-font">Licences</span>
    </ng-template>

    <p>
      All current licences are listed here. You can log in to this subscription portal at any time to retrieve a copy of
      the licences:
    </p>

    <ul>
      <li *ngFor="let licence of licences">
        <a href="{{ licence.eulaUrl }}">{{ licence.displayName }}</a>
      </li>
    </ul>
  </mat-tab>
</mat-tab-group>
