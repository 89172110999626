import { Component, Input } from '@angular/core';
import { Product } from '../../models/product.model';
import { Discount } from '../../models/discount.model';

@Component({
  selector: 'dm-price-with-vat',
  templateUrl: './price-with-vat.component.html',
  styleUrls: ['./price-with-vat.component.scss'],
})
export class PriceWithVatComponent {
  @Input('product')
  product: Product;

  @Input('showVat')
  showVat: boolean = true;

  @Input('showDiscount')
  showDiscount: Discount | undefined;

  getPriceText() {
    if (this.product.proRataMonths === 12) {
      return 'Price excl. VAT';
    }

    return `Price excl. VAT (pro-rata)`;
  }
}
