import { Injectable } from '@angular/core';
import { ActivatedRoute, Params, PRIMARY_OUTLET } from '@angular/router';

export interface IBreadcrumb {
  label: string;
  params?: Params;
  url: string;
  description: string;
}

@Injectable()
export class BreadcrumbService {
  getBreadcrumbs(route: ActivatedRoute, urlStr: string = '', breadcrumbs: IBreadcrumb[] = []): IBreadcrumb[] {
    const ROUTE_DATA_BREADCRUMB: string = 'breadcrumb';
    const ROUTE_DATA_BREADCRUMB_DESC: string = 'breadcrumb_description';

    // get the child routes
    const children: ActivatedRoute[] = route.children;

    // return if there are no more children
    if (children.length === 0) {
      return breadcrumbs;
    }

    // iterate over each children
    for (const child of children) {
      // verify primary route
      if (child.outlet !== PRIMARY_OUTLET) {
        continue;
      }

      // verify the custom data property 'breadcrumb' is specified on the route
      if (!child.snapshot.data.hasOwnProperty(ROUTE_DATA_BREADCRUMB)) {
        return this.getBreadcrumbs(child, urlStr, breadcrumbs);
      }

      // get the route's URL segment
      const routeURL: string = child.snapshot.url.map(segment => segment.path).join('/');

      // append route URL to URL
      urlStr += `/${routeURL}`;

      // add breadcrumb
      const breadcrumb: IBreadcrumb = {
        label: child.snapshot.data[ROUTE_DATA_BREADCRUMB],
        description: child.snapshot.data[ROUTE_DATA_BREADCRUMB_DESC],
        params: child.snapshot.params,
        url: urlStr,
      };
      breadcrumbs.push(breadcrumb);

      // recursive
      return this.getBreadcrumbs(child, urlStr, breadcrumbs);
    }

    // we should never get here, but just in case
    return breadcrumbs;
  }
}
