import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { mergeMap } from 'rxjs/operators';
import { SchoolService } from '../school.service';
import { FormControl, FormGroup, Validators } from '@angular/forms';

@Component({
  templateUrl: './exit-survey.component.html',
  styleUrls: ['./exit-survey.component.scss'],
})
export class ExitSurveyComponent implements OnInit {
  edinaOrgId = '';
  subscriptionId = '';
  cancelReasons: string[] = [
    'Lack of understanding of how to use the service',
    'Too expensive / no budget',
    'Found another preferable service',
    'Not used enough',
    'Missing features',
    'Other',
  ];
  usageReasons: string[] = [
    'Lack of available computers for teachers and pupils',
    'Service did not do what was expected',
    'Lack of training of how to use the service',
    'Not closely aligned enough with the curriculum',
    'Other',
  ];
  dfsAlternatives: string[] = ['Google Maps', 'Hard Copy Maps', 'Nothing', 'Other'];
  showSuccessMessage: boolean;
  getDataError: boolean;
  showForm: boolean;
  showSubmitError = false;
  public formGroup = new FormGroup({
    title: new FormControl('', [Validators.required]),
    firstName: new FormControl('', [Validators.required]),
    lastName: new FormControl('', [Validators.required]),
    email: new FormControl('', [Validators.required, Validators.email]),
    cancelReason: new FormControl('', [Validators.required]),
    cancelOther: new FormControl(''),
    purchaseReason: new FormControl(),
    usageReason: new FormControl(),
    usageOther: new FormControl(''),
    dfsAlternative: new FormControl('', [Validators.required]),
    altOther: new FormControl(''),
  });

  constructor(private readonly route: ActivatedRoute, private schoolService: SchoolService) {}

  ngOnInit() {
    this.route.params
      .pipe(
        mergeMap((params: any) => {
          this.edinaOrgId = params['edinaOrgId'];
          this.subscriptionId = params['subscriptionId'];
          return this.schoolService.getSurveyInfo(this.edinaOrgId, this.subscriptionId);
        })
      )
      .subscribe({
        next: data => {
          this.showForm = true;
          this.getDataError = false;
          this.formGroup.controls['title'].setValue(data.title);
          this.formGroup.controls['firstName'].setValue(data.firstName);
          this.formGroup.controls['lastName'].setValue(data.lastName);
          this.formGroup.controls['email'].setValue(data.email);
        },
        error: () => {
          this.showForm = false;
          this.getDataError = true;
        },
      });
  }

  handleSubmit() {
    this.schoolService.sendSurvey(this.formGroup.value, this.edinaOrgId, this.subscriptionId).subscribe({
      next: () => {
        this.showSuccessMessage = true;
        this.showForm = false;
      },
      error: () => {
        this.showSubmitError = true;
      },
    });
  }
}
