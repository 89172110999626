import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { catchError, debounceTime, filter, switchMap, tap } from 'rxjs/operators';
import { SchoolService, SchoolType } from './school.service';
import { Postcode } from './postcode';
import { Observable, of } from 'rxjs';
import { ImmediateErrorStateMatcher } from '../../util/immediate-error-state-matcher';
import { ActivatedRoute, Router } from '@angular/router';
import { Institution } from '../../../models/institution.model';
import { ShopConfigService } from '../../util/shop-config.service';
import { ShopType } from '../../../helpers/ShopType';
import { AppPages } from '../../../state/app-page';
import { MatProgressButtonOptions } from 'mat-progress-buttons';

@Component({
  templateUrl: './schools-page.component.html',
  styleUrls: ['./schools-page.component.scss'],
})
export class SchoolsPageComponent implements OnInit {
  postcodeControl = new FormControl();
  postcodes$: Observable<Postcode[]>;
  // Holds the submitted postcode value, otherwise when binding directly
  // to the postcode control editing the value automatically updates the
  // text on the form, which isn't what we want
  postcodeValue: string;

  searching = false;
  error = false;
  postcodeSubmitted = false;
  schoolSubmitted = false;
  availableSchools: Institution[] = [];

  selectedSchoolControl = new FormControl();
  errorMatcher = new ImmediateErrorStateMatcher();

  btnOpts: MatProgressButtonOptions = {
    active: false,
    text: 'Submit',
    spinnerSize: 20,
    raised: true,
    stroked: false,
    buttonColor: 'primary',
    spinnerColor: 'accent',
    fullWidth: false,
    disabled: false,
    mode: 'indeterminate',
  };

  schoolType: SchoolType;

  constructor(
    private schoolService: SchoolService,
    private router: Router,
    private shopConfig: ShopConfigService,
    private activatedRoute: ActivatedRoute
  ) {}

  fetchSchools() {
    if (!this.postcodeControl.value) {
      return;
    }
    this.postcodeSubmitted = true;
    this.postcodeValue = this.postcodeControl.value;
    const postcode = this.postcodeControl.value.replace(/\s/g, '');

    this.error = false;
    this.searching = true;
    this.schoolService
      .getSchoolsByPostcode(postcode, this.schoolType)
      .pipe(
        catchError(() => {
          this.error = true;
          this.searching = false;
          this.postcodeControl.setErrors({ wat: true });
          return of([]);
        })
      )
      .subscribe(schools => {
        this.searching = false;
        this.availableSchools = schools;
      });
  }

  onSubmit() {
    const schoolId = this.selectedSchoolControl.value;

    if (!schoolId) {
      this.btnOpts.active = false;
      return;
    }
    this.btnOpts.active = true;
    this.schoolSubmitted = true;

    const navPath = this.schoolType === 'school' ? AppPages.schools.navPath : AppPages.colleges.navPath;
    this.router.navigate([`/${navPath}/${schoolId}`]);
  }

  ngOnInit() {
    // Assume a school, unless the schoolType has been explicitly set in the route data.
    this.activatedRoute.data.subscribe(data => (this.schoolType = data.schoolType ? data.schoolType : 'school'));

    // TODO check how to set the ofsted schools here
    this.shopConfig.updateShopType(ShopType.DIGIMAP_FOR_SCHOOLS);
    this.postcodes$ = this.postcodeControl.valueChanges.pipe(
      filter(value => value && value.trim() !== ''), // don't search for empty values
      debounceTime(200),
      // Reset spinner and error flags for every new search
      tap(() => {
        this.searching = true;
        this.postcodeSubmitted = false;
        this.error = false;
      }),
      // Get postcodes from the backend, if theres an error return [] and set
      // an error flag
      switchMap(value =>
        this.schoolService.getPostcode(value).pipe(
          catchError(() => {
            this.error = true;
            return of([]);
          })
        )
      ),
      // Remove the search spinner now searching is done
      tap(() => (this.searching = false)),
      // If there are no results then set error codes for UI display
      tap(p => {
        if (!p.length) {
          this.postcodeControl.setErrors({ 'no-results': true });
        } else {
          this.postcodeControl.setErrors(null);
        }
      })
    );
  }

  postcodeDisabled() {
    return !this.postcodeControl.valid || this.postcodeSubmitted;
  }
}
