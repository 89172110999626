<div class="order-total-container">
  <div class="dm-labels">
    <div>Total excluding VAT</div>
    <div>Total VAT</div>
    <div class="total-incl-vat-label">Total including VAT</div>
  </div>
  <div class="dm-amounts">
    <div class="amount-text">{{ cart.netTotal.format() }}</div>
    <div class="amount-text">{{ cart.grossTotal.subtract(cart.netTotal).format() }}</div>
    <div class="total-incl-vat">{{ cart.grossTotal.format() }}</div>
  </div>
</div>
