import * as currency from 'currency.js';

import { Product } from 'app/models/product.model';

export class AlternativeBundle {
  constructor(public bundle: Product, public totalForIndividualProducts: currency) {
    this.bundle = bundle;
  }

  isBundleCheaper(): boolean {
    return this.bundleSavings().intValue > 0; // gt or equal? Consider that the bundle may contain bonus products....
  }

  bundleSavings(): currency {
    return this.totalForIndividualProducts.subtract(this.bundle.totalPrice());
  }
}
