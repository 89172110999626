export class Licence {
  id: string = '';
  name: string = '';
  displayName: string = '';
  eulaUrl: string = '';
  eulaChangesUrl: string = '';
  tosUrl: string = '';
  finalLicence: boolean;

  constructor(cfg: Partial<Licence> = {}) {
    this.id = cfg.id ? cfg.id : this.id;
    this.name = cfg.name ? cfg.name : this.name;
    this.displayName = cfg.displayName ? cfg.displayName : this.displayName;
    this.eulaUrl = cfg.eulaUrl ? cfg.eulaUrl : this.eulaUrl;
    this.eulaChangesUrl = cfg.eulaChangesUrl ? cfg.eulaChangesUrl : this.eulaChangesUrl;
    this.tosUrl = cfg.tosUrl ? cfg.tosUrl : this.tosUrl;
    this.finalLicence = cfg.finalLicence ? true : false;
  }
}
