import { Injectable } from '@angular/core';
import { CartItem } from 'app/models/cart-item.model';
import { Product } from 'app/models/product.model';
import { ShoppingCart } from 'app/models/shopping-cart.model';
import { AlternativeBundle } from 'app/services/product/bundling/alternative-bundle';
import { ProductService } from 'app/services/product/products.service';
import { ShoppingCartService } from 'app/services/shopping-cart.service';
import { Observable } from 'rxjs';
import { distinctUntilChanged } from 'rxjs/operators';
import { ReplaySubject } from 'rxjs';
import { Licence } from '../../../models/licence.model';
import { GBP } from '../../../helpers/currency-defs';
import { areRangesOverlapping } from 'date-fns';

@Injectable()
export class BundleService {
  readonly cheaperBundles$: Observable<AlternativeBundle[]>;

  private readonly cheaperBundlesSubject = new ReplaySubject<AlternativeBundle[]>(1);

  private bundles: Product[] = [];
  private cartItems: CartItem[] = [];

  constructor(private cartService: ShoppingCartService, private productService: ProductService) {
    this.cheaperBundles$ = this.cheaperBundlesSubject.asObservable();
    this.cartService.cart$
      .pipe(distinctUntilChanged((p: ShoppingCart, c: ShoppingCart) => p.items.length === c.items.length))
      .subscribe(cart => this.onCartItemsChange(cart.items));
    this.productService.orderable$.subscribe(orderable => this.onBundleChange(orderable));
  }

  getCheaperBundles$(): Observable<AlternativeBundle[]> {
    return this.cheaperBundlesSubject;
  }

  private onCartItemsChange(items: CartItem[]) {
    this.cartItems = items;
    this.checkPricing();
  }

  private onBundleChange(orderable: Product[]) {
    this.bundles = orderable.filter(p => p.isBundle());
    this.checkPricing();
  }

  private checkPricing(): void {
    const cartBundles: AlternativeBundle[] = [];
    this.bundles.forEach(bundle => {
      // The set of this bundles products in the cart
      const bundleProductsInCart = bundle.licences
        .map(licence => this.getSimpleProductFromCartItems(bundle, licence))
        .filter(product => product !== undefined);

      // If all licences in this bundle are already in the cart as separate products
      if (bundleProductsInCart.length === bundle.licences.length) {
        const total = bundleProductsInCart
          .map(p => (p ? p.totalPrice() : GBP(0)))
          .reduce((prev, next) => prev.add(next), GBP(0));
        const cheaperBundle = new AlternativeBundle(bundle, total);
        if (cheaperBundle.isBundleCheaper()) {
          cartBundles.push(cheaperBundle);
        }
      }
    });
    this.cheaperBundlesSubject.next(cartBundles);
  }

  private getSimpleProductFromCartItems(bundle: Product, licence: Licence): Product | undefined {
    const cartItem = this.cartItems
      .filter(i => areRangesOverlapping(i.product.startDate, i.product.endDate, bundle.startDate, bundle.endDate))
      .find(item => item.product.isSimpleWithLicence(licence.id));
    return cartItem ? cartItem.product : undefined;
  }
}
