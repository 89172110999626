<div #shoppingCart class="cart">
  <div class="row">
    <div class="small-12 columns">
      <h3>Your Order:</h3>
      <div *ngIf="!cartProducts.length" class="empty-collection">Select an item from the collections panel</div>

      <ng-container *ngIf="cartBundleProducts.length">
        <div *ngFor="let product of cartBundleProducts">
          <button
            *ngIf="canRemoveProduct(product); else boughtBundle"
            mat-icon-button
            (click)="removeProductFromCart(product)"
            aria-label="Delete Button">
            <mat-icon aria-label="Delete Icon">delete</mat-icon>
          </button>
          <ng-template #boughtBundle>
            <mat-icon aria-hidden="true">done</mat-icon>
          </ng-template>
          <span>{{ product.name }}</span>
        </div>
      </ng-container>

      <ng-container *ngIf="cartStandaloneProducts.length">
        <div *ngFor="let product of cartStandaloneProducts">
          <button
            *ngIf="canRemoveProduct(product); else boughtProduct"
            mat-icon-button
            (click)="removeProductFromCart(product)"
            aria-label="Delete Button">
            <mat-icon aria-label="Delete Icon">delete</mat-icon>
          </button>
          <ng-template #boughtProduct>
            <mat-icon aria-hidden="true">done</mat-icon>
          </ng-template>
          <span>{{ product.name }}</span>
        </div>
      </ng-container>
    </div>
  </div>
  <br />
  <div class="row">
    <div class="small-12 columns">
      <h5>
        Sub Total ({{ cartProducts.length }} items):
        <span class="amount-text text--bold js-cart-total">{{ cart.netTotal.format() }}</span>
        <span class="vat-small">excl&nbsp;VAT</span>
      </h5>
    </div>
  </div>
  <br />
  <div class="row">
    <div class="small-12 columns">
      <button
        (click)="onNext()"
        [disabled]="!appState.isCheckoutPageEnabled()"
        class="success button large expanded"
        type="submit"
        data-test="Next to Checkout">
        Next
      </button>
    </div>
  </div>
  <ng-container *ngIf="!hasAllowRemovalList()">
    <div class="row">
      <div class="small-12 columns text-center or-section">or</div>
    </div>
    <div class="row">
      <div class="small-12 columns">
        <button
          [disabled]="cartProducts.length === 0"
          class="secondary button small expanded js-btn-empty-cart"
          type="submit"
          (click)="emptyCart()">
          Clear Order
        </button>
      </div>
    </div>
  </ng-container>
  <div class="row">
    <div class="small-12 columns">You can review and agree to all licences and Terms of Service on the next page.</div>
  </div>
</div>
