import { Component } from '@angular/core';
import { CartItem } from 'app/models/cart-item.model';
import { ShoppingCart } from 'app/models/shopping-cart.model';
import { ShoppingCartService } from 'app/services/shopping-cart.service';
import { AppPage, AppPages } from 'app/state/app-page';
import { AppStateService } from 'app/state/app-state.service';
import { PageComponent } from '../PageComponent';

@Component({
  selector: 'dm-checkout',
  styleUrls: ['./checkout-page.component.scss'],
  templateUrl: './checkout-page.component.html',
})
export class CheckoutPageComponent extends PageComponent {
  page: AppPage = AppPages.checkout;
  nextPage: AppPage = AppPages.billingDetails;
  cart: ShoppingCart;
  cartItems: CartItem[] = [];
  itemCount: number = 0;

  constructor(public appState: AppStateService, private shoppingCartService: ShoppingCartService) {
    super();

    this.shoppingCartService.cart$.subscribe(cart => {
      this.cart = cart;
      this.itemCount = cart.items.length;
      this.cartItems = cart.items;
    });
  }

  saveState() {
    this.shoppingCartService.stateChanged();
  }
}
