<h5>{{ title }}</h5>
<div class="row">
  <div class="medium-offset-1 medium-3 columns text--bold">Name:</div>
  <div class="medium-8 columns breakable-data">{{ user.title }} {{ user.firstName }} {{ user.lastName }}</div>
</div>
<div *ngIf="institutionName" class="row">
  <div class="medium-offset-1 medium-3 columns text--bold">Institution Name:</div>
  <div class="medium-8 columns breakable-data">
    {{ institutionName }}
  </div>
</div>
<div class="row">
  <div class="medium-offset-1 medium-3 columns text--bold">Job Title:</div>
  <div class="medium-8 columns breakable-data">
    {{ user.jobTitle }}
  </div>
</div>
<div class="row">
  <div class="medium-offset-1 medium-3 columns text--bold">Email Address:</div>
  <div class="medium-8 columns breakable-data">
    {{ user.emailAddress }}
  </div>
</div>
<div class="row">
  <div class="medium-offset-1 medium-3 columns text--bold">Telephone Number:</div>
  <div class="medium-8 columns breakable-data">
    {{ user.telephoneNumber }}
  </div>
</div>
