import { AuthenticationGuard } from './route-guards/authentication.guard';
import { BillingDetailsPageComponent } from './components/page/billing/billing-details-page.component';
import { CheckoutPageComponent } from './components/page/checkout/checkout-page.component';
import { InstitutionPageComponent } from './components/page/institution-page/institution-page.component';
import { LoginComponent } from './components/page/login/login.component';
import { NavBillingGuard } from './route-guards/nav-billing.guard';
import { NavCheckoutGuard } from './route-guards/nav-checkout.guard';
import { NavConfirmGuard } from './route-guards/nav-confirm.guard';
import { NavProductsGuard } from './route-guards/nav-products.guard';
import { NavSummaryGuard } from './route-guards/nav-summary.guard';
import { NgModule } from '@angular/core';
import { OrderConfirmationComponent } from './components/page/order-confirmation/order-confirmation.component';
import { OrderSummaryComponent } from './components/page/order-summary/order-summary.component';
import { PinGuard } from './route-guards/pin.guard';
import { ProductResolver } from './services/product/product.resolver';
import { ProductsPageComponent } from './components/page/products/products-page.component';
import { RouterModule } from '@angular/router';
import { StaticAppPages } from './state/app-page';
import { StaticErrorPageComponent } from 'app/components/page/error/static-error-page.component';
import { SchoolsPageComponent } from './components/page/schools-page/schools-page.component';
import { SchoolsConfirmComponent } from './components/page/schools-page/schools-confirm/schools-confirm.component';
import { HomePageComponent } from './components/page/home/home-page.component';
import { SchoolResolver } from './components/page/schools-page/school-resolver';
import { ExitSurveyComponent } from './components/page/schools-page/exit-survey/exit-survey.component';
import { UnauthorisedPageComponent } from './components/page/unauthorised/unauthorised-page.component';
import { PrivacyComponent } from './components/page/privacy/privacy.component';
import { CookiePolicyComponent } from './components/page/cookie-policy/cookie-policy.component';
import { UpgradesPageComponent } from './components/page/upgrades/upgrades-page.component';

@NgModule({
  exports: [RouterModule],
  imports: [
    RouterModule.forRoot(
      [
        {
          path: StaticAppPages.products.navPath,
          canActivate: [AuthenticationGuard, NavProductsGuard],
          component: ProductsPageComponent,
          resolve: {
            products: ProductResolver,
          },
          data: {
            breadcrumb: StaticAppPages.products.title,
            breadcrumb_description: 'Step 1 of 6',
            page: 1,
          },
        },
        {
          path: StaticAppPages.upgrades.navPath,
          canActivate: [AuthenticationGuard, NavCheckoutGuard],
          component: UpgradesPageComponent,
          resolve: {
            products: ProductResolver,
          },
          data: {
            breadcrumb: StaticAppPages.upgrades.title,
            breadcrumb_description: 'Step 2 of 6',
            page: 2,
          },
        },
        {
          path: StaticAppPages.checkout.navPath,
          component: CheckoutPageComponent,
          canActivate: [AuthenticationGuard, NavCheckoutGuard],
          resolve: {
            products: ProductResolver,
          },
          data: {
            breadcrumb: StaticAppPages.checkout.title,
            breadcrumb_description: 'Step 3 of 6',
            page: 3,
          },
        },
        {
          path: StaticAppPages.billingDetails.navPath,
          canActivate: [AuthenticationGuard, NavBillingGuard],
          component: BillingDetailsPageComponent,
          resolve: {
            products: ProductResolver,
          },
          data: {
            breadcrumb: StaticAppPages.billingDetails.title,
            breadcrumb_description: 'Step 4 of 6',
            page: 4,
          },
        },
        {
          path: StaticAppPages.confirm.navPath,
          canActivate: [AuthenticationGuard, NavConfirmGuard],
          component: OrderConfirmationComponent,
          resolve: {
            products: ProductResolver,
          },
          data: {
            breadcrumb: StaticAppPages.confirm.title,
            breadcrumb_description: 'Step 5 of 6',
            page: 5,
          },
        },
        {
          path: StaticAppPages.orderSummary.navPath,
          canActivate: [AuthenticationGuard, NavSummaryGuard],
          component: OrderSummaryComponent,
          resolve: {
            products: ProductResolver,
          },
          data: {
            breadcrumb: StaticAppPages.orderSummary.title,
            breadcrumb_description: 'Step 6 of 6',
            page: 6,
          },
        },
        {
          path: StaticAppPages.login.navPath,
          component: LoginComponent,
          canActivate: [PinGuard],
          data: {
            breadcrumb: 'Login',
          },
        },
        {
          path: StaticAppPages.schools.navPath,
          component: SchoolsPageComponent,
          data: {
            breadcrumb: StaticAppPages.schools.header,
          },
        },
        {
          path: StaticAppPages.colleges.navPath,
          component: SchoolsPageComponent,
          data: {
            breadcrumb: StaticAppPages.colleges.header,
            schoolType: 'college',
          },
        },
        {
          path: StaticAppPages.survey.navPath + '/:edinaOrgId' + '/:subscriptionId',
          component: ExitSurveyComponent,
          data: {
            breadcrumb: StaticAppPages.survey.header,
          },
        },
        {
          path: StaticAppPages.schools.navPath + '/:id',
          component: SchoolsConfirmComponent,
          resolve: {
            school: SchoolResolver,
          },
          data: {
            breadcrumb: StaticAppPages.schools.header,
          },
        },
        {
          path: StaticAppPages.colleges.navPath + '/:id',
          component: SchoolsConfirmComponent,
          resolve: {
            school: SchoolResolver,
          },
          data: {
            breadcrumb: StaticAppPages.colleges.header,
            schoolType: 'college',
          },
        },
        {
          path: StaticAppPages.institutions.navPath,
          component: InstitutionPageComponent,
          data: {
            breadcrumb: StaticAppPages.institutions.header,
          },
        },
        {
          path: StaticAppPages.error.navPath,
          component: StaticErrorPageComponent,
        },
        {
          path: StaticAppPages.unauthorised.navPath,
          component: UnauthorisedPageComponent,
        },
        {
          path: StaticAppPages.root.navPath,
          component: HomePageComponent,
        },
        {
          path: StaticAppPages.privacy.navPath,
          component: PrivacyComponent,
        },
        {
          path: StaticAppPages.cookiePolicy.navPath,
          component: CookiePolicyComponent,
        },
        {
          path: '**',
          redirectTo: StaticAppPages.products.navPath,
        },
      ],
      {
        enableTracing: false,
      }
    ),
  ],
})
export class AppRoutingModule {}
