import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { UserInfo } from 'app/models/user-info.model';
import { ShoppingCartService } from 'app/services/shopping-cart.service';
import { ContactInfo } from '../../../../models/contact-info.model';
import { NgForm } from '@angular/forms';

@Component({
  selector: 'dm-contact-user-form',
  templateUrl: './contact-user-form.component.html',
})
export class ContactUserFormComponent implements OnInit {
  @Input('user') user: ContactInfo;
  @Output('form') form: EventEmitter<NgForm> = new EventEmitter<NgForm>();
  @ViewChild('contactForm', { static: true }) contactForm;

  readonly validations = UserInfo.validations;

  constructor(private readonly shoppingCartService: ShoppingCartService) {}

  ngOnInit() {
    this.form.emit(this.contactForm);
  }

  saveState() {
    this.shoppingCartService.stateChanged();
  }
}
