import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { Institution } from './../../models/institution.model';
import { UserInfo } from './../../models/user-info.model';

@Component({
  selector: 'dm-ordered-by',
  templateUrl: './ordered-by.component.html',
  styleUrls: ['./ordered-by.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OrderedByComponent {
  @Input() user: UserInfo;
  @Input() title: string;
  @Input() institution: Institution;

  constructor() {}
}
