import { AllPagesArray, AppPage, AppPages, CheckoutPageArray } from './app-page';
import { NavigationEnd, Router } from '@angular/router';
import { AppVersionInfo } from '../helpers/app-environment';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { Injectable } from '@angular/core';
import { Institution } from '../models/institution.model';
import { ShoppingCart } from '../models/shopping-cart.model';
import { ShoppingCartService } from '../services/shopping-cart.service';
import { StorageService } from '../services/storage.service';
import { filter } from 'rxjs/operators';
import { AppState } from './app-state';
import { CachedAppState } from './cached-app-state';

/**
 * Have to send something, may as well send this rather than incrementing a counter.
 */
export interface NavStateChangeEvent {
  cart: ShoppingCart;
}

type EnvironmentStage = 'dev' | 'beta' | 'prod';

/** @todo: ggranum: Create an error observable and pipe all thrown errors through it. */
@Injectable()
export class AppStateService {
  ready$: Observable<boolean>;
  navStateChange$: Observable<void>;
  private readySubject: BehaviorSubject<boolean> = new BehaviorSubject(false);
  private navStateChangeSubject: Subject<void> = new Subject();
  private appState: AppState;
  private readonly SESSION_TIMEOUT = 8 * 60 * 60 * 1000; // 8 hours

  constructor(
    private store: StorageService,
    private cartService: ShoppingCartService,
    private appVersionInfo: AppVersionInfo,
    private router: Router
  ) {
    this.ready$ = this.readySubject.asObservable();
    this.navStateChange$ = this.navStateChangeSubject.asObservable();
    this.load();
  }

  isPageActiveExact(page: AppPage): boolean {
    return this.router.isActive(page.navPath, {
      paths: 'exact',
      queryParams: 'exact',
      fragment: 'ignored',
      matrixParams: 'ignored',
    });
  }

  isPageActive(page: AppPage): boolean {
    return this.router.isActive(page.navPath, {
      paths: 'subset',
      queryParams: 'subset',
      fragment: 'ignored',
      matrixParams: 'ignored',
    });
  }

  isPageEnabled(page: AppPage): boolean {
    switch (page) {
      case AppPages.products: {
        return this.isProductsPageEnabled();
      }
      case AppPages.upgrades: {
        return this.isCheckoutPageEnabled();
      }
      case AppPages.checkout: {
        return this.isCheckoutPageEnabled();
      }
      case AppPages.billingDetails: {
        return this.isBillingPageEnabled();
      }
      case AppPages.confirm: {
        return this.isConfirmPageEnabled();
      }
      case AppPages.orderSummary: {
        return this.isSummaryPageEnabled();
      }
      default: {
        throw new Error(`Unhandled page: ${page.navPath}`);
      }
    }
  }

  isProductsPageEnabled() {
    this.checkAppReady();
    return !this.appState.cart.orderCompleted;
  }

  isCheckoutPageEnabled() {
    this.checkAppReady();
    return this.appState.cart.items.length > 0 && !this.appState.cart.orderCompleted;
  }

  isBillingPageEnabled(): boolean {
    return this.isCheckoutPageEnabled() && this.appState.cart.allAgreementsSigned();
  }

  /**
   * All addresses must be present and valid.
   *
   * @returns
   */
  isConfirmPageEnabled(): boolean {
    // Don't need to validate the address if the invoice is for 0 (eg ofsted)
    const addrValid = this.appState.cart.grossTotal.intValue === 0 || this.appState.cart.invoiceAddress.isValid();
    return (
      this.isBillingPageEnabled() &&
      !!this.appState.cart.purchaser &&
      this.appState.cart.purchaser.isValid() &&
      addrValid
    );
  }

  isSummaryPageEnabled() {
    this.checkAppReady();
    return this.appState.cart.orderCompleted;
  }

  nextEnabledCheckoutPage(fromPage: AppPage): AppPage | undefined {
    this.checkAppReady();
    const fromIndex = CheckoutPageArray.indexOf(fromPage);
    for (let i = fromIndex; i < CheckoutPageArray.length; i++) {
      const page = CheckoutPageArray[i];
      if (this.isPageEnabled(page)) {
        return page;
      }
    }
    return undefined;
  }

  previousEnabledCheckoutPage(fromPage: AppPage): AppPage | undefined {
    this.checkAppReady();
    const fromIndex = CheckoutPageArray.indexOf(fromPage);
    for (let i = fromIndex; i >= 0; i--) {
      const page = CheckoutPageArray[i];
      if (this.isPageEnabled(page)) {
        return page;
      }
    }
    return undefined;
  }

  getEnvironmentStage(): EnvironmentStage {
    let stage: EnvironmentStage = 'prod';
    const url = window.location.host;
    if (url.search('beta') > -1) {
      stage = 'beta';
    } else if (url.search(':4200') > 0) {
      stage = 'dev';
    }
    console.log('AppStateService#getEnvironmentStage', 'URL: ', url, 'Stage: ', stage);
    return stage;
  }

  routeUserToInstitutionLoginPage(institution: Institution) {
    const stage = this.getEnvironmentStage();
    console.log('InstitutionPageComponent#onLoginClick', stage);
    const baseUrl = this.getBaseLoginUrl();
    if (stage === 'dev') {
      // just use the hour of the day for a semi-consistent cookie
      const suffix = new Date().getHours();
      console.log('InstitutionPageComponent#onLoginClick', baseUrl, suffix);
      window.location.assign(baseUrl + '?token=fake_token_' + suffix);
    } else {
      window.location.assign(baseUrl + 'idp=' + institution.edinaOrgId);
    }
  }

  setSuppressBundlePriceAlert(suppress: boolean): void {
    this.appState.suppressBundlePriceAlert = suppress;
  }

  getSuppressBundlePriceAlert() {
    return this.appState.suppressBundlePriceAlert;
  }

  getBaseLoginUrl(): string {
    const stage = this.getEnvironmentStage();
    if (stage === 'dev') {
      return '//' + window.location.hostname + ':' + window.location.port + '/' + AppPages.login.navPath;
    }

    return '/shibb-login-redirect';
  }

  private checkAppReady() {
    if (!this.readySubject.getValue()) {
      throw new Error('Application is not ready. Wait on appState.ready$ observable.');
    }
  }

  private load() {
    this.appState = new AppState();
    const cachedAppState: CachedAppState | undefined = this.store.getObject(CachedAppState.StoreKey, AppState);
    if (!cachedAppState) {
      this.appState.sessionCreated = Date.now();
    }
    Object.assign(this.appState, cachedAppState);

    const appVersionExpired =
      cachedAppState &&
      cachedAppState.versionInfo &&
      cachedAppState.versionInfo.dateTime < this.appVersionInfo.dateTime;
    const noSession = cachedAppState && !cachedAppState.sessionCreated;
    const sessionTimeoutExpired = cachedAppState && cachedAppState.sessionCreated < Date.now() - this.SESSION_TIMEOUT;

    if (appVersionExpired || noSession || sessionTimeoutExpired) {
      console.warn('AppStateService#load', 'Cache is out of date, clearing', this.appState.versionInfo);

      this.store.clearAll();
      this.appState = new AppState();
    }
    this.appState.versionInfo = this.appVersionInfo;

    this.cartService.cart$.subscribe(cart => {
      this.appState.cart = cart;
      this.navStateChangeSubject.next();
      if (!this.readySubject.getValue()) {
        this.readySubject.next(true);
      }
    });

    // noinspection SuspiciousInstanceOfGuard
    this.router.events.pipe(filter(e => e instanceof NavigationEnd)).subscribe(() => {
      this.appState.activePage = this.findActivePage();
      this.navStateChangeSubject.next();
      const cacheState: CachedAppState = {
        activePage: this.appState.activePage,
        versionInfo: this.appState.versionInfo,
        sessionCreated: this.appState.sessionCreated,
      };
      this.store.setObject(AppState.StoreKey, cacheState);
    });
  }

  private findActivePage() {
    const activePage = AllPagesArray.find(page => {
      const matchExact = page === AppPages.institutions;
      return this.router.isActive(page.navPath, matchExact);
    });
    if (!activePage) {
      throw new Error(`Could not determine the active page for the current URL ${this.router.url}`);
    }
    return activePage;
  }
}
