import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, Router, RouterStateSnapshot } from '@angular/router';
import { Product } from 'app/models/product.model';
import { ProductService } from 'app/services/product/products.service';
import { ServiceError } from 'app/services/service-error';
import { Observable } from 'rxjs';
import { race } from 'rxjs';
import { first, map } from 'rxjs/operators';
import { AppPages } from '../../state/app-page';

@Injectable()
export class ProductResolver implements Resolve<Product[]> {
  constructor(private productService: ProductService, private router: Router) {}

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<Product[]> {
    const values = this.productService.products$.pipe(first(products => products.length > 0));
    const errors = this.productService.error$.pipe(
      first(),
      map((e: ServiceError) => {
        this.router.navigate(['/' + AppPages.error.navPath]);
        return [];
      })
    );

    this.productService.load();

    return race(values, errors);
  }
}
