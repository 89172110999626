import { ShopType } from '../../helpers/ShopType';

export interface ShopConfigInterface {
  title: string;
  icon?: {
    iconUrl: string;
    alternativeText: string;
  };
  contactEmail: string;
  emailWarning: boolean;
  headerUrl: string;
}

export const configTypes: { [id in ShopType]: ShopConfigInterface } = {
  DIGIMAP: {
    title: 'Digimap HE Subscriptions',
    icon: {
      iconUrl: 'assets/digimap-icon-only.svg',
      alternativeText: 'Digimap subscription',
    },
    contactEmail: 'digimap@ed.ac.uk',
    emailWarning: false,
    headerUrl: 'https://digimap.edina.ac.uk/',
  },
  DIGIMAP_FOR_SCHOOLS: {
    title: 'Digimap Schools Subscriptions',
    contactEmail: 'digimap.schools@ed.ac.uk',
    emailWarning: true,
    headerUrl: 'https://digimapforschools.edina.ac.uk/',
  },
  DIGIMAP_FOR_SCHOOLS_OFSTED: {
    title: 'Digimap Schools Subscriptions',
    contactEmail: 'digimap.schools@ed.ac.uk',
    emailWarning: true,
    headerUrl: 'https://digimapforschools.edina.ac.uk/',
  },
};
