import { Institution } from './institution.model';

export class CurrentUser {
  institution: Institution;
  hasEnteredPin: boolean;
  lastSeen: Date;

  constructor(cfg: Partial<CurrentUser> = {}) {
    this.institution = cfg.institution ? new Institution(cfg.institution) : this.institution;
    this.hasEnteredPin = cfg.hasEnteredPin ? cfg.hasEnteredPin : this.hasEnteredPin;
    this.lastSeen = cfg.lastSeen ? new Date(cfg.lastSeen) : this.lastSeen;
  }
}
