import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { ShoppingCart } from 'app/models/shopping-cart.model';
import { ShoppingCartService } from 'app/services/shopping-cart.service';
import { CartItem } from 'app/models/cart-item.model';
import { Licence } from '../../../../models/licence.model';
import { Discount } from '../../../../models/discount.model';
import { Product } from '../../../../models/product.model';

@Component({
  selector: 'dm-checkout-item',
  templateUrl: './checkout-product-item.component.html',
  styleUrls: ['./checkout-product-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CheckoutItemComponent {
  @Input() cartItem: CartItem;
  @Output() cartItemChange: EventEmitter<CartItem> = new EventEmitter<CartItem>(false);

  cart: ShoppingCart; // safe, because we have resolver and a Replay subject on the cart.

  constructor(private shoppingCartService: ShoppingCartService) {
    this.shoppingCartService.cart$.subscribe(cart => (this.cart = cart));
  }

  getLicenceId(licence: Licence): string {
    return `${this.cartItem.product.id}-${licence.id}`;
  }

  fireChangeEvent() {
    this.cartItemChange.next(this.cartItem);
  }

  // Return the first found discount that can be applied to the given product
  getDiscountApplied(product: Product): Discount | undefined {
    return this.cart.getDiscountApplied(product.discounts);
  }

  get isBundle(): boolean {
    return this.cartItem.product.isBundle();
  }
}
