import { CurrentUser } from 'app/models/current-user.model';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { RemoteBasket } from 'app/models/remote.model';
import { RemoteOrderAdaptor } from './remote-order-adaptor';
import { ShoppingCart } from 'app/models/shopping-cart.model';
import { lastValueFrom } from 'rxjs';

@Injectable()
export class OrderService {
  constructor(private http: HttpClient) {}

  async submitOrder(user: CurrentUser, cart: ShoppingCart): Promise<RemoteBasket> {
    try {
      const remoteOrders: RemoteBasket[] = RemoteOrderAdaptor.toRemoteOrders(cart);
      const response = await lastValueFrom(this.http.post<RemoteBasket>('/api/order', remoteOrders));
      return response;
    } catch (e) {
      // Just log it and rethrow - needs to be handled by the UI that invoked it.
      console.log('OrderService#submitOrder', 'Order failed: ', e);
      throw e;
    }
  }
}
