<dm-sub-header [icon]="'local_offer'" [title]="page.header">
  <button class="warning button large" type="submit" routerLink="/products">Return to Products</button>
</dm-sub-header>

<div class="small-12 medium-8 columns">
  Thank you for ordering a Digimap Bundle. Your purchase means your institution is eligible for a discount on the
  products below. The discounted prices are only available now as part of today's purchase process. Should you wish to
  purchase any of the products below at any point in the future, the full pro rata price will apply.
</div>

<div class="small-12 medium-8 columns nopadding">
  <dm-product-list-item
    *ngFor="let product of upgrades"
    [product]="product"
    [allProducts]="upgrades"></dm-product-list-item>
</div>
<div class="small-12 medium-4 columns">
  <dm-shopping-cart [enableUpgrades]="false" [allowRemoval]="upgrades"></dm-shopping-cart>
</div>
