import { Product, ProductSubscriptionState } from '../models/product.model';
import { first, skip, take } from 'rxjs/operators';

import { CartItem } from '../models/cart-item.model';
import { CurrentUser } from '../models/current-user.model';
import { Injectable } from '@angular/core';
import { Observable, race, ReplaySubject, Subject } from 'rxjs';
import { OrderService } from './order/order.service';
import { ProductService } from './product/products.service';
import { ServiceError } from 'app/services/service-error';
import { ShoppingCart } from '../models/shopping-cart.model';
import { StorageService } from 'app/services/storage.service';
import { UserService } from './user/user.service';
import { ProductAlreadyInvoicedError } from './product-already-invoiced-error';

@Injectable()
export class ShoppingCartService {
  public readonly cart$: Observable<ShoppingCart>;

  private readonly cartSubject: Subject<ShoppingCart> = new ReplaySubject<ShoppingCart>(1);
  private currentCart: ShoppingCart;
  private user: CurrentUser;

  constructor(
    private orderService: OrderService,
    private storageService: StorageService,
    private productsService: ProductService,
    private userService: UserService
  ) {
    window.dataLayer = window.dataLayer || [];
    this.currentCart = this.retrieveCartFromLocalStorage();
    this.cart$ = this.cartSubject.asObservable();
    this.userService.user$.subscribe(user => (this.user = user));
    this.dispatch(this.currentCart);
  }

  addItem(product: Product): void {
    window.dataLayer.push({
      event: 'addToCart',
      ecommerce: {
        currencyCode: 'GBP',
        add: {
          products: [
            {
              name: product.name,
              id: product.id,
              price: product.proRataPrice.toString(),
              position: product.sortOrder,
              quantity: 1,
            },
          ],
        },
      },
    });

    const nextCart = new ShoppingCart(this.currentCart);
    nextCart.addItem(new CartItem({ product }));

    if (product.isBundle()) {
      product.licences.forEach(licence => nextCart.removeSimpleProductByLicenceId(licence.id));
    }

    this.stateChanged(nextCart);
  }

  removeItem(product: Product): void {
    window.dataLayer.push({
      event: 'removeFromCart',
      ecommerce: {
        remove: {
          products: [
            {
              name: product.name,
              id: product.id,
              price: product.proRataPrice.toString(),
              position: product.sortOrder,
              quantity: 1,
            },
          ],
        },
      },
    });

    const nextCart = new ShoppingCart(this.currentCart);
    nextCart.removeProductItem(product);
    this.stateChanged(nextCart);
  }

  /** Clears all data in the basket */
  purge(): void {
    this.currentCart.items.forEach(i => {
      window.dataLayer.push({
        event: 'removeFromCart',
        ecommerce: {
          remove: {
            products: [
              {
                name: i.product.name,
                id: i.product.id,
                price: i.product.proRataPrice.toString(),
                position: i.product.sortOrder,
                quantity: 1,
              },
            ],
          },
        },
      });
    });

    this.stateChanged(new ShoppingCart());
  }

  /**
   * Clears just the basket items from the basket. All other entered
   * data is retained. State through the app is reset to make sure
   * agreements are checked.
   */
  empty(): void {
    this.currentCart.items.forEach(i => {
      window.dataLayer.push({
        event: 'removeFromCart',
        ecommerce: {
          remove: {
            products: [
              {
                name: i.product.name,
                id: i.product.id,
                price: i.product.proRataPrice.toString(),
                position: i.product.sortOrder,
                quantity: 1,
              },
            ],
          },
        },
      });
    });

    const nextCart = new ShoppingCart(this.currentCart);
    nextCart.items = [];
    nextCart.authorisationConfirmed = false;
    nextCart.orderCompleted = false;
    this.stateChanged(nextCart);
  }

  /**
   * Clears most data from the basket except for invoice contact address
   * and the current user, which we wouldn't expect to change between
   * the user placing multiple orders.
   */
  reset(): void {
    this.currentCart.items.forEach(i => {
      window.dataLayer.push({
        event: 'removeFromCart',
        ecommerce: {
          remove: {
            products: [
              {
                name: i.product.name,
                id: i.product.id,
                price: i.product.proRataPrice.toString(),
                position: i.product.sortOrder,
                quantity: 1,
              },
            ],
          },
        },
      });
    });

    this.productsService.resultsCached = false;

    const nextCart = new ShoppingCart();
    nextCart.purchaser = this.currentCart.purchaser;
    nextCart.invoiceAddress = this.currentCart.invoiceAddress;
    nextCart.invoiceRecipient = this.currentCart.invoiceRecipient;
    nextCart.charityNumber = this.currentCart.charityNumber;
    nextCart.vatRegistrationNumber = this.currentCart.vatRegistrationNumber;
    nextCart.companyNumber = this.currentCart.companyNumber;
    nextCart.isPurchaserRecipient = this.currentCart.isPurchaserRecipient;

    this.stateChanged(nextCart);
  }

  isPurchaserRecipient() {
    return this.currentCart.isPurchaserRecipient;
  }

  async placeOrder(): Promise<void> {
    const orderableProducts: Product[] = await this.getOrderableItems();
    const orderableIds = orderableProducts.map(p => p.id).sort();
    const alreadyOrdered = this.currentCart.items
      .map(item => item.product)
      .filter(product => !orderableIds.includes(product.id));
    if (alreadyOrdered.length) {
      throw new ProductAlreadyInvoicedError(alreadyOrdered);
    }

    await this.orderService.submitOrder(this.user, this.currentCart);
    // ignore the possible error, as it will be thrown up to the UI component to handle.
    const nextCart = new ShoppingCart(this.currentCart);

    nextCart.items.forEach(item => {
      item.product.subscriptionState = ProductSubscriptionState.Invoiced;
    });
    nextCart.orderCompleted = true;
    this.stateChanged(nextCart);
    this.productsService.load(true); // invoiced products will have (presumably) changed.

    this.trackPurchase(this.currentCart, this.user);
  }

  /**
   * Kingdoms traded for immutable objects.
   *
   * Within this service, all update actions treat the currentCart as immutable - a new cart is created and changes
   * made to it, then 'this.currentCart' is set to the updated cart directly before the change event is fired.
   *
   * However, some Components are modifying the cart model directly, which means the service isn't always the
   * guardian of all change made to the model. This is not a horrible pattern, but it works less awesome with things
   * like the distinctUntilChanged filter.
   *
   * In summary, nextCart is optional because changing all the components which directly modify the Cart model
   * is out of scope.
   *
   * @param nextCart
   */
  stateChanged(nextCart?: ShoppingCart): void {
    if (!nextCart) {
      nextCart = this.currentCart;
    }
    this.save(nextCart);
    this.dispatch(nextCart);
  }

  private retrieveCartFromLocalStorage(): ShoppingCart {
    let result = this.storageService.getObject(ShoppingCart.StoreKey, ShoppingCart);
    if (result && result.items) {
      result.items = result.items.map(
        item => new CartItem(Object.assign({}, item, { product: new Product(item.product) }))
      );
      result = new ShoppingCart(result);
    } else {
      result = new ShoppingCart();
    }
    return result;
  }

  private save(nextCart: ShoppingCart): void {
    this.storageService.setObject(ShoppingCart.StoreKey, nextCart);
  }

  private dispatch(nextCart: ShoppingCart): void {
    this.currentCart = nextCart;
    this.cartSubject.next(this.currentCart);
  }

  private async getOrderableItems(): Promise<Product[]> {
    return new Promise<Product[]>((resolve, reject) => {
      race(this.productsService.orderable$.pipe(skip(1), take(1)), this.productsService.error$.pipe(first())).subscribe(
        {
          next: (result: ServiceError | Product[]) => {
            if (result && 'message' in result) {
              reject(result);
            } else {
              resolve(result);
            }
          },
          error: error => {
            reject(error);
          },
        }
      );
      this.productsService.load(true);
    });
  }

  private trackPurchase(cart: ShoppingCart, user: CurrentUser) {
    const gtagProducts = cart.items.map(i => ({
      name: i.product.name,
      id: i.product.id,
      price: i.product.proRataPrice.toString(),
      position: i.product.sortOrder,
      quantity: 1,
    }));
    window.dataLayer.push({
      ecommerce: {
        purchase: {
          actionField: {
            id: user.institution.edinaOrgId,
            affiliation: 'Subscriptions Portal',
            revenue: cart.grossTotal.toString(),
            tax: cart.grossTotal.subtract(cart.netTotal).toString(),
          },
          products: gtagProducts,
        },
      },
    });
  }
}
