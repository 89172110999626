import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgModel } from '@angular/forms';
import { HttpErrorResponse } from '@angular/common/http';
import { CurrentUser } from 'app/models/current-user.model';
import { AppPages } from 'app/state/app-page';
import { UserService } from 'app/services/user/user.service';
import { MatProgressButtonOptions } from 'mat-progress-buttons';

@Component({
  templateUrl: 'login.component.html',
  styleUrls: ['login.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LoginComponent implements OnInit {
  passcode: string = '';
  loading = false;
  errorMessage: string;
  btnOpts: MatProgressButtonOptions = {
    active: false,
    text: 'Login',
    spinnerSize: 20,
    raised: true,
    stroked: false,
    buttonColor: 'primary',
    spinnerColor: 'accent',
    fullWidth: false,
    disabled: false,
    mode: 'indeterminate',
  };

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private userService: UserService,
    private changeDetector: ChangeDetectorRef
  ) {}

  ngOnInit() {
    this.route.data.subscribe((data: { user: CurrentUser }) => {
      if (data.user && data.user.hasEnteredPin) {
        console.log('LoginComponent#ngInit.hasEnteredPin');
        this.router.navigate([AppPages.products.navPath]);
      }
    });
  }

  async login() {
    this.loading = true;
    this.btnOpts.active = true;
    try {
      await this.userService.submitPin(this.passcode);
      this.handleLoginSuccess();
    } catch (e) {
      this.handleLoginFail(e);
    }
  }

  errorMessageFrom(fPasscode: NgModel) {
    let msg = '';
    if (fPasscode.errors) {
      if (fPasscode.errors['minlength']) {
        msg = 'Passcode too short';
      } else if (fPasscode.errors['pattern']) {
        msg = 'Invalid passcode';
      } else if (fPasscode.errors['required']) {
        msg = 'Passcode is required';
      }
      console.log('LoginComponent#errorMessageFrom', fPasscode.errors);
    }
    return msg;
  }

  private handleLoginSuccess() {
    this.router.navigate([AppPages.products.navPath]).catch(e => {
      console.error('[Error]', 'LoginComponent#navigateToReturlUrl', e);
    });
  }

  private handleLoginFail(error: any) {
    if (error instanceof HttpErrorResponse) {
      if (error.status === 401 || error.status === 403) {
        this.errorMessage = error.error && error.error.detail ? error.error.detail : 'Invalid passcode';
      } else {
        console.error('[Error]', 'LoginComponent#Unhandled Server Error', error);
      }
    } else {
      console.error('[Error]', 'LoginComponent#Unhandled Error', error);
    }
    this.loading = false;
    this.btnOpts.active = false;
    /* All these manual change detections make me sad. */
    this.changeDetector.markForCheck();
  }
}
