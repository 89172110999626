<form #userForm="ngForm">
  <div class="row">
    <div class="medium-2 columns">
      <label for="{{ userType }}-user-title">Title</label>
      <input
        id="{{ userType }}-user-title"
        type="text"
        placeholder="Title"
        name="purchaser-title"
        [disabled]="disabled"
        [(ngModel)]="user.title"
        required
        maxlength="{{ validations.title.maxLength }}"
        minlength="{{ validations.title.minLength }}"
        (ngModelChange)="saveState()" />
    </div>
    <div class="medium-5 columns">
      <label for="{{ userType }}-user-first-name">First Name</label>
      <input
        id="{{ userType }}-user-first-name"
        type="text"
        placeholder="First Name"
        name="purchaser-firstName"
        autocomplete="given-name"
        required
        maxlength="{{ validations.givenName.maxLength }}"
        minlength="{{ validations.givenName.minLength }}"
        [disabled]="disabled"
        [(ngModel)]="user.firstName"
        (ngModelChange)="saveState()" />
    </div>
    <div class="medium-5 columns">
      <label for="{{ userType }}-user-last-name">Last Name</label>
      <input
        id="{{ userType }}-user-last-name"
        type="text"
        placeholder="Last Name"
        name="purchaser-lastName"
        autocomplete="family-name"
        required
        maxlength="{{ validations.familyName.maxLength }}"
        minlength="{{ validations.familyName.minLength }}"
        [disabled]="disabled"
        [(ngModel)]="user.lastName"
        (ngModelChange)="saveState()" />
    </div>
  </div>
  <div class="row">
    <div class="medium-8 columns">
      <label for="{{ userType }}-user-job-title">Job Title</label>
      <input
        id="{{ userType }}-user-job-title"
        type="text"
        name="purchaser-jobTitle"
        placeholder="Job Title"
        required
        maxlength="{{ validations.jobTitle.maxLength }}"
        minlength="{{ validations.jobTitle.minLength }}"
        [disabled]="disabled"
        [(ngModel)]="user.jobTitle"
        (ngModelChange)="saveState()" />
    </div>
  </div>
  <div class="row">
    <div class="medium-6 columns">
      <label for="{{ userType }}-user-email-address">Email Address</label>
      <input
        id="{{ userType }}-user-email-address"
        type="email"
        name="purchaser-email"
        ngModel
        email="true"
        placeholder="Email Address"
        autocomplete="email"
        required
        pattern="{{ validations.email.regex }}"
        maxlength="{{ validations.email.maxLength }}"
        minlength="{{ validations.email.minLength }}"
        [disabled]="disabled"
        [(ngModel)]="user.emailAddress"
        (blur)="blurEmailInput($event)"
        (ngModelChange)="saveState()" />
    </div>
    <div class="medium-6 columns">
      <label for="{{ userType }}-user-telephone-number">Telephone Number</label>
      <input
        id="{{ userType }}-user-telephone-number"
        type="text"
        placeholder="Phone Number"
        name="purchaser-phoneNumber"
        autocomplete="tel"
        required
        pattern="{{ validations.phone.regex }}"
        maxlength="{{ validations.phone.maxLength }}"
        minlength="{{ validations.phone.minLength }}"
        [disabled]="disabled"
        [(ngModel)]="user.telephoneNumber"
        (ngModelChange)="saveState()" />
    </div>
  </div>
</form>
