import * as currency from 'currency.js';
import { GBP } from '../helpers/currency-defs';

export class Discount {
  discountPrice: currency;
  discountRule: string[] = [];
  priceWithDiscount: currency;
  proRataPriceWithDiscount: currency;
  vatAmount: currency;
  discount: currency;
  displayName?: string;

  constructor(cfg: Partial<Discount> = {}) {
    // NB: discounts are negative in Salesforce. Make them positive for easier display
    this.discount = GBP(cfg.discount ? -cfg.discount : this.discount);
    this.discountPrice = GBP(cfg.discountPrice ? cfg.discountPrice : this.discountPrice);
    this.discountRule = cfg.discountRule ? cfg.discountRule : this.discountRule;
    this.priceWithDiscount = GBP(cfg.priceWithDiscount ? cfg.priceWithDiscount : this.priceWithDiscount);
    this.proRataPriceWithDiscount = GBP(
      cfg.proRataPriceWithDiscount ? cfg.proRataPriceWithDiscount : this.proRataPriceWithDiscount
    );
    this.vatAmount = GBP(cfg.vatAmount ? cfg.vatAmount : this.vatAmount);
  }

  totalPrice(): currency {
    // Note we are using the proRataPrice - this will be the same as priceNoVat if no pro-rata discount applicable
    return this.proRataPriceWithDiscount.add(this.vatAmount);
  }
}
