import { AppStateService } from 'app/state/app-state.service';
import { Component } from '@angular/core';
import { ShopConfigService } from '../../util/shop-config.service';
import { ShopType } from '../../../helpers/ShopType';

@Component({
  styleUrls: ['./institution-page.component.scss'],
  templateUrl: 'institution-page.component.html',
})
export class InstitutionPageComponent {
  baseLoginUrl: string;

  constructor(private appState: AppStateService, private shopConfig: ShopConfigService) {
    this.baseLoginUrl = this.appState.getBaseLoginUrl();
    this.shopConfig.updateShopType(ShopType.DIGIMAP);
  }
}
