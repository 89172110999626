import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { UserInfo } from 'app/models/user-info.model';

@Component({
  selector: 'dm-ordered-by-summary',
  templateUrl: './ordered-by-summary.component.html',
  styleUrls: ['./ordered-by-summary.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OrderedBySummaryComponent {
  @Input() institutionName: string | undefined;
  @Input() user: UserInfo;
  @Input() title: string;
}
