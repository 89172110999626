import { AppPage, AppPages } from 'app/state/app-page';
import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';

import { CurrentUser } from 'app/models/current-user.model';
import { PageComponent } from 'app/components/page/PageComponent';
import { Router } from '@angular/router';
import { ShoppingCart } from 'app/models/shopping-cart.model';
import { ShoppingCartService } from 'app/services/shopping-cart.service';
import { UserService } from 'app/services/user/user.service';

@Component({
  templateUrl: './order-summary.component.html',
  styleUrls: ['./order-summary.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OrderSummaryComponent extends PageComponent implements OnInit {
  page: AppPage = AppPages.orderSummary;

  cart: ShoppingCart;
  user: CurrentUser;
  isPurchaserShown: boolean;

  constructor(
    private shoppingCartService: ShoppingCartService,
    private userService: UserService,
    private router: Router
  ) {
    super();
  }

  printTheSummary() {
    window.print();
  }

  ngOnInit() {
    this.shoppingCartService.cart$.subscribe(cart => {
      this.cart = cart;

      this.isPurchaserShown = this.cart.grossTotal.value !== 0.0;
    });
    this.userService.user$.subscribe(u => (this.user = u));
  }

  startANewOrder() {
    this.shoppingCartService.reset();
    this.router.navigate([AppPages.products.navPath]);
  }
}
